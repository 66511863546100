import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Navigation } from 'core/src/app/ui/common/components/navigation/navigation';
import { NavigationLink } from 'core/src/app/ui/common/components/navigation/navigation-link';

import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as fromApp from '../store/app.reducers';
import * as AuthActions from '../modules/auth/store/auth.actions';
import * as fromAuthSelectors from '../modules/auth/store/auth.selectors';
import { CustomerWrapperFooterComponent } from '../components/footer/customer-wrapper-footer.component';
import { CustomerWrapperHeaderComponent } from '../components/header/customer-wrapper-header.component';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, takeUntil, tap, take } from 'rxjs/operators';
import { openCloseAnimation } from 'shared/src/app/animations/animation';
import { LoaderService } from 'shared/src/app/interceptors/loader.service';
import { UserService } from 'shared/src/app/services/user.service';
import { LocalStorage } from 'core/src/app/common/services/local-storage.service';

@Component({
  templateUrl: './customer-wrapper.component.html',
  styleUrls: ['./customer-wrapper.component.scss'],
  animations: [
    openCloseAnimation
  ]
})
export class CustomerWrapperComponent implements OnInit, OnDestroy {
  @ViewChild(CustomerWrapperHeaderComponent, { read: false, static: false }) customerHeaderComponent: CustomerWrapperHeaderComponent;
  @ViewChild(CustomerWrapperFooterComponent, { read: false, static: false }) customerFooterComponent: CustomerWrapperFooterComponent;
  navigation: Navigation;
  isAjaxRequest: boolean;
  pollingSub: Subscription;

  bookGenerateStatus$: Observable<string>;
  contentUpdateStatus$: Observable<number>;
  hasSettings = false;
  hasMyAccount = false;

  shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));
  private unsubscribe$: Subject<void> = new Subject();
  constructor(
    private store: Store<fromApp.AppState>,
    private _localStorage: LocalStorage,
    public loaderService: LoaderService,
    private _route: ActivatedRoute,
    private _userService: UserService
  ) { }

  ngOnInit() {
    const paramIsAjaxRequest = this._route.snapshot.queryParamMap.get('isAjaxRequest');
    if (paramIsAjaxRequest) {
      this.isAjaxRequest = JSON.parse(paramIsAjaxRequest);
    }

    this.bookGenerateStatus$ = this.store.pipe(
      select(fromAuthSelectors.selectUserState.selectBookGenerateState),
      map(state => state.syncMessage)
    );
    this.contentUpdateStatus$ = this.store.pipe(
      select(fromAuthSelectors.selectUserState.selectContentUpdateState),
      map(state => state.status)
    );
    this.store.pipe(
      select(fromAuthSelectors.selectUserState.selectFieldSyncResponse),
      takeUntil(this.unsubscribe$)
    ).subscribe(res => {
      if (res.syncStatus) {
        this.store.dispatch(new AuthActions.GetNavigationItems());
      }
    });

    this.store.pipe(
      select(fromAuthSelectors.selectUserState.navigationItems),
      filter(val => val.length > 0),
      tap((navigationItems) => {
        navigationItems.forEach(val => {
          if (val.displayName === 'My Account') {
            this.hasMyAccount = true;
          }

          if (val.displayName === 'Settings') {
            this.hasSettings = true;
          }
        });
      }),
      map(val => {
        return val.filter(v => v.displayName !== 'My Account' && v.displayName !== 'Settings');
      }),
      tap((navigationItems) => {
        const customerNavigation: NavigationLink[] = [];
        navigationItems.map(val => {
          if (val.linkURL.startsWith('/app2/')) {
            customerNavigation.push(new NavigationLink({ label: val.displayName, routerLink: val.linkURL.slice(5), icon: val.icon, canSync: val.canSync, type: val.type }));
          } else {
            customerNavigation.push(new NavigationLink({ label: val.displayName, href: val.linkURL, icon: val.icon, canSync: val.canSync, type: val.type }));
          }
          if (val.displayName === 'Mobile Price book') {
            this.store
              .pipe(select(fromAuthSelectors.selectUserState.user), take(1))
              .subscribe(val => {
                if (val.FeatureFlags['EnableSqlGenerator']) {
                  this.pollPeriodically();
                }
              });
          }

          if (val.displayName === 'Content Updates') {
            this.store
              .pipe(select(fromAuthSelectors.selectUserState.user), take(1))
              .subscribe(val => {
                if (val.FeatureFlags['EnableContentUpdates']) {
                  this.pollContentUpdatePeriodically();
                }
              });
          }
        });
        this.navigation = new Navigation(customerNavigation);
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe();


    this.store.pipe(
      select(fromAuthSelectors.selectUserState.user),
      takeUntil(this.unsubscribe$)
    ).subscribe(user => {
      if (user && user.token && user.token !== '') {
        // this.pollDotNetSession(user);
      }
    });
    
  }

  fieldEdgePolling() {
    this.store.dispatch(new AuthActions.FieldEdgeSync());
    setTimeout(() => {
      this.fieldEdgeSyncInProcess();
    }, 90000);
  }

  fieldEdgeSyncInProcess() {
    this.pollingSub = interval(60000).subscribe(() => {
      this.store.dispatch(new AuthActions.FieldEdgeSyncPolling());
    });

    this.store.pipe(
      select(fromAuthSelectors.selectUserState.selectFieldSyncPollingResponse)
    ).subscribe(val => {
      if (val.syncStatus) {
        this.store.dispatch(new AuthActions.GetNavigationItems());
        this._localStorage.set('IsSyncInProcess', false);
        if (this.pollingSub) {
          this.pollingSub.unsubscribe();
        }
      }
    });
  
  }

  pollDotNetSession(user) {
    const source = interval(5000 * 60).pipe(
      takeUntil(this.unsubscribe$)
    );
    source.subscribe(() => {
      this._userService.isTokenExpired(user.token).subscribe();
    });
  }

  generateBook() {
    this.store.dispatch(new AuthActions.GenerateBook());
    this.store.dispatch(new AuthActions.SetBookStatus({
      syncStatus: false,
      syncMessage: 'InProgress'
    }));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get isExpandedState() {
    return localStorage.getItem('bodyclass') === 'opened-menu' ? 'open' : 'closed';
  }

  pollPeriodically() {
    this.store.dispatch(new AuthActions.BeginBookPolling());
    const source = interval(100000).pipe(
      takeUntil(this.unsubscribe$)
    );
    source.subscribe(_ => {
      this.store.dispatch(new AuthActions.BeginBookPolling());
    });
  }

  pollContentUpdatePeriodically() {
    this.store.dispatch(new AuthActions.BeginContentUpdatePolling());
    const source = interval(100000).pipe(
      takeUntil(this.unsubscribe$)
    );

    source.subscribe(_ => {
      this.store.dispatch(new AuthActions.BeginContentUpdatePolling());
    });
  }
}
