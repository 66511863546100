<form class="forgot-password-form" *ngIf="!(showTermsOfService$ | async) && !(showForgotPasswordSuccess$ | async)" [formGroup]="forgotPasswordformGroup">
  <div class="forgot-password-heading">Forgot Password</div>
  <mat-form-field appearance="outline" class="mat-full-width"> 
    <mat-label> Email
      <span class="req">*</span>
    </mat-label>
    <input type="email" matInput formControlName="email">
    <mat-error *ngIf="this.forgotPasswordformGroup.controls['email'].hasError('required')">
        Please enter your email
    </mat-error>
    <mat-error *ngIf="!this.forgotPasswordformGroup.controls['email'].hasError('required')">
        Please enter a valid email
    </mat-error>
</mat-form-field>
  <div *ngIf="forgotPasswordFailureReason$ | async" class="req">
    {{ forgotPasswordFailureReason$ | async }}
  </div>
  <button [disabled]="isBusy$ | async" mat-flat-button class="link-btn" color="primary" (click)="doForgotPassword()">
    <span [class.hidden]="isBusy$ | async"> Send Reset Link</span>
    <mat-progress-spinner [class.hidden]="!(isBusy$ | async)" color="accent" mode="indeterminate" diameter="20"></mat-progress-spinner>
  </button>
  <div class="forgot-password-heading">
    <a [routerLink]="'/login'" title="Login" class="login-margin">
      Login
    </a>
  </div>
  <div class="tos-div">
    <a (click)="termsOfService()" title="Terms of Service - Privacy Policy">
      Terms of Service - Privacy Policy
    </a>
  </div>
</form>
<pr-terms-of-service *ngIf="showTermsOfService$ | async"></pr-terms-of-service>
<pr-forgot-password-success *ngIf="!(showTermsOfService$ | async) && (showForgotPasswordSuccess$ | async)"></pr-forgot-password-success>
