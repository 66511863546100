import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromApp from '../store/app.reducers';
import * as AuthActions from '../modules/auth/store/auth.actions';
import * as fromAuthSelectors from '../modules/auth/store/auth.selectors';
import { first, map, skipWhile } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class ResetTokenGuard implements CanActivate {
  constructor(
    private store: Store<fromApp.AppState>
  ) { }

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean>  {
    const resetPasswordToken = _route.paramMap.get('resetPasswordToken');
    this.store.dispatch(new AuthActions.VerifyResetPasswordToken(resetPasswordToken));

    return this.store.select(fromAuthSelectors.selectResetPasswordState.isCheckingToken).pipe(
      skipWhile(check => check.isCheckingToken),
      map(check => check.isTokenValid),
      first( ss => {
        return ss;
      })
    );
  }
}
