import { NgModule } from '@angular/core';
import { ConfiguredCurrencyPipe } from './configured-currency.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { BrandCodePipe } from './brand-code.pipe';

const EXPORTED_PIPE = [
  SafeHtmlPipe,
  ConfiguredCurrencyPipe,
  BrandCodePipe
];

@NgModule({
  declarations: [
    EXPORTED_PIPE
  ],

  imports: [
  ],

  exports: [
    EXPORTED_PIPE,
  ]
})

export class CustomPipeModule { }
