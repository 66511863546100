import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pr-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  errorDisplayObj: { imageUrl: string, title: string }
  errorDisplayList = [
    // {
    //   imageUrl:  '/assets/img/error-img1.png',
    //   title: "What’s the diagnosis…"
    // },
    {
      imageUrl:  './assets/img/error-img2.png',
      title: "Something was unplugged…"
    },
    {
      imageUrl:  './assets/img/error-img3.png',
      title: "That doesn’t measure up…"
    },
    {
      imageUrl:  './assets/img/error-img4.png',
      title: "There is a leak in the system…"
    },
    {
      imageUrl: './assets/img/error-img5.png',
      title: "That’s the wrong color paint…"
    },
  ]

  constructor() { }

  ngOnInit() {
    const randomNumber = Math.floor((Math.random() * this.errorDisplayList.length));
    this.errorDisplayObj = this.errorDisplayList[randomNumber];
  }



}