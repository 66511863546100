import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef } from '@angular/material';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomSnackBarComponent implements OnInit {
   data: {
    message: string;
    status: boolean;
   };

  constructor(private snackBarRef: MatSnackBarRef<CustomSnackBarComponent>) {}

  ngOnInit() {
   this.data = this.snackBarRef.containerInstance.snackBarConfig.data;
  }
  close() {
    this.snackBarRef.dismiss();
  }
}
