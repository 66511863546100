import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { UnauthenticatedApiClient } from '../services/api-client';
import {
  IBulkActionTasksRequest,
  ICopyTaskRequest,
  ILibraryImageRequest,
  IPartRequest,
  ITaskRate,
  ITasksRequest,
  IUploadImageRequest,
  PatchRequest } from 'frb-app/src/app/modules/tasks/store/task.model';
import { HttpResponse } from '../models/http-response';
import { Observable } from 'rxjs';

@Injectable()
export class TaskApi {

  constructor(private readonly _apiClient: UnauthenticatedApiClient) {
  }

  public getTasks(tasksRequest: ITasksRequest): Observable<HttpResponse<any>> {
    const request = `${toDataSourceRequestString(tasksRequest.request)}`;
    const params = new HttpParams().set('searchText', tasksRequest.searchText ? tasksRequest.searchText : '' );
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._apiClient.post<any>(`/api/v2/kendotasks`, request, { params: params, headers : headers});
  }

  public getTaskInternal(taskId: number) {
    return this._apiClient.get(`/api/v2/task/${taskId}/Internal`);
  }

  public getTaskCustom(taskId: number) {
    return this._apiClient.get(`/api/v2/task/${taskId}/Custom`);
  }

  public copyTask(CopyTaskRequest: ICopyTaskRequest): Observable<HttpResponse<any>> {
    const req = {
      taskNumber: CopyTaskRequest.copyTo,
      copyMarketingData: CopyTaskRequest.copyMarketingData,
      buisnessTypeId: CopyTaskRequest.buisnessTypeId
    };
    return this._apiClient.post<any>(`/api/v2/task/${CopyTaskRequest.copyFrom}/CopyTask`, req);
  }

  public taskBulkAction(bulkActionTasksRequest: IBulkActionTasksRequest): Observable<HttpResponse<any>> {
    return this._apiClient.post<any>(`/api/v2/bulkaction/tasks`, bulkActionTasksRequest);
  }

  public getAllBusinessType(): Observable<HttpResponse<any>> {
    return this._apiClient.get<any>(`/api/v2/TaskCategories`);
  }

  public getBusinessTypeById(id): Observable<HttpResponse<any>> {
    return this._apiClient.get<any>(`/api/v2/TaskCategories/${id}`);
  }

  public getAllSubCategory(id): Observable<HttpResponse<any>> {
    return this._apiClient.get<any>(`/api/v2/TaskCategories/${id}/TaskCategories`);
  }

  public searchTasks(search: string, businessType?: number, assignmentType?: string, isActive?: string, isOem?: string, pageNumber?: number,  quickAdds?:string): Observable<HttpResponse<any>> {

    let params = new HttpParams().set('PageNo', pageNumber ? pageNumber.toString() : "1").set('PageSize', '100').set('SearchKeyword', search);

    if (businessType) {
      params = params.append('BusinessTypeId', businessType.toString());
    }

    if(assignmentType){
      params = params.append('AssignmentType', assignmentType);
    }

    if(isActive){
      params = params.append('active', isActive);
    }

    
    if(isOem){
      params = params.append('OemFilter', isOem);
    }
    
    if(quickAdds){
      params = params.append('quickAdds', quickAdds);
    }

    return this._apiClient.get<any>(`/api/v2/Tasks`, {params: params});
  }

  public getTaskById(TaskId: number): Observable<HttpResponse<any>> {
    return this._apiClient.get<any>(`/api/v2/task/` + TaskId);
  }

  public saveTask(patch: PatchRequest[], taskId: number): Observable<HttpResponse<any>> {
    return this._apiClient.patch<any>(`/api/v2/task/${taskId}`, patch);
  }

  public getTaskPricesById(TaskId: number): Observable<HttpResponse<any>> {
    return this._apiClient.get<any>(`/api/v2/task/${TaskId}/taskprices`);
  }

  public saveInternalTaskData(patch: PatchRequest[], taskId: number): Observable<HttpResponse<any>> {
    return this._apiClient.patch<any>(`/api/v2/task/${taskId}/Internal`, patch);
  }

  public savePartTaskAssignment(patch: PatchRequest[], taskId: number, partId: number): Observable<HttpResponse<any>> {
    return this._apiClient.patch<any>(`/api/v2/task/${taskId}/TaskPartAssignment/${partId}`, patch);
  }

  public updateTaskCustom(obj: {key: string, value: string}, taskId: number): Observable<HttpResponse<any>> {
    return this._apiClient.put<any>(`/api/v2/task/${taskId}/Custom`, obj);
  }

  public addNewTask(addNewTask): Observable<HttpResponse<any>> {
    return this._apiClient.post<any>(`/api/v2/tasks/`, addNewTask);
  }


  public addCrossSell(id: string, taskId: string) {
    const crossSell = {
      taskId: taskId
    };
    return this._apiClient.post<any>(`/api/v2/task/${id}/AddCrossSellTask`, crossSell);
  }

  public addUpSell(id: string, taskId: string) {
    const crossSell = {
      taskId: taskId
    };
    return this._apiClient.post<any>(`/api/v2/task/${id}/AddUpSellTask`, crossSell);
  }

  getGlobalLibrary(searchKeyword: string) {
    const params = new HttpParams().set('SearchKeyword', searchKeyword ? searchKeyword : '' );
    return this._apiClient.get<any>(`/api/v2/Images/GlobalLibrary`, { params : params});
  }

  getYourLibrary(searchKeyword: string) {
    const params = new HttpParams().set('SearchKeyword', searchKeyword ? searchKeyword : '' );
    return this._apiClient.get<any>(`/api/v2/Images/YourLibrary`, { params : params});
  }

  getGoogleLibrary(searchKeyword: string, page: number) {
    const params = new HttpParams().set('SearchKeyword', searchKeyword ? searchKeyword : '' ).set('imageType', 'large')
    .set('PageNo', page.toString());
    return this._apiClient.get<any>(`/api/v2/Images/Google`, { params : params});
  }

  public addTaskPart(part: IPartRequest[], taskId: number) {
    return this._apiClient.post<any>(`/api/v2/task/${taskId}/Part`, part);
  }

  public addTaskCategories(categorieIds: { id: number }[], taskId: number) {
    return this._apiClient.post<any>(`/api/v2/task/${taskId}/AssignCategory`, categorieIds);
  }

  public assignLibraryImage(yourGlobalLibrary: ILibraryImageRequest[], taskId: number) {
    return this._apiClient.post<any>(`/api/v2/Task/${taskId}/AssignLibraryImage`, yourGlobalLibrary);
  }

  public uploadImage(googleSystemImage: IUploadImageRequest[], taskId: number) {
    return this._apiClient.post<any>(`/api/v2/task/${taskId}/UploadImage`, googleSystemImage);
  }

  public deleteTaskPart(assignmentId: number | undefined) {
    return this._apiClient.delete(`/api/v2/RemoveTaskPartAssignment/${assignmentId}`);
  }

  public deleteTaskCrossSell(assignmentId: number | undefined, taskId: number) {
    return this._apiClient.delete(`/api/v2/Task/${taskId}/RemoveCrossSellTask/${assignmentId}`);
  }

  public deleteTaskUpSell(assignmentId: number | undefined, taskId: number) {
    return this._apiClient.delete(`/api/v2/Task/${taskId}/RemoveUpSellTask/${assignmentId}`);
  }

  public convertGoogleImageInBase64(imageUrl: string): Observable<HttpResponse<string>> {
    const params = new HttpParams().set('ImageUrl', imageUrl);
    return this._apiClient.get(`/api/v2/Images/UploadImage`, { params : params});
  }

  public getTaskRates(taskId: number) {
    return this._apiClient.get<ITaskRate>(`/api/v2/task/${taskId}/rates`);
  }

  public getBrands() {
    const params = new HttpParams().set('PageSize', '-1');
    return this._apiClient.get<any>(`/api/v2/Brand`, {params});
  }

  public deleteTaskImages(taskId: number) {
    return this._apiClient.delete(`/api/v2/Task/${taskId}/RemoveImages`);
  }

  public deleteTaskCategory(categoryId: number | undefined, taskId: number) {
    return this._apiClient.delete(`/api/v2/Task/${taskId}/Category/${categoryId}`);
  }

  public getTaskBrands(): Observable<HttpResponse<any>> {
    const params = new HttpParams().set('PageNo', '1').set('PageSize', '-1');
    return this._apiClient.get<any>(`/api/v2/Brand`, {params});
  }

  public getGroups() {
    return this._apiClient.get<any>(`/api/v2/Groups`);
  }

}
