import { ContentApi } from 'frb-api/src/apis/content-api';
import * as i0 from "@angular/core";
import * as i1 from "../../../../frb-api/src/apis/content-api";
var ContentService = /** @class */ (function () {
    function ContentService(_contentApi) {
        this._contentApi = _contentApi;
    }
    ContentService.prototype.getLoginPageHtml = function () {
        return this._contentApi.getLoginPageHtml();
    };
    ContentService.prototype.getTermsAndServiceHtml = function () {
        return this._contentApi.getTermsAndServiceHtml();
    };
    ContentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContentService_Factory() { return new ContentService(i0.ɵɵinject(i1.ContentApi)); }, token: ContentService, providedIn: "root" });
    return ContentService;
}());
export { ContentService };
