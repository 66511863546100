import { EventManager } from '@angular/platform-browser';
import { debounce } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
var SCREEN_BREAKPOINTS = ['xs', 'sm', 'md', 'lg'];
var BREAKPOINT_CONFIGS = [
    {
        breakpoint: 'lg',
        size: '75em',
    },
    {
        breakpoint: 'md',
        size: '62em',
    },
    {
        breakpoint: 'sm',
        size: '48em',
    },
    {
        breakpoint: 'xs',
        size: '0em',
    },
];
/**
 * Allows consumers of this service to subscribe to screen breakpoint changes so they can update their behavior accordingly
 */
var MediaQuery = /** @class */ (function () {
    function MediaQuery(eventManager) {
        // Stores the current breakpoint so we know when we need to publish a breakpoint change event
        var currentBreakpoint;
        var self = this;
        var breakpoints = BREAKPOINT_CONFIGS.map(function (breakpointConfig) {
            return {
                breakpoint: breakpointConfig.breakpoint,
                mediaQuery: window.matchMedia("(min-width: " + breakpointConfig.size + ")"),
            };
        });
        // Create the subscription that we're going to publish breakpoint change events to
        this._changesSubject = new BehaviorSubject({
            new: getBreakpoint(),
        });
        // Listen to the browser resize event and only process once the screen hasn't been resized for 100ms
        eventManager.addGlobalEventListener('window', 'resize', debounce(onResize, 100));
        function onResize() {
            var newBreakpoint = getBreakpoint();
            if (newBreakpoint === currentBreakpoint) {
                return;
            }
            self._changesSubject.next({
                old: currentBreakpoint,
                new: newBreakpoint,
            });
            currentBreakpoint = newBreakpoint;
        }
        function getBreakpoint() {
            var result = 'lg';
            for (var i = 0; i < breakpoints.length; i++) {
                var breakpoint = breakpoints[i];
                if (breakpoint.mediaQuery.matches) {
                    result = breakpoint.breakpoint;
                    break;
                }
            }
            return result;
        }
    }
    Object.defineProperty(MediaQuery, "breakpoints", {
        get: function () {
            return SCREEN_BREAKPOINTS;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MediaQuery.prototype, "changes", {
        get: function () {
            return this._changesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    MediaQuery.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MediaQuery_Factory() { return new MediaQuery(i0.ɵɵinject(i1.EventManager)); }, token: MediaQuery, providedIn: "root" });
    return MediaQuery;
}());
export { MediaQuery };
