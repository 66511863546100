import * as tslib_1 from "tslib";
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as AuthActions from './auth.actions';
import { LocalStorage } from 'core/src/app/common/services/local-storage.service';
import { ContentService } from 'frb-app/src/app/services/content.service';
import { NgxPendoService } from 'ngx-pendo';
import { defer, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, switchMap } from 'rxjs/operators';
import { CustomSnackBarComponent } from 'shared/src/app/components/custom-snackbar/custom-snackbar.component';
import { UserService } from 'shared/src/app/services/user.service';
import { SAMPLE_MASK } from 'shared/src/app/components/task-card/task-sample-data';
var AuthEffects = /** @class */ (function () {
    function AuthEffects(actions$, router, _userService, snackbar, _localStorage, _contentService, _ngxPendoService) {
        var _this = this;
        this.actions$ = actions$;
        this.router = router;
        this._userService = _userService;
        this.snackbar = snackbar;
        this._localStorage = _localStorage;
        this._contentService = _contentService;
        this._ngxPendoService = _ngxPendoService;
        this.init$ = defer(function () {
            var userData = _this._localStorage.get('ACTIVE_USER');
            userData = JSON.parse(userData);
            if (userData && userData.userData && userData.userData.Company) {
                _this._ngxPendoService.initialize({
                    id: userData.username,
                    user_id: userData.userData.Company.CustomerNumber,
                    Customer_Number: userData.userData.Company.CustomerNumber,
                    Phone: userData.userData.Company.Phone,
                    company_id: userData.userData.Company.CustomerNumber,
                    _RoleName: userData.RoleName,
                    _EmailAddress: userData.username,
                    _viewAllQuotes: userData.ViewAllQuotes.toString(),
                    _canShowHideTasks: userData.CanShowHideTasks.toString(),
                    _canDeleteQuotes: userData.CanDeleteQuotes.toString(),
                    company: {
                        id: userData.userData.Company.CustomerNumber,
                        name: userData.userData.CompanyName
                    }.toString()
                }, tslib_1.__assign({ id: userData.userData.Company.CompanyId.toString() }, userData.FeatureFlags, { _Subscription: userData.userData.SubscriptionName, _SubscriptionType: userData.SubscriptionType, _PartnerType: userData.PartnerType, _MRR: userData.Mrr ? userData.Mrr.toString() : '', _Timezone: userData.TimeZone, _DemoAccount: userData.DemoAccount ? userData.DemoAccount.toString() : '', _CNumber: userData.userData.Company.CustomerNumber, _State: userData.State, _ZipCode: userData.ZipCode, _BusinessTypeList: userData.BusinessTypesList, _InvoiceDate: userData.invoiceDate ? userData.invoiceDate.toString() : '', _SubscriptionPurchaseDate: userData.SubscriptionPurchaseDate ? userData.SubscriptionPurchaseDate.toString() : '', _BillingDate: userData.BillingDate ? userData.BillingDate.toString() : '', _SubscriptionDate: userData.SubscriptionDate, _UsersAllowed: userData.UsersAllowed ? userData.UsersAllowed.toString() : '' }));
                return of(new AuthActions.SigninSuccess(userData));
            }
            else {
                // if the user isn't there return a logout action
                return of(new AuthActions.LogoutSuccess());
            }
        });
        this.authSignup = this.actions$
            .pipe(ofType(AuthActions.TRY_SIGNUP));
        this.authGetLoginDetails = this.actions$
            .pipe(ofType(AuthActions.TRY_GET_LOGIN_DETAILS), map(function (action) {
            return action.payload;
        }), exhaustMap(function (authData) {
            var activeAdminUser = _this._localStorage.get('ACTIVEAdmin_USER');
            if (activeAdminUser) {
                _this._localStorage.remove('ACTIVEAdmin_USER');
            }
            return _this._userService.getLoginDetails(authData)
                .pipe(map(function (response) {
                if (response && response.status) {
                    if (response.termsaccepted) {
                        return {
                            type: AuthActions.TRY_SIGNIN,
                            payload: authData
                        };
                    }
                    else {
                        return {
                            type: AuthActions.TERMS_OF_SERVICES,
                            payload: true
                        };
                    }
                }
                else {
                    return {
                        type: AuthActions.GET_LOGIN_DETAILS_FAIL,
                        payload: response.message,
                    };
                }
            }));
        }));
        this.authSignin = this.actions$
            .pipe(ofType(AuthActions.TRY_SIGNIN), map(function (action) {
            return action.payload;
        }), exhaustMap(function (loginModel) {
            var activeAdminUser = _this._localStorage.get('ACTIVEAdmin_USER');
            if (activeAdminUser) {
                _this._localStorage.remove('ACTIVEAdmin_USER');
            }
            return _this._userService.login(loginModel).pipe(map(function (response) {
                // Redirect page on response base
                if (response && response.status && response.data && response.data.redirect) {
                    var user = {
                        id: response.data.username,
                        email: response.data.email,
                        username: loginModel.userName,
                        role: response.data.role,
                        token: response.data.token,
                        rememberMe: loginModel.rememberMe,
                        url: response.data.url,
                        redirect: response.data.redirect,
                        isTokenValid: true,
                        isCheckingToken: response.data.isCheckingToken,
                        companyName: response.data.userData.CompanyName,
                        companyLogo: response.data.userData.CompanyLogo,
                        userTypeId: response.data.userData.UserTypeId,
                        isSinglePartDB: response.data.isSinglePartDB,
                        TaskNumberAutoIncrement: response.data.TaskNumberAutoIncrement,
                        SubscriptionName: response.data.userData.SubscriptionName,
                        SubscriptionStatus: response.data.userData.SubscriptionStatusString,
                        DaysLeft: response.data.userData.DaysLeft,
                        FeatureFlags: response.data.FeatureFlags,
                        BusinessTypes: response.data.BusinessTypes,
                        SubscriptionType: response.data.SubscriptionType,
                        DemoAccount: response.data.DemoAccount,
                        TimeZone: response.data.TimeZone,
                        PartnerType: response.data.PartnerType,
                        RoleName: response.data.RoleName,
                        Mrr: response.data.Mrr,
                        ViewAllQuotes: response.data.ViewAllQuotes,
                        CanDeleteQuotes: response.data.CanDeleteQuotes,
                        CanShowHideTasks: response.data.CanShowHideTasks,
                        State: response.data.State,
                        ZipCode: response.data.ZipCode,
                        userData: response.data.userData,
                        BusinessTypesList: response.data.BusinessTypesList,
                        InvoiceDate: response.data.InvoiceDate,
                        SubscriptionPurchaseDate: response.data.SubscriptionPurchaseDate,
                        BillingDate: response.data.BillingDate,
                        SubscriptionDate: response.data.SubscriptionDate,
                        UsersAllowed: response.data.UsersAllowed
                    };
                    _this._localStorage.set('ACTIVE_USER', user);
                    _this._ngxPendoService.initialize({
                        id: response.data.username,
                        user_id: response.data.userData.Company.CustomerNumber,
                        Customer_Number: response.data.userData.Company.CustomerNumber,
                        Phone: response.data.userData.Company.Phone,
                        company_id: response.data.userData.Company.CustomerNumber,
                        company: {
                            id: response.data.userData.Company.CustomerNumber,
                            name: response.data.userData.CompanyName
                        }.toString(),
                        _RoleName: response.data.RoleName,
                        _EmailAddress: response.data.username,
                        _viewAllQuotes: response.data.ViewAllQuotes.toString(),
                        _canShowHideTasks: response.data.CanShowHideTasks.toString(),
                        _canDeleteQuotes: response.data.CanDeleteQuotes.toString(),
                    }, tslib_1.__assign({ id: response.data.userData.Company.CompanyId.toString() }, response.data.FeatureFlags, { _Subscription: response.data.userData.SubscriptionName, _SubscriptionType: response.data.SubscriptionType, _PartnerType: response.data.PartnerType, _MRR: response.data.Mrr ? response.data.Mrr.toString() : '', _Timezone: response.data.TimeZone, _DemoAccount: response.data.DemoAccount ? response.data.DemoAccount.toString() : '', _CNumber: response.data.userData.Company.CustomerNumber, _State: response.data.State, _ZipCode: response.data.ZipCode, _BusinessTypeList: response.data.BusinessTypesList, _InvoiceDate: response.data.InvoiceDate ? response.data.InvoiceDate.toString() : '', _SubscriptionPurchaseDate: response.data.SubscriptionPurchaseDate ? response.data.SubscriptionPurchaseDate.toString() : '', _BillingDate: response.data.BillingDate ? response.data.BillingDate.toString() : '', _SubscriptionDate: response.data.SubscriptionDate, _UsersAllowed: response.data.UsersAllowed ? response.data.UsersAllowed.toString() : '' }));
                    window.location.href = response.data.url;
                    return {
                        type: AuthActions.SIGNIN_SUCCESS,
                        payload: user
                    };
                }
                else {
                    return {
                        type: AuthActions.SIGNIN_FAIL,
                        payload: response.message
                    };
                }
            }));
        }));
        // LOGOUT
        this.logout = this.actions$.pipe(ofType(AuthActions.LOGOUT), switchMap(function () {
            return _this._userService.logout();
        }), map(function (res) {
            if (res.status) {
                var userData = _this._localStorage.get('ACTIVE_USER');
                userData = JSON.parse(userData);
                userData.token = '';
                userData.SubscriptionStatus = 'LoggedOut';
                userData.username = undefined;
                _this._localStorage.set('ACTIVE_USER', userData);
                _this.router.navigate(['login']);
                return new AuthActions.LogoutSuccess();
            }
            return new AuthActions.LogoutFailure();
        }), catchError(function () {
            return [{
                    type: AuthActions.LOGOUT_FAILURE
                }];
        }));
        // @Effect({ dispatch: false })
        // logout = this.actions$
        //   .pipe(
        //     ofType(AuthActions.LOGOUT),
        //     exhaustMap(() => {
        //       return this._userService.logout();
        //     }),
        //     tap(() => {
        //       let userData: any = this._localStorage.get('ACTIVE_USER') as string;
        //       userData =  JSON.parse(userData);
        //       userData.token = '';
        //       this._localStorage.set('ACTIVE_USER', userData);
        //       this.router.navigate(['login']);
        //     }));
        // FORGOT PASSWORD
        this.forgotPassword = this.actions$
            .pipe(ofType(AuthActions.TRY_FORGOT_PASSWORD), map(function (action) {
            return action.payload;
        }), exhaustMap(function (authData) {
            return _this._userService.forgotPassword(authData.email);
        }), switchMap(function (res) {
            if (res.status) {
                return [
                    {
                        type: AuthActions.FORGOT_PASSWORD_SUCCESS,
                    }
                ];
            }
            return [{
                    type: AuthActions.FORGOT_PASSWORD_FAILURE,
                    payload: res.message,
                }];
        }), catchError(function (error) {
            return [{
                    type: AuthActions.FORGOT_PASSWORD_FAILURE,
                    payload: error,
                }];
        }));
        // Reset Password
        this.resetPassword = this.actions$
            .pipe(ofType(AuthActions.RESET_PASSWORD), map(function (action) {
            return action.payload;
        }), exhaustMap(function (authData) {
            return _this._userService.resetPassword(authData);
        }), switchMap(function (res) {
            if (res.status) {
                _this.router.navigate(['login']);
                _this.getMessage(res.message);
                return [
                    {
                        type: AuthActions.RESET_PASSWORD_SUCCESS
                    }
                ];
            }
            return [{
                    type: AuthActions.RESET_PASSWORD_FAILURE,
                    payload: res.message,
                }];
        }), catchError(function (error) {
            return [{
                    type: AuthActions.RESET_PASSWORD_FAILURE,
                    payload: error,
                }];
        }));
        // TERMS OF SERVICES CONTENT
        this.temsOfServicesContent = this.actions$
            .pipe(ofType(AuthActions.TERMS_OF_SERVICES_CONTENT), map(function (action) {
            return action;
        }), switchMap(function () {
            return _this._contentService.getTermsAndServiceHtml();
        }), switchMap(function (res) {
            if (res && res.data) {
                return [{
                        type: AuthActions.TERMS_OF_SERVICES_CONTENT_SUCCESS,
                        payload: {
                            htmlContentInput: res.data.content,
                            loaded: true,
                        }
                    }];
            }
            return [{
                    type: AuthActions.TERMS_OF_SERVICES_CONTENT_FAIL
                }];
        }));
        this.IsTokenExpired = this.actions$.pipe(ofType(AuthActions.IS_TOKEN_EXPIRED), map(function (action) {
            return action.payload;
        }), exhaustMap(function (user) {
            return _this._userService.isTokenExpired(user.token).pipe(map(function (isAuth) {
                return {
                    isAuth: isAuth,
                    user: user
                };
            }));
        }), switchMap(function (res) {
            if (res.isAuth) {
                window.location.href = res.user.url;
                return [{
                        type: AuthActions.IS_TOKEN_EXPIRED_SUCCESS,
                        payload: res.user
                    }];
            }
            return [{
                    type: AuthActions.IS_TOKEN_EXPIRED_FAIL,
                    payload: res.user
                }];
        }), catchError(function (err) {
            return [{
                    type: AuthActions.IS_TOKEN_EXPIRED_FAIL,
                    payload: err
                }];
        }));
        this.VerifyResetPasswordToken = this.actions$.pipe(ofType(AuthActions.VERIFY_RESET_PASSWORD_TOKEN), map(function (action) {
            return action.payload;
        }), exhaustMap(function (resetPasswordToken) {
            return _this._userService.verifyResetPasswordToken(resetPasswordToken);
        }), switchMap(function (res) {
            if (res.status) {
                return [{
                        type: AuthActions.VERIFY_RESET_PASSWORD_TOKEN_SUCCESS
                    }];
            }
            _this.getMessage(res.message);
            _this.router.navigate(['login']);
            return [{
                    type: AuthActions.VERIFY_RESET_PASSWORD_TOKEN_FAIL
                }];
        }));
        this.GetNavigationItems = this.actions$.pipe(ofType(AuthActions.GET_NAVIGATION_ITEMS), switchMap(function () {
            return _this._userService.getNavigationItems();
        }), map(function (res) {
            if (res.status) {
                return new AuthActions.GetNavigationItemsSuccess(res.data.menues);
            }
            return [{
                    type: AuthActions.GET_NAVIGATION_ITEMS_FAIL
                }];
        }));
        this.GetCompanyFeatures = this.actions$.pipe(ofType(AuthActions.GET_COMPANY_FEATURES), switchMap(function () {
            return _this._userService.getcompanyfeatures();
        }), map(function (res) {
            if (res.status) {
                return new AuthActions.GetCompanyFeaturesSuccess(res.data.screenList);
            }
            return [{
                    type: AuthActions.GET_COMPANY_FEATURES_FAIL
                }];
        }));
        this.getMaskedLabels = this.actions$.pipe(ofType(AuthActions.GET_MASKED_LABELS), switchMap(function () {
            return _this._userService.getMaskedLabels();
        }), map(function (res) {
            if (res.status) {
                return res.data.length ? new AuthActions.GetMaskedLabelsSuccess(res.data[0]) : new AuthActions.GetMaskedLabelsSuccess(SAMPLE_MASK);
            }
            return [new AuthActions.GetMaskedLabelsFail(SAMPLE_MASK)];
        }));
        this.FieldEdgeSync = this.actions$.pipe(ofType(AuthActions.FIELD_EDGE_SYNC), switchMap(function () {
            return _this._userService.fieldEdgeSync();
        }), map(function (res) {
            _this.getMessage(res.message);
            if (res.status) {
                _this._localStorage.set('IsSyncInProcess', true);
                return new AuthActions.FieldEdgeSyncSuccess(res.message);
            }
            return new AuthActions.FieldEdgeSyncFail(res.message);
        }));
        this.generateBook$ = this.actions$.pipe(ofType(AuthActions.GENERATE_BOOK), switchMap(function () { return _this._userService.generateBook().pipe(map(function (_) {
            _this.snackbar.openFromComponent(CustomSnackBarComponent, { horizontalPosition: 'center', verticalPosition: 'top', duration: 3000, data: { message: 'Your book is being generated, this will take a moment.', status: true } });
            return new AuthActions.SetBookStatus({
                syncStatus: false,
                syncMessage: 'InProgress'
            });
        }), catchError(function (_) {
            return of(new AuthActions.SetBookStatus({
                syncStatus: true,
                syncMessage: 'FailedException'
            }));
        })); }));
        this.pollBookStatus$ = this.actions$.pipe(ofType(AuthActions.BEGIN_BOOK_POLLING), concatMap(function (_) { return _this._userService.getBookStatus().pipe(map(function (res) {
            return new AuthActions.SetBookStatus({
                syncStatus: res.data !== 'InProgress',
                syncMessage: res.data
            });
        })); }));
        this.pollContentUpdateStatus$ = this.actions$.pipe(ofType(AuthActions.BEGIN_CONTENTUPDATE_POLLING), concatMap(function (_) { return _this._userService.getContentUpdateStatus().pipe(map(function (res) {
            return new AuthActions.SetContentUpdateStatus({ status: res.data.productStatus });
        })); }));
        this.FieldEdgeSyncPolling = this.actions$.pipe(ofType(AuthActions.FIELD_EDGE_SYNC_POLLING), switchMap(function () {
            return _this._userService.fieldEdgeSyncPolling();
        }), map(function (res) {
            if (!res.status) {
                return new AuthActions.FieldEdgeSyncPollingSuccess(res.message);
            }
            return new AuthActions.FieldEdgeSyncPollingFail(res.message);
        }));
        this.GlobalError = this.actions$.pipe(ofType(AuthActions.ADD_GLOBAL_ERROR), map(function (action) {
            return action.payload;
        }), switchMap(function (error) {
            if (error.status === 412 || error.status === 401) {
                var userData = _this._localStorage.get('ACTIVE_USER');
                if (userData) {
                    userData = JSON.parse(userData);
                    userData.token = '';
                    _this._localStorage.set('ACTIVE_USER', userData);
                }
                _this.router.navigate(['login']);
                return [
                    {
                        type: AuthActions.LOGOUT_SUCCESS
                    }
                ];
            }
            else if (error.status === 500) {
                _this.snackbar.openFromComponent(CustomSnackBarComponent, { horizontalPosition: 'center', verticalPosition: 'top', duration: 3000, data: { message: 'Something went wrong, please try again', status: false } });
            }
            else if (error.status === 400) {
                _this.snackbar.openFromComponent(CustomSnackBarComponent, { horizontalPosition: 'center', verticalPosition: 'top', duration: 3000, data: { message: error.error.message, status: false } });
            }
            else if (error.status === 405) {
                _this.snackbar.openFromComponent(CustomSnackBarComponent, { horizontalPosition: 'center', verticalPosition: 'top', duration: 3000, data: { message: error.error.message, status: false } });
            }
            return [
                { type: 'NO_ACTION' }
            ];
        }));
    }
    AuthEffects.prototype.getMessage = function (message) {
        this.snackbar.openFromComponent(CustomSnackBarComponent, { horizontalPosition: 'center', verticalPosition: 'top', duration: 3000, data: { message: message, status: true } });
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "init$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "authSignup", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "authGetLoginDetails", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "authSignin", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "logout", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "forgotPassword", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "resetPassword", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "temsOfServicesContent", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "IsTokenExpired", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "VerifyResetPasswordToken", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "GetNavigationItems", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "GetCompanyFeatures", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "getMaskedLabels", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "FieldEdgeSync", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "generateBook$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "pollBookStatus$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "pollContentUpdateStatus$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "FieldEdgeSyncPolling", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "GlobalError", void 0);
    return AuthEffects;
}());
export { AuthEffects };
