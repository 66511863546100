<div class="toggle-sub-menu">
    <mat-accordion>
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ subMenu.label }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngIf="subMenu.subMenus && subMenu.subMenus.length">
                <ng-container *ngFor="let menu of subMenu.subMenus">
                    <pr-toggle-sub-menu *ngIf="isToggleSubMenu(menu) else  subMenuTemplate" [subMenu]="menu"></pr-toggle-sub-menu>
                    <ng-template #subMenuTemplate>
                        <pr-sub-menu [subMenu]="menu"></pr-sub-menu>
                    </ng-template>
                </ng-container>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>