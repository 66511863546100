import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import * as fromApp from '../../../../store/app.reducers';
import * as AuthActions from '../../store/auth.actions';
import * as fromAuthSelectors from '../../store/auth.selectors';
@Component({
  selector: 'pr-terms-of-service-content',
  templateUrl: './terms-of-service-content.component.html'
})

export class TermsOfServiceContentComponent implements OnInit, OnDestroy {
  htmlContentInput$: Observable<string>;
  loaded$: Observable<boolean>;
  loadedSubscription: Subscription;

  @Output() loadEvent = new EventEmitter<boolean>();
  constructor(private store: Store<fromApp.AppState>) { }

  ngOnInit() {
    this.loaded$ = this.store.select(fromAuthSelectors.selectTermsOfServiceState.loaded);
    this.htmlContentInput$ = this.store.select(fromAuthSelectors.selectTermsOfServiceState.htmlContentInput);
    this.loadedSubscription = this.loaded$.subscribe(val => {
      this.loadEvent.emit(val);
    });

    this.store.dispatch(new AuthActions.TermsOfServiceContent());
  }

  ngOnDestroy() {
    this.loadedSubscription.unsubscribe();
  }
}
