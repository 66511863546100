import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducers';
import { selectUserState } from '../../modules/auth/store/auth.selectors';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-hold-alert',
  templateUrl: './hold-alert.component.html',
  styleUrls: ['./hold-alert.component.scss']
})
export class HoldAlertComponent implements OnInit {

  subscriptionStatus$: Observable<string>;
  daysToFailure$: Observable<number>;

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.subscriptionStatus$ = this.store.pipe(
      select(selectUserState.user),
      map(user => user.SubscriptionStatus)
    );

    this.daysToFailure$ =  this.store.pipe(
      select(selectUserState.user),
      map(user => user.DaysLeft)
    );
  }

}
