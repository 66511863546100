import { Injectable } from '@angular/core';
import { isObjectLike, toString } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class LocalStorage {
  private static key(key: string): string {
    return `ps:${key}`;
  }

  get(key: string): string | null {
    return localStorage.getItem(LocalStorage.key(key));
  }

  set<T>(key: string, value: T) {
    const valueToStore = isObjectLike(value) ? JSON.stringify(value) : toString(value);
    localStorage.setItem(LocalStorage.key(key), valueToStore);
  }

  remove(key: string) {
    localStorage.removeItem(LocalStorage.key(key));
  }
}
