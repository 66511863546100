import { Store } from '@ngrx/store';
import * as AuthActions from '../modules/auth/store/auth.actions';
import * as fromAuthSelectors from '../modules/auth/store/auth.selectors';
import { of } from 'rxjs';
import { first, map, skipWhile, switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var NoAuthGuard = /** @class */ (function () {
    function NoAuthGuard(store) {
        this.store = store;
    }
    NoAuthGuard.prototype.canActivate = function (_route, _state) {
        var _this = this;
        return this.store.select(fromAuthSelectors.selectUserState.user).pipe(first(), map(function (user) {
            if (user && user.token && user.token !== '') {
                _this.store.dispatch(new AuthActions.IsTokenExpired(user));
                return false;
            }
            return true;
        }), switchMap(function (res) {
            if (res) {
                return of(true);
            }
            else {
                return _this.store.select(fromAuthSelectors.selectUserState.isCheckingToken).pipe(skipWhile(function (check) { return check.isCheckingToken; }), map(function (check) { return check.isTokenValid; }), first());
            }
        }));
    };
    NoAuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NoAuthGuard_Factory() { return new NoAuthGuard(i0.ɵɵinject(i1.Store)); }, token: NoAuthGuard, providedIn: "root" });
    return NoAuthGuard;
}());
export { NoAuthGuard };
