<div class="image-icon-sub-menu-container" *ngIf="selectedTopMainMenu">
    <div class="sub-menu-wrapper-container">
        <label> <span>{{ selectedTopMainMenu.subMenuLabel}} </span></label>
        <div class="sub-menu-wrapper">
            <ng-container *ngFor="let subMenu of selectedTopMainMenu.subMenus">
                <pr-toggle-sub-menu *ngIf="isToggleSubMenu(subMenu) else  subMenuTemplate" [subMenu]="subMenu">
                </pr-toggle-sub-menu>
                <ng-template #subMenuTemplate>
                    <pr-sub-menu [subMenu]="subMenu"></pr-sub-menu>
                </ng-template>
            </ng-container>
        </div>
    </div>
</div>