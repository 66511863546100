import { Component } from '@angular/core';
import { CoreConfig } from 'core/src/app/common/services/core-config.service';
import { SocialLinks, SocialMediaItem } from './social-media';

@Component({
  selector: 'pr-social-media',
  templateUrl: './social-media.component.html'
})

export class SocialMediaComponent {
  rootUrl: string;
  constructor(config: CoreConfig) {
    this.rootUrl = config.environment.urls.siteRoot;
  }

  socialLinks: SocialMediaItem[] = SocialLinks;
}
