<div class="menu-container" (mouseleave)="setSelectedMainMenu(null);">
  <div class="main-menu-container">
    <div>
      <div class="top-logo">
        <mat-icon svgIcon='group1194' class="site-icon"></mat-icon>
        <mat-icon class="hover-icon" [svgIcon]="isSmartPriceUser ? 'smart-price-logo-icon':'integrate-logo-icon'">
        </mat-icon>
      </div>
      <ul class="top-menu">
        <ng-container *ngFor="let menu of topMainMenu">
            <ng-container *ngIf="menu.validatePermission else menuTemplate">
              <ng-container *ngIf="menu.hrefLink">
                <li *validatePermission="menu.permissions" (mouseenter)="setSelectedMainMenu(menu)">
                  <a [href]="menu.hrefLink" class="nav-link" routerLinkActive="active" [matTooltip]="menu.toolTipText"
                    matTooltipClass="menu-tool-tip" matTooltipPosition="right">
                    <mat-icon [svgIcon]="menu.svgIcon"></mat-icon>
                  </a>
                </li>
              </ng-container>
              <ng-container *ngIf="!menu.hrefLink">
                <li *validatePermission="menu.permissions" (mouseenter)="setSelectedMainMenu(menu)">
                  <a [routerLink]="menu.routerLink" class="nav-link" routerLinkActive="active"
                    [matTooltip]="menu.toolTipText" matTooltipPosition="right" matTooltipClass="menu-tool-tip">
                    <mat-icon [svgIcon]="menu.svgIcon"></mat-icon>
                  </a>
                </li>
              </ng-container>
  
            </ng-container>
            <ng-template #menuTemplate>
              <ng-container *ngIf="menu.hrefLink">
                <li>
                  <a [href]="menu.hrefLink" class="nav-link" routerLinkActive="active" [matTooltip]="menu.toolTipText"
                    matTooltipClass="menu-tool-tip" matTooltipPosition="right">
                    <mat-icon [svgIcon]="menu.svgIcon"></mat-icon>
                  </a>
                </li>
              </ng-container>
              <ng-container *ngIf="!menu.hrefLink">
                <li (mouseenter)="setSelectedMainMenu(menu)">
                  <a [routerLink]="menu.routerLink" class="nav-link" routerLinkActive="active"
                    [matTooltip]="menu.toolTipText" matTooltipPosition="right" matTooltipClass="menu-tool-tip">
                    <mat-icon [svgIcon]="menu.svgIcon"></mat-icon>
                  </a>
                </li>
              </ng-container>
            </ng-template>
        </ng-container>
      </ul>
    </div>

    <ul class="bottom-menu">
      <ng-container *ngFor="let menu of bottomMainMenu">
        <ng-container
          *ngIf="menu.toolTipText!=='Mobile Price Book' && menu.toolTipText!=='Content Management' else staticBottomMainMenuTemplate">
          <ng-container *ngIf="menu.validatePermission else nonPermissionMenuTemplate">
            <ng-container *ngIf="menu.hrefLink">
              <li *validatePermission="menu.permissions" (mouseenter)="setSelectedMainMenu(menu)">
                <a [href]="menu.hrefLink" class="nav-link" routerLinkActive="active" [matTooltip]="menu.toolTipText"
                  matTooltipClass="menu-tool-tip" matTooltipPosition="right">
                  <mat-icon [svgIcon]="menu.svgIcon"></mat-icon>
                </a>
              </li>
            </ng-container>
            <ng-container *ngIf="!menu.hrefLink">
              <li *validatePermission="menu.permissions" (mouseenter)="setSelectedMainMenu(menu)">
                <a [routerLink]="menu.routerLink" class="nav-link" routerLinkActive="active"
                  [matTooltip]="menu.toolTipText" matTooltipPosition="right" matTooltipClass="menu-tool-tip">
                  <mat-icon [svgIcon]="menu.svgIcon"></mat-icon>
                </a>
              </li>
            </ng-container>
          </ng-container>
          <ng-template #nonPermissionMenuTemplate>
            <ng-container *ngIf="menu.hrefLink">
              <li (mouseenter)="setSelectedMainMenu(menu)">
                <a [href]="menu.hrefLink" class="nav-link" routerLinkActive="active" [matTooltip]="menu.toolTipText"
                  matTooltipClass="menu-tool-tip" matTooltipPosition="right">
                  <mat-icon [svgIcon]="menu.svgIcon"></mat-icon>
                </a>
              </li>
            </ng-container>
            <ng-container *ngIf="!menu.hrefLink">
              <li (mouseenter)="setSelectedMainMenu(menu)">
                <a [routerLink]="menu.routerLink" class="nav-link" routerLinkActive="active"
                  [matTooltip]="menu.toolTipText" matTooltipPosition="right" matTooltipClass="menu-tool-tip">
                  <mat-icon [svgIcon]="menu.svgIcon"></mat-icon>
                </a>
              </li>
            </ng-container>
          </ng-template>
        </ng-container>
        <ng-template #staticBottomMainMenuTemplate>
          <li *ngIf="hasEnableSqlGeneratorFeature && menu.toolTipText==='Mobile Price Book' && hasNavigationItem('Mobile Price book')"
            (mouseenter)="setSelectedMainMenu(menu)">
            <a (click)="generateBook()" [ngStyle]="{'color': syncColor }" matTooltipClass="menu-tool-tip"
              [matTooltip]="menu.toolTipText" matTooltipPosition="right">
              <mat-icon svgIcon="generateSQLIcon-new" [ngClass]="{
                  'spin-sync-nav': bookGeneratedStatus === 'InProgress'
                }"></mat-icon>
            </a>
          </li>
          <li *ngIf="hasEnableContentUpdatesFetaure && menu.toolTipText==='Content Management' && hasNavigationItem('Content Updates')" 
            (mouseenter)="setSelectedMainMenu(menu)" >
            <a class="content-update-icon nav-link" [routerLink]="menu.routerLink"
              routerLinkActive="content-update-icon-active active" [ngClass]="contentUpdateStatusClass"
              [matTooltip]="menu.toolTipText" matTooltipClass="menu-tool-tip" matTooltipPosition="right">
              <mat-icon [svgIcon]="contentUpdateStatusIcon"></mat-icon>
            </a>
          </li>
        </ng-template>
      </ng-container>

      <li *ngIf="fieldEdgeNavigationItem" class="ng-tns-c6-3 FieldEdgeli closed"
        [ngClass]="{ 'Sync':fieldEdgeNavigationItem.canSync === 1, 'SyncComplete': fieldEdgeNavigationItem.canSync !== 1 && fieldEdgeNavigationItem.canSync!==2,  'Syncinprogress': fieldEdgeNavigationItem.canSync===2 }"
        (click)="fieldEdgeSync()" (mouseenter)="setSelectedMainMenu(null)">
        <span class="caption FieldEdgeIcon ng-tns-c6-3 ">
        </span>
      </li>

      <li *ngIf="userState" (mouseenter)="setSelectedMainMenu(null)">
        <a href="https://learn.profitrhino.com/" target="_blank" matTooltip="Rhino University"
          matTooltipClass="menu-tool-tip" matTooltipPosition="right">
          <mat-icon svgIcon="graduate-hat-icon"></mat-icon>
        </a>
      </li>
      <li *ngIf="userState" (mouseenter)="setSelectedMainMenu(null)">
        <a (click)="logout()" [matTooltip]="logOutToolTip" matTooltipClass="menu-tool-tip" matTooltipPosition="right">
          <mat-icon svgIcon="logOutIcon"></mat-icon>
        </a>
      </li>
    </ul>
  </div>

  <div class="sub-menu-container" [@slideFadeInOut]
    *ngIf="selectedMenu && selectedMenu.subMenus && selectedMenu.subMenus.length">
    <pr-nav-sub-menu-bar [userState]="userState" [selectedTopMainMenu]="selectedMenu"></pr-nav-sub-menu-bar>
    <div class="bottom-sub-menu">
      <!-- <div class="item mobile-sync-wrapper">
        <div>
          <mat-icon style="font-size: 15px;" [ngStyle]="{'color': '#D8000C' }">fiber_manual_record</mat-icon>
        </div>
        <div>
          <div class="title">Mobile Sync</div>
          <div class="sub-title">Out-of-date</div>
        </div>
      </div> -->
      <div class="item sub-menu-image">
        <a style="text-decoration: none;" href="/Company/Company/DashBoard">
          <div class="company-logo">
            <div *ngIf="!userState.companyLogo else logo">
              Your Logo Here
            </div>
            <ng-template #logo>
              <img src="{{userState.companyLogo}}" />
            </ng-template>
          </div>
        </a>
      </div>
      <div class="item cnumber-wrapper">
        <div class="title">Profit Rhino</div>
        <div class="sub-title" *ngIf="userState">{{ userState.username }}</div>
      </div>
    </div>
  </div>

</div>