import { NgModule } from '@angular/core';
import { ClickStopPropagationDirective } from './click-stop-propogation/click-top-propagation.directive';
import { DisableWhileSyncingDirective } from './disable-while-syncing/disable-while-syncing';
import { LazyLoadDirective } from './lazy-load-directive/lazy-load-directive';
import { HideFromNonAdminDirective } from './non-admin-directive/non-admin-directive';
import { ValidatePermissionDirective } from './permission-directive/validate-permission.directive';

const EXPORTED_DIRECTIVE = [
  ValidatePermissionDirective,
  HideFromNonAdminDirective,
  LazyLoadDirective,
  ClickStopPropagationDirective,
  DisableWhileSyncingDirective
];

@NgModule({
  declarations: [
    EXPORTED_DIRECTIVE,
  ],

  imports: [
  ],

  exports: [
    EXPORTED_DIRECTIVE,
  ]
})
export class CustomDirectiveModule { }
