export enum StyleSize {
  None = 'none',
  Xs = 'xs',
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
  Xl = 'xl',
  Xxl = 'xxl',
  Auto = 'auto',
}
