import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';

import * as fromApp from '../../../../frb-app/src/app/store/app.reducers';
import * as AuthActions from '../../../../frb-app/src/app/modules/auth/store/auth.actions';
import * as fromAuthSelectors from '../../../../frb-app/src/app/modules/auth/store/auth.selectors';
import { catchError, first, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private store: Store<fromApp.AppState>,
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return this.store.select(fromAuthSelectors.selectUserState.user).pipe(
      first(),
      mergeMap(user => {

        const authReq = !!user.token ? req.clone({
          setHeaders: { 'X-HTTP-ProfitRhino-Service-Key': user.token },
        }) : req;

        return next.handle(authReq).pipe(
          map(event => {
            if (event instanceof HttpResponse && event.status === 204) {
              event = event.clone({ body: {
                status: false,
                message: event.statusText,
                responseData: {}
              }});
            }
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            this.store.dispatch(new AuthActions.AddGlobalError(error));
            return throwError(error);
          })
        );
      }),
    );
  }
}
