import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatTooltipModule
} from '@angular/material';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthWrapperComponent } from './pages/auth-wrapper.component';
import { ForgotPasswordSuccessComponent } from './components/forgot-password/forgot-password-success.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { CustomPipeModule } from 'shared/src/app/pipes/custom-pipe.module';
import { SocialMediaComponent } from './components/social-media/social-media.component';
import { TermsOfServiceContentComponent } from './components/terms-of-service/terms-of-service-content.component';
// import { TermsOfServiceModalComponent } from './components/terms-of-service/terms-of-services-modal-unused/terms-of-service-modal.component';
import { TermsOfServiceComponent } from './components/terms-of-service/terms-of-service.component';

const COMPONENTS = [
  ForgotPasswordComponent,
  ForgotPasswordSuccessComponent,
  LoginComponent,
  ResetPasswordComponent,
  AuthWrapperComponent,
  TermsOfServiceContentComponent,
  TermsOfServiceComponent,
  SocialMediaComponent
];

// const ENTRY_COMPONENTS = [
//   TermsOfServiceModalComponent,
// ];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    AuthRoutingModule,
    MatButtonModule,
    CustomPipeModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatTooltipModule,
    MatSnackBarModule
  ],
  declarations: [
    COMPONENTS // ,
    //  ENTRY_COMPONENTS
  ],
  providers: [
  ],
  //  entryComponents: ENTRY_COMPONENTS,
})
export class PrAuthModule { }
