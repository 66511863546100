import { Injectable } from '@angular/core';
import { UnauthenticatedApiClient } from './../services/api-client';
import { HttpParams } from '@angular/common/http';
import { IOptionBoardRequest, IOptionLabelRequest } from 'frb-app/src/app/modules/option-board/options.models';
import { map } from 'rxjs/operators';

@Injectable()
export class OptionApi {

  constructor(private readonly _apiClient: UnauthenticatedApiClient) {
  }

  getBusinessTypeList() {
    return this._apiClient.get<any>(`/api/v2/TaskCategories/0`);
  }

  getQuickAdds() {
    return this._apiClient.get<any>(`/api/v2/quickadds`);
  }

  getTechnicianList() {
    return this._apiClient.get<any>(`/api/v2/Users/Technicians`);
  }
  getOptionLabels(request: IOptionLabelRequest) {
    let params = new HttpParams().set('PageNumber', request.pageNumber.toString()).set('PageSize', request.pageSize.toString());
    if (request.searchText)
      params = params.set("Search", `${request.searchText}`);
    return this._apiClient.get<any>(`/api/v2/optionlabel`, { params });
  }
  getOptionLabelLabels(id: number) {
    return this._apiClient.get<any>(`/api/v2/optionlabel/${id}/labels`);
  }

  createOptionLabel(model: any) {
    return this._apiClient.post<any>(`/api/v2/optionlabel`, model);
  }
  updateOptionLabel(model: any) {
    return this._apiClient.put<any>(`/api/v2/optionlabel/${model.id}`, model);
  }
  deleteOptionLabel(id: number) {
    return this._apiClient.delete<any>(`/api/v2/optionlabel/${id}`);
  }

  getOptionBoards(request: IOptionBoardRequest) {
    let params = new HttpParams().set('PageNumber', request.pageNumber.toString()).set('PageSize', request.pageSize.toString()).set('BoardType', request.boardType);
    if (request.businessTypeId)
      params = params.set("BusinessTypeId", `${request.businessTypeId}`);
    if (request.technicianId)
      params = params.set("TechnicianId", `${request.technicianId}`);
    if (request.searchText)
      params = params.set("Search", `${request.searchText}`);
    return this._apiClient.get<any>(`/api/v2/optionboards`, { params });
  }

  createOptionBoard(model: any) {
    return this._apiClient.post<any>(`/api/v2/optionboards`, model);
  }
  promoteTechBoard(id: number) {
    return this._apiClient.post<any>(`/api/v2/optionboards/${id}/promote`, {});
  }
  copyOptionBoard(id: number, model: any) {
    return this._apiClient.post<any>(`/api/v2/optionboards/${id}/copy`, model);
  }
  getOptionBoardDetail(id: number) {
    return this._apiClient.get<any>(`/api/v2/optionboards/${id}/details`);
  }
  deleteOptionBoard(id: number) {
    return this._apiClient.delete<any>(`/api/v2/optionboards/${id}`);
  }
  updateOptionBoardDetail(id: number, model) {
    return this._apiClient.put<any>(`/api/v2/optionboards/${id}/details`, model);
  }

  getOptionBoardSortType() {
    return this._apiClient.get<any>(`/api/v2/settings/MobileSettings`).pipe(
      map(response => {
        if(response && response.data && response.data.mobileSettings 
          && response.data.mobileSettings.optionBoardSortTypes){
            var optionBoardSortType= response.data.mobileSettings.optionBoardSortTypes.find(x => x.isDefault);
            if(optionBoardSortType){
              return optionBoardSortType.sortType
            }
          }
          return "";
        
      })
    );
  }
}
