import { ErrorHandler } from '@angular/core';
import * as rg4js from 'raygun4js';

const VERSION_NUMBER = '1.0.0.0';
rg4js('apiKey', window.location.href.indexOf('secure') > -1 ? 'JPD8TZzaf8m2p2vGSfcHEw' : 'DeF8dBoi6AEsSn1ibgxtlA');
rg4js('setVersion', VERSION_NUMBER);
rg4js('enableCrashReporting', true);
export class RaygunErrorHandler implements ErrorHandler {
  handleError(e: any) {
    if (window.location.href.indexOf('dev') === -1) {
      console.log(e);
      rg4js('send', {
        error: e,
      });
    }
  }
}
