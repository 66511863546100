import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toDataSourceRequest, toDataSourceRequestString } from '@progress/kendo-data-query';
import { UnauthenticatedApiClient } from '../services/api-client';
import {
  IAddNewPart,
  IBulkActionPartsRequest,
  ICopyPartRequest,
  IManagePartVendorRequest,
  IPartsRequest,
  IPartTaskListRequest,
  IUpdateProductLineRequest,
  PatchRequest
} from 'frb-app/src/app/modules/parts/store/part.model';
import { HttpResponse } from '../models/http-response';
import { Observable } from 'rxjs';
import { SearchProductLineResponse } from '../models/search-product-line-response';
import { VendorResponse } from '../models/vendor-response';

@Injectable()
export class PartApi {

  constructor(private readonly _apiClient: UnauthenticatedApiClient) {
  }

  public getParts(partsRequest: IPartsRequest): Observable<HttpResponse<any>> {
    const request = `${toDataSourceRequestString(partsRequest.request)}`;
    const params = new HttpParams().set('searchText', partsRequest.searchText ? partsRequest.searchText : '' );
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._apiClient.post<any>(`/api/v2/parts`, request, { params: params, headers : headers});
  }

  public getPartTasks(partTaskListRequest: IPartTaskListRequest): Observable<HttpResponse<any>> {
    const state = toDataSourceRequest(partTaskListRequest.state);
    const params = new HttpParams().set('PageNo', state.page ).set('PageSize', state.pageSize);
    return this._apiClient.get<any>(`/api/v2/part/${partTaskListRequest.partId}/tasks`, {params: params});
  }

  public removePartTaskList(id: string): Observable<HttpResponse<any>> {
    return this._apiClient.delete<any>(`/api/v2/RemoveTaskPartAssignment/${id}`);
  }

  public copyPart(CopyPartRequest: ICopyPartRequest): Observable<HttpResponse<any>> {
    const req = {
      PartNumber: CopyPartRequest.copyTo
    };
    return this._apiClient.post<any>(`/api/v2/Part/${CopyPartRequest.copyFrom}/CopyPart`, req);
  }

  public savePart(patch: PatchRequest[], partId: number): Observable<HttpResponse<any>> {
    return this._apiClient.patch<any>(`/api/v2/part/${partId}`, patch);
  }

  public addNewPart(AddNewPart: IAddNewPart): Observable<HttpResponse<any>> {
    return this._apiClient.post<any>(`/api/v2/part/`, AddNewPart);
  }

  public getPartsCategory(): Observable<HttpResponse<any>> {
    const params = new HttpParams().set('pageSize', '-1');
    return this._apiClient.get<any>(`/api/v2/PartCategories`, { params: params});
  }

  public getAllPartVendor(): Observable<HttpResponse<any>> {
    return this._apiClient.get<any>(`/api/v2/Vendors`);
  }

  public getProductLine(): Observable<HttpResponse<any>> {
    const params = new HttpParams().set('pageSize', '-1');
    return this._apiClient.get<any>(`/api/v2/ProductLines`, { params: params});
  }

  public getProductLineById(productLineId: number): Observable<HttpResponse<any>> {
    return this._apiClient.get<any>(`/api/v2/ProductLine/${productLineId}`);
  }

  public SearchProductLineForCompany(productLine: string): Observable<HttpResponse<SearchProductLineResponse>> {
    return this._apiClient.post<SearchProductLineResponse>(`/SearchProductLineForCompany`, productLine);
  }

  public UpdateProductLineForCompany(UpdateProductLineRequest: IUpdateProductLineRequest): Observable<HttpResponse<any>> {
    const producLineIds: number[]  = [];
    UpdateProductLineRequest.productLines.map(item => {
      producLineIds.push(item.id);
    });
    const req = {
      producLineIds: producLineIds,
      percentage: UpdateProductLineRequest.costPercent as number
    };
    return this._apiClient.post<any>(`/api/v2/ProductLines/UpdatePartCostByProductlines`, req);
  }

  public getAllVendors(): Observable<HttpResponse<any>> {
    const params = new HttpParams().set('pageSize', '-1');
    return this._apiClient.get<any>(`/api/v2/Vendors`, { params: params});
  }

  public addVendor(patch: PatchRequest[], partId: number): Observable<HttpResponse<any>> {
    return this._apiClient.patch<any>(`/api/v2/part/${partId}`, patch);
  }

  public managePartVendors(PartRequest: IManagePartVendorRequest): Observable<HttpResponse<any>> {
    const params = new HttpParams().set('pageSize', '-1');
    return this._apiClient.get<any>(`/api/v2/part/${PartRequest.partId}/vendors`, { params: params});
  }

  public savePartVendor(patch: PatchRequest[], partId: number): Observable<HttpResponse<VendorResponse>> {
    return this._apiClient.patch<any>(`/api/v2/part/${partId}`, patch);
  }

  public removePartTaskAssignment(id: number): Observable<HttpResponse<any>> {
    return this._apiClient.post<any>(`/remove_part_task_assignment`, id);
  }

  public removePartVendor(patch: PatchRequest[], partId: number): Observable<HttpResponse<any>> {
    return this._apiClient.patch<any>(`/api/v2/part/${partId}`, patch);
  }

  public savePartAccount(patch: PatchRequest[], partId: number): Observable<HttpResponse<any>> {
    return this._apiClient.patch<any>(`/api/v2/part/${partId}`, patch);
  }

  public partBulkAction(bulkActionPartsRequest: IBulkActionPartsRequest): Observable<HttpResponse<any>> {
    return this._apiClient.post<any>(`/api/v2/parts/updateallparts`, bulkActionPartsRequest);
  }

  public getPartById(PartId: number): Observable<HttpResponse<any>> {
    return this._apiClient.get<any>(`/api/v2/part/` + PartId);
  }

  public getManufacturers() {
    const params = new HttpParams().set('PageNo', '1' ).set('PageSize', '-1');
    return this._apiClient.get<any>(`/api/v2/manufacturer`, {params});
  }

  public getVendorByPart(id: number){
    return this._apiClient.get<any>(`/api/v2/part/${id}/vendors`);
  }
}
