var NavigationLink = /** @class */ (function () {
    function NavigationLink(_config) {
        this._config = _config;
        if (!_config) {
            throw new Error('No configuration was passed in.');
        }
        if (!_config.label) {
            throw new Error('"label" must be populated.');
        }
    }
    Object.defineProperty(NavigationLink.prototype, "label", {
        get: function () {
            return this._config.label;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationLink.prototype, "href", {
        get: function () {
            return this._config.href;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationLink.prototype, "icon", {
        get: function () {
            return this._config.icon;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationLink.prototype, "badge", {
        get: function () {
            return this._config.badge;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationLink.prototype, "routerLink", {
        get: function () {
            return this._config.routerLink;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationLink.prototype, "canSync", {
        get: function () {
            return this._config.canSync;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationLink.prototype, "type", {
        get: function () {
            return this._config.type;
        },
        enumerable: true,
        configurable: true
    });
    return NavigationLink;
}());
export { NavigationLink };
