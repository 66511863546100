/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./forgot-password-success.component";
import * as i4 from "@ngrx/store";
var styles_ForgotPasswordSuccessComponent = [];
var RenderType_ForgotPasswordSuccessComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ForgotPasswordSuccessComponent, data: {} });
export { RenderType_ForgotPasswordSuccessComponent as RenderType_ForgotPasswordSuccessComponent };
export function View_ForgotPasswordSuccessComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "forgot-password-success-div mat-typography"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h1", [["class", "mat-display-1 header"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Check your mail"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h2", [["class", "content"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["We sent an email with instructions to reset your password"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "img-div"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "img", [["alt", "Message Box"], ["height", "118"], ["src", "./assets/img/pw-reset.png"], ["width", "118"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "a", [["class", "login"], ["title", "Login"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Log in"])), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [["class", "tos-div"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "a", [["title", "Terms of Service - Privacy Policy"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.termsOfService() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Terms of Service - Privacy Policy "]))], function (_ck, _v) { var currVal_2 = "/login"; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 8).target; var currVal_1 = i0.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_0, currVal_1); }); }
export function View_ForgotPasswordSuccessComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pr-forgot-password-success", [], null, null, null, View_ForgotPasswordSuccessComponent_0, RenderType_ForgotPasswordSuccessComponent)), i0.ɵdid(1, 49152, null, 0, i3.ForgotPasswordSuccessComponent, [i4.Store], null, null)], null, null); }
var ForgotPasswordSuccessComponentNgFactory = i0.ɵccf("pr-forgot-password-success", i3.ForgotPasswordSuccessComponent, View_ForgotPasswordSuccessComponent_Host_0, {}, {}, []);
export { ForgotPasswordSuccessComponentNgFactory as ForgotPasswordSuccessComponentNgFactory };
