import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as AuthActions from '../../modules/auth/store/auth.actions';
import * as fromAuthSelectors from '../../modules/auth/store/auth.selectors';
import { interval, Subject } from 'rxjs';
import { map, takeUntil, take } from 'rxjs/operators';
import { LoaderService } from 'shared/src/app/interceptors/loader.service';
import { UserService } from 'shared/src/app/services/user.service';
import { LocalStorage } from 'core/src/app/common/services/local-storage.service';
import { BOTTOM_MENU_DATA, TOP_MENU_DATA } from './nav-bar.model';
import { CustomSnackBarComponent } from 'shared/src/app/components/custom-snackbar/custom-snackbar.component';
import { MatSnackBar } from '@angular/material';
var NavBarComponent = /** @class */ (function () {
    function NavBarComponent(store, _localStorage, loaderService, _route, _userService, _router, snackbar) {
        var _this = this;
        this.store = store;
        this._localStorage = _localStorage;
        this.loaderService = loaderService;
        this._route = _route;
        this._userService = _userService;
        this._router = _router;
        this.snackbar = snackbar;
        this.hasSettings = false;
        this.hasMyAccount = false;
        this.topMainMenu = TOP_MENU_DATA;
        this.bottomMainMenu = BOTTOM_MENU_DATA;
        this.shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(function (h) { return h.test(window.location.host); });
        this.hasEnableSqlGeneratorFeature = false;
        this.hasEnableContentUpdatesFetaure = false;
        this.hasFieldEdgeFetaure = false;
        this.menus = [{
                toolTipText: "Business "
            }];
        this.unsubscribe$ = new Subject();
        this.fieldEdgeSyncStatus = false;
        this.navigationItems = [];
        this.visibilityFunction = function () {
            if (document.hidden) {
                _this.poolingEnabled = false;
            }
            else {
                _this.poolingEnabled = true;
            }
        };
        this.poolingEnabled = true;
    }
    Object.defineProperty(NavBarComponent.prototype, "fieldEdgeNavigationItem", {
        get: function () {
            return this.navigationItems.find(function (x) { return x.displayName === "FieldEdge"; });
        },
        enumerable: true,
        configurable: true
    });
    NavBarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                _this.setSelectedMainMenu(null);
            }
        });
        this.store.dispatch(new AuthActions.GetNavigationItems());
        this.store.pipe(select(fromAuthSelectors.selectUserState.navigationItems), takeUntil(this.unsubscribe$)).subscribe(function (data) {
            _this.navigationItems = data;
        });
        var paramIsAjaxRequest = this._route.snapshot.queryParamMap.get('isAjaxRequest');
        if (paramIsAjaxRequest) {
            this.isAjaxRequest = JSON.parse(paramIsAjaxRequest);
        }
        this.store.pipe(select(fromAuthSelectors.selectUserState.selectBookGenerateState), map(function (state) { return state.syncMessage; }), takeUntil(this.unsubscribe$)).subscribe(function (status) {
            _this.bookGeneratedStatus = status;
        });
        this.store.pipe(select(fromAuthSelectors.selectUserState.selectContentUpdateState), takeUntil(this.unsubscribe$), map(function (state) { return state.status; })).subscribe(function (status) {
            _this.contentUpdateStatus = status;
        });
        this.store.pipe(select(fromAuthSelectors.selectUserState.selectFieldSyncResponse), takeUntil(this.unsubscribe$)).subscribe(function (res) {
            if (res.syncStatus) {
                _this.store.dispatch(new AuthActions.GetNavigationItems());
            }
        });
        this.store
            .pipe(select(fromAuthSelectors.selectUserState.user), take(1))
            .subscribe(function (val) {
            if (val.FeatureFlags['EnableSqlGenerator']) {
                _this.hasEnableSqlGeneratorFeature = true;
                _this.pollPeriodically();
            }
        });
        this.store
            .pipe(select(fromAuthSelectors.selectUserState.user), take(1))
            .subscribe(function (val) {
            if (val.FeatureFlags['EnableContentUpdates']) {
                _this.hasEnableContentUpdatesFetaure = true;
                _this.pollContentUpdatePeriodically();
            }
        });
        this.store
            .pipe(select(fromAuthSelectors.selectUserState.user), take(1))
            .subscribe(function (val) {
            if (val.FeatureFlags['FieldEdge']) {
                _this._userService.fieldEdgeSyncPolling().subscribe(function (response) {
                    _this.fieldEdgeSyncStatus = !response.status;
                    _this._localStorage.set('IsSyncInProcess', _this.fieldEdgeSyncStatus ? false : true);
                    _this.store.dispatch(new AuthActions.SetFieldEdgeSyncPollingStatus({ syncMessage: response.message, syncStatus: _this.fieldEdgeSyncStatus }));
                    _this.fieldEdgePolling();
                    _this.hasFieldEdgeFetaure = true;
                });
            }
        });
        this.store.pipe(select(fromAuthSelectors.selectUserState.user), takeUntil(this.unsubscribe$)).subscribe(function (user) {
            if (user && user.token && user.token !== '') {
                // this.pollDotNetSession(user);
            }
        });
        this.store
            .select(fromAuthSelectors.selectUserState.user)
            .pipe(takeUntil(this.unsubscribe$)).subscribe(function (user) {
            _this.userState = user;
            _this.isSmartPriceUser = user.SubscriptionName.toLowerCase().includes('smart price');
        });
        document.addEventListener("visibilitychange", this.visibilityFunction);
    };
    NavBarComponent.prototype.hasNavigationItem = function (displayName) {
        return this.navigationItems.some(function (x) { return x.displayName.toLowerCase() === displayName.toLowerCase(); });
    };
    NavBarComponent.prototype.setSelectedMainMenu = function (menu) {
        this.selectedMenu = menu;
    };
    NavBarComponent.prototype.fieldEdgeSync = function () {
        this._localStorage.set('IsSyncInProcess', true);
        this.store.dispatch(new AuthActions.FieldEdgeSync());
    };
    NavBarComponent.prototype.fieldEdgePolling = function () {
        var _this = this;
        if (this.pollingSub) {
            this.pollingSub.unsubscribe();
        }
        this.pollingSub = interval(120000).pipe(takeUntil(this.unsubscribe$)).subscribe(function () {
            if (_this.poolingEnabled)
                _this.store.dispatch(new AuthActions.FieldEdgeSyncPolling());
        });
        this.store.pipe(select(fromAuthSelectors.selectUserState.selectFieldSyncPollingResponse), takeUntil(this.unsubscribe$)).subscribe(function (val) {
            if (_this.fieldEdgeSyncStatus !== undefined && _this.fieldEdgeSyncStatus !== val.syncStatus && val.syncStatus) {
                _this._localStorage.set('IsSyncInProcess', false);
                _this.store.dispatch(new AuthActions.GetNavigationItems());
                _this.snackbar.openFromComponent(CustomSnackBarComponent, { horizontalPosition: 'center', verticalPosition: 'top', duration: 3000, data: { message: val.syncMessage, status: true } });
            }
            switch (val.syncStatus) {
                case true:
                    _this.fieldEdgeSyncStatus = true;
                    break;
                case false:
                    _this.fieldEdgeSyncStatus = false;
                    break;
                default:
                    _this.fieldEdgeSyncStatus = _this.fieldEdgeSyncStatus;
                    break;
            }
        });
    };
    NavBarComponent.prototype.pollDotNetSession = function (user) {
        var _this = this;
        var source = interval(5000 * 60).pipe(takeUntil(this.unsubscribe$));
        source.subscribe(function () {
            _this._userService.isTokenExpired(user.token).subscribe();
        });
    };
    NavBarComponent.prototype.generateBook = function () {
        this.store.dispatch(new AuthActions.GenerateBook());
        this.store.dispatch(new AuthActions.SetBookStatus({
            syncStatus: false,
            syncMessage: 'InProgress'
        }));
    };
    NavBarComponent.prototype.ngOnDestroy = function () {
        if (this.unsubscribe$) {
            this.unsubscribe$.next();
            this.unsubscribe$.complete();
        }
        document.removeEventListener("visibilitychange", this.visibilityFunction);
    };
    Object.defineProperty(NavBarComponent.prototype, "isExpandedState", {
        get: function () {
            return localStorage.getItem('bodyclass') === 'opened-menu' ? 'open' : 'closed';
        },
        enumerable: true,
        configurable: true
    });
    NavBarComponent.prototype.pollPeriodically = function () {
        var _this = this;
        this.store.dispatch(new AuthActions.BeginBookPolling());
        var source = interval(120000).pipe(takeUntil(this.unsubscribe$));
        source.subscribe(function (_) {
            if (_this.poolingEnabled)
                _this.store.dispatch(new AuthActions.BeginBookPolling());
        });
    };
    NavBarComponent.prototype.pollContentUpdatePeriodically = function () {
        var _this = this;
        this.store.dispatch(new AuthActions.BeginContentUpdatePolling());
        var source = interval(120000).pipe(takeUntil(this.unsubscribe$));
        source.subscribe(function (_) {
            if (_this.poolingEnabled)
                _this.store.dispatch(new AuthActions.BeginContentUpdatePolling());
        });
    };
    Object.defineProperty(NavBarComponent.prototype, "contentUpdateStatusClass", {
        get: function () {
            return {
                "update-completed": this.contentUpdateStatus === 2 || this.contentUpdateStatus === 3,
                "update-available": this.contentUpdateStatus === 0,
                "update-pending": this.contentUpdateStatus === 1
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavBarComponent.prototype, "contentUpdateStatusIcon", {
        get: function () {
            switch (this.contentUpdateStatus) {
                case 2:
                case 3:
                    return "contentUpdateDone";
                default:
                    return "contentUpdate";
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavBarComponent.prototype, "syncColor", {
        get: function () {
            switch (this.bookGeneratedStatus) {
                case 'Completed':
                    return '#5587C3';
                case 'InProgress':
                    return '#f5a623';
                case 'NotStarted':
                    return '#5587C3';
                case 'FailedException':
                    return '#D8000C';
                default:
                    break;
            }
        },
        enumerable: true,
        configurable: true
    });
    NavBarComponent.prototype.logout = function () {
        if (this.userState) {
            if (this.userState.AdminReturnUserId) {
                window.location.href = '/Account/Account/LoginAsAdmin?AdminUserId=1';
            }
            else {
                this.store.dispatch(new AuthActions.Logout());
            }
        }
    };
    Object.defineProperty(NavBarComponent.prototype, "logOutToolTip", {
        get: function () {
            return this.userState.AdminReturnUserId ? "Back To Admin" : "Logout";
        },
        enumerable: true,
        configurable: true
    });
    return NavBarComponent;
}());
export { NavBarComponent };
