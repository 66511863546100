import { Routes } from '@angular/router';
import { AuthWrapperComponent } from './pages/auth-wrapper.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { LoginResolver } from './resolver/loginResolver';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ResetTokenGuard } from '../../gaurds/reset-token-gaurd.service';
var ɵ0 = LoginResolver;
var routes = [
    {
        path: '',
        component: AuthWrapperComponent,
        children: [
            {
                path: 'login',
                component: LoginComponent,
                resolve: { data: ɵ0 }
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent,
            },
            {
                path: 'reset-password/:resetPasswordToken',
                component: ResetPasswordComponent,
                canActivate: [ResetTokenGuard]
            }
        ]
    }
];
var AuthRoutingModule = /** @class */ (function () {
    function AuthRoutingModule() {
    }
    return AuthRoutingModule;
}());
export { AuthRoutingModule };
export { ɵ0 };
