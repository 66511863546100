import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromApp from '../../../../store/app.reducers';
import * as AuthActions from '../../store/auth.actions';

@Component({
  selector: 'pr-forgot-password-success',
  templateUrl: './forgot-password-success.component.html'
})
export class ForgotPasswordSuccessComponent {
  loginFailureReason: string;
  showTermsOfService: boolean;

  constructor(
    private store: Store<fromApp.AppState>
  ) { }

  termsOfService() {
    this.store.dispatch(new AuthActions.TermsOfService(false));
  }
}
