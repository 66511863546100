import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pr-customer-wrapper-footer',
  templateUrl: './customer-wrapper-footer.component.html',
  styleUrls: ['./customer-wrapper-footer.component.scss']
})
export class CustomerWrapperFooterComponent implements OnInit {
  currentYear: number;
  lastYear: number;
  buildVersion = '0.0.1';

  constructor() {
    this.currentYear = new Date().getFullYear();
    this.lastYear = new Date().setFullYear(this.currentYear - 1);
  }

  ngOnInit() {
  }

}
