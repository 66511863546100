import { NavigationBadge } from 'core/src/app/ui/common/components/navigation/navigation-badge';
import { NavigationItem } from 'core/src/app/ui/common/components/navigation/navigation-item';
export class NavigationLink implements NavigationItem {
  constructor(private readonly _config: NavigationLinkConfiguration) {
    if (!_config) {
      throw new Error('No configuration was passed in.');
    }

    if (!_config.label) {
      throw new Error('"label" must be populated.');
    }

  }

  get label(): string {
    return this._config.label;
  }

  get href(): string | undefined {
    return this._config.href;
  }

  get icon(): string | undefined {
    return this._config.icon;
  }

  get badge(): NavigationBadge | undefined {
    return this._config.badge;
  }

  get routerLink(): string | undefined {
    return this._config.routerLink;
  }

  get canSync(): number | undefined {
    return this._config.canSync;
  }

  get type(): string | undefined {
    return this._config.type;
  }
}

export interface NavigationLinkConfiguration {
  label: string;
  href?: string;
  icon?: string;
  badge?: NavigationBadge;
  routerLink?: string;
  canSync: number;
  type: string;
}
