import { Component, Inject, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatCheckboxChange } from '@angular/material';
import { ConfirmationDialogInput, ComplexDialogConfiguration } from './confirmation-dialog-input';
import { merge } from 'lodash-es';

const DEFAULTS: ConfirmationDialogInput = {
  message: 'Are you sure you want to do this?',
  actions: [
    {
      label: 'Yes',
      value: true,
      color: 'primary'
    },
    {
      label: 'No',
      value: false,
      color: 'warn'
    }
  ],
  complex: false
};
@Component({
  selector: 'pr-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent {

  closingData: {} = {};
  private el: HTMLInputElement;
  @ViewChild('inputField', { static: false }) inputField: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogInput
  ) {    
    this.data = merge({}, DEFAULTS, this.data || {});
    if(this.data.complexConfigurations)
    this.data.complexConfigurations.forEach(a => {
      this.closingData[a.id] = a.checked;
    })    
  }

  updateCloseData(checkboxEvent: MatCheckboxChange, action: ComplexDialogConfiguration){
    this.closingData[action.id] = checkboxEvent.checked;
  }

  updateCloseTextData(action: ComplexDialogConfiguration){
    this.el = this.inputField.nativeElement;
    this.closingData[action.id] = this.el.value;    
  }
}
