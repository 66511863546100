import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UnauthenticatedApiClient } from './../services/api-client';
import { IBulkActionCategoryRequest, ICategory, ICreateTask } from 'frb-app/src/app/modules/books/store/book-models';
import { PatchRequest } from 'frb-app/src/app/modules/parts/store/part.model';
import { ICopyTaskRequest } from 'frb-app/src/app/modules/tasks/store/task.model';
import { HttpResponse } from '../models/http-response';
import { Observable } from 'rxjs';
@Injectable()
export class CategoryAPI {

  constructor(private readonly _apiClient: UnauthenticatedApiClient) {
  }

  getBusinessTypeList() {
    return this._apiClient.get<any>(`/api/v2/TaskCategories/0`);
  }

  getCategoryChildren(id: number) {
    const params = new HttpParams().set('ParentId', id.toString()).set('PageNo', '1').set('PageSize', '-1');
    return this._apiClient.get<any>(`/api/v2/TaskCategories/`, { params });
  }

  searchCategories(search: string, parentId: number | undefined = undefined) {
    let params = new HttpParams().set('SearchKeyword', search).set('PageNo', '1').set('PageSize', '-1');
    if(parentId){
      params=params.set('ParentId',parentId.toString());
    }
    return this._apiClient.get<any>(`/api/v2/TaskCategories/`, { params });
  }

  getTasksOfCategory(taskObj: { id: number, page?: number, isAssignmentActive?: string, oem?: string, isTaskActive?: string }) {
    let params: any;
    if (taskObj.isAssignmentActive) {
      switch (taskObj.isAssignmentActive) {
        case 'Inactive':
          taskObj.isAssignmentActive = 'Inactive';
          break;
        case 'Both':
          taskObj.isAssignmentActive = 'All';
          break;
        default:
          taskObj.isAssignmentActive;
      }
    }
    params = new HttpParams().set('PageSize', '25');
    if (taskObj.page) {
      params = params.append('PageNo', taskObj.page.toString())
    }
    if (taskObj.isAssignmentActive) {
      params = params.append('IsAssignmentActive', taskObj.isAssignmentActive);
    }
    if (taskObj.oem) {
      params = params.append('OemFilter', taskObj.oem);
    }
    if (taskObj.isTaskActive) {
      params = params.append('IsTaskActive', taskObj.isTaskActive);
    }
    return this._apiClient.get<any>(`/api/v2/TaskCategories/${taskObj.id}/tasks`, { params });
  }

  getCategoryById(id: number) {
    return this._apiClient.get<any>(`/api/v2/TaskCategories/${id}`);
  }

  createCategory(category: ICategory, imageName: string) {
    return this._apiClient.post<any>(`/api/v2/Category`, {
      ...category,
      imageData: category.imageUrl,
      imageName
    });
  }

  updateCategory(category: ICategory, imageName: string) {
    const destruct = {
      id: category.id,
      name: category.name,
      description: category.description,
      isActive: category.isActive,
      account: category.account,
      imageData: category.imageUrl,
      imageName: imageName,
      position: category.position,
      isOptIn: category.isOptIn
    };
    return this._apiClient.put<any>(`/api/v2/Category`, destruct);
  }

  deleteCategory(id: number) {
    return this._apiClient.delete<any>(`/api/v2/Category?Id=${id}`);
  }

  addTaskCategories(categorieIds: { id: number }[], taskId: number) {
    return this._apiClient.post<any>(`/api/v2/task/${taskId}/AssignCategory`, categorieIds);
  }

  public deleteTaskCategory(assignmentId: number, taskId: number) {
    return this._apiClient.delete(`/api/v2/Task/${taskId}/Category/${assignmentId}`);
  }

  public copyTask(CopyTaskRequest: ICopyTaskRequest) {
    const req = {
      taskNumber: CopyTaskRequest.copyTo,
      copyMarketingData: CopyTaskRequest.copyMarketingData,
      buisnessTypeId: CopyTaskRequest.buisnessTypeId
    };
    return this._apiClient.post<any>(`/api/v2/task/${CopyTaskRequest.copyFrom}/CopyTask`, req);
  }

  categoryBulkActions(request: IBulkActionCategoryRequest) {
    return this._apiClient.post('/api/v2/bulkaction/Category', { ids: request.ids, actionType: request.type });
  }

  partCategoryBulkActions(request: IBulkActionCategoryRequest) {
    return this._apiClient.post('/api/v2/bulkaction/PartCategory', { ids: request.ids, actionType: request.type });
  }

  productlineBulkActions(request: IBulkActionCategoryRequest) {
    return this._apiClient.post('/api/v2/bulkaction/Productlines', { ids: request.ids, actionType: request.type });
  }

  public updateCategoryOrder(sourceId: number, destinationId: number, index: number): Observable<HttpResponse<any>> {
    const body = {
      sourceId: sourceId,
      destinationId: destinationId,
      index: index
    };
    return this._apiClient.post<any>(`/api/v2/categorytree/actions/movecategory`, body);
  }


  updatePartCategoryOrder(categories: { categoryID: number, order: number }[]) {
    const request = {
      sortOrders: categories
    };
    return this._apiClient.post('/api/v2/PartCategory/ReOrderPosition', request);
  }

  updateProductlineOrder(categories: { categoryID: number, order: number }[]) {
    const request = {
      sortOrders: categories
    };
    return this._apiClient.post('/api/v2/Productline/ReOrderPosition', request);
  }

  getPartCategories() {
    const params = new HttpParams().set('PageNo', '1').set('PageSize', '-1');
    return this._apiClient.get<any>('/api/v2/PartCategories', { params });
  }

  getProductLineByPartCategory(categoryID: number) {
    const params = new HttpParams().set('PageNo', '1').set('PageSize', '-1');
    return this._apiClient.get<any>(`/api/v2/PartCategory/${categoryID}/ProductLines`, { params });
  }

  setCategoryTaskStatus(categoryID: number, taskID: number, status: boolean) {
    const body = {
      actionType: status ? 'Activate' : 'Inactivate'
    };
    return this._apiClient.post<any>(`/api/v2/Category/${categoryID}/ActiveInactiveTaskAssignment/${taskID}`, body);
  }

  public getVendors() {

    const params = new HttpParams().set('PageNo', '1').set('PageSize', '-1');


    return this._apiClient.get<any>(`/api/v2/Vendors`, { params: params });
  }

  createPartCategory(category: ICategory, imageName: string) {
    return this._apiClient.post<any>(`/api/v2/PartCategory`, {
      name: category.name,
      description: category.description,
      account: category.account,
      imageUrl: category.imageUrl,
      imageName: imageName,
      isActive: category.isActive,
      categoryId: category.categoryId
    });
  }

  updatePartCategory(category: ICategory, imageName: string) {
    const destruct = {
      id: category.id,
      name: category.name,
      description: category.description,
      isActive: category.isActive,
      account: category.account,
      imageData: category.imageUrl,
      imageName: imageName,
      categoryId: category.categoryId
    };
    return this._apiClient.put<any>(`/api/v2/PartCategory`, destruct);
  }

  deletePartCategory(id: number) {
    return this._apiClient.delete<any>(`/api/v2/PartCategory?Id=${id}`);
  }

  createProductLine(category: ICategory, imageName: string) {
    const destruct = {
      ...category,
      imageName
    };
    return this._apiClient.post<any>(`/api/v2/Productline`, destruct);
  }

  updateProductLine(category: ICategory, imageName: string) {
    const destruct = {
      id: category.id,
      name: category.name,
      description: category.description,
      isActive: category.isActive,
      account: category.account,
      imageData: category.imageUrl,
      imageName: imageName,
      categoryId: category.categoryId
    };
    return this._apiClient.put<any>(`/api/v2/Productline`, destruct);
  }

  deleteProductline(id: number) {
    return this._apiClient.delete<any>(`/api/v2/Productline?Id=${id}`);
  }

  getGlobalLibrary(searchKeyword: string) {
    const params = new HttpParams().set('SearchKeyword', searchKeyword ? searchKeyword : '');
    return this._apiClient.get<any>(`/api/v2/Images/GlobalLibrary`, { params: params });
  }

  getYourLibrary(searchKeyword: string) {
    const params = new HttpParams().set('SearchKeyword', searchKeyword ? searchKeyword : '').set('type', 'Category').set('BusinessType', '0');
    return this._apiClient.get<any>(`/api/v2/Images/YourLibrary`, { params: params });
  }

  getGoogleLibrary(searchKeyword: string, page: number) {
    const params = new HttpParams().set('SearchKeyword', searchKeyword ? searchKeyword : '').set('imageType', 'large').set('PageNo', page.toString());
    return this._apiClient.get<any>(`/api/v2/Images/Google`, { params: params });
  }

  public convertGoogleImageInBase64(imageUrl: string): Observable<HttpResponse<string>> {
    const params = new HttpParams().set('ImageUrl', imageUrl);
    return this._apiClient.get(`/api/v2/Images/UploadImage`, { params: params });
  }

  public reorderTasksInCategory(assignmentId: number, destinationCategoryId: number, index: number): Observable<HttpResponse<any>> {
    const body = {
      assignmentId: assignmentId,
      destinationCategoryId: destinationCategoryId,
      index: index
    };
    return this._apiClient.post<any>(`/api/v2/categorytree/actions/movetaskassignment`, body);
  }


  requestPartsOfProductline(productLineId: number | string): Observable<HttpResponse<any>> {
    return this._apiClient.get(`/api/v2/productline/${productLineId}/parts`);
  }

  createPart(part) {
    return this._apiClient.post('/api/v2/part', part);
  }

  moveCategories(categoryIDs: number[], targetId: number) {
    const body = {
      sourceIds: categoryIDs,
      targetId
    };
    return this._apiClient.post(`/api/v2/Category/Move`, body);
  }

  createTask(taskDetails: ICreateTask): Observable<HttpResponse<any>> {
    const body = {
      taskNumber: taskDetails.taskNumber,
      businessTypeId: taskDetails.businessTypeId,
      name: taskDetails.name,
      description: taskDetails.description,
      marketingDescription: taskDetails.marketingDescription,
      taskDuration: taskDetails.taskDuration,
      taskAsstTime: taskDetails.taskAsstTime,
      addonEnabled: taskDetails.addonEnabled,
      addonDuration: taskDetails.addonDuration,
      addonAsstTime: taskDetails.addonAsstTime
    };

    return this._apiClient.post('/api/v2/tasks', body);
  }

  addPartToTask(id: number, taskDetails: ICreateTask): Observable<HttpResponse<any>> {
    const body = taskDetails.parts.map((p, i) => {
      return {
        id: p.id,
        qty: p.qty,
        order: i,
        partType: 'Primary'
      };
    });
    return this._apiClient.post(`/api/v2/task/${id}/Part`, body);
  }

  assignTaskToCategory(id: number, categoryID: number): Observable<HttpResponse<any>> {
    const body = [
      {
        id: categoryID
      }
    ];
    return this._apiClient.post(`/api/v2/task/${id}/AssignCategory`, body);
  }

  addImagesToTask(id: number, taskDetails: ICreateTask): Observable<HttpResponse<any>> {
    const body = taskDetails.images.map((im, i) => {
      return {
        name: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
        order: i,
        imageData: im.url.includes(';base64,') ? im.url.split(';base64,')[1] : im.url
      };
    });
    return this._apiClient.post(`/api/v2/task/${id}/UploadImage`, body);
  }

  deleteTaskFromCategory(categoryId: number, taskId: number) {
    return this._apiClient.delete(`/api/v2/task/${taskId}/Category/${categoryId}`);
  }

  public savePart(patch: PatchRequest[], partId: number): Observable<HttpResponse<any>> {
    return this._apiClient.patch<any>(`/api/v2/part/${partId}`, patch);
  }

  public getManufacturers() {
    const params = new HttpParams().set('PageNo', '1').set('PageSize', '-1');
    return this._apiClient.get<any>(`/api/v2/manufacturer`, { params });
  }

  public getPriceBookTree() {
    return this._apiClient.get<any>(`/api/v2/PriceBookTree`);
  }
}
