import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../../store/app.reducers';
import * as AuthActions from '../../store/auth.actions';
import * as fromAuthSelectors from '../../store/auth.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'pr-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordToken: string;
  resetPasswordFormGroup: FormGroup;

  isBusy$: Observable<boolean>;
  showTermsOfService$: Observable<boolean>;
  resetPasswordFailureReason$: Observable<string>;
  show = false;
  showConfirmPws = false;
  pattern = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/;

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private store: Store<fromApp.AppState>
  ) {  }

  ngOnInit() {
    this.resetPasswordFormGroup = this.fb.group({
      newPassword: new FormControl('', [Validators.required, Validators.pattern(this.pattern), Validators.maxLength(255)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.pattern(this.pattern), Validators.maxLength(255)])
    });

    const resetPasswordToken = this._activatedRoute.snapshot.paramMap.get('resetPasswordToken');
    this.resetPasswordToken = resetPasswordToken ? resetPasswordToken : '';

    this.isBusy$ = this.store.select(
      fromAuthSelectors.selectResetPasswordState.isLoading
    );
    this.resetPasswordFailureReason$ = this.store.select(
      fromAuthSelectors.selectResetPasswordState.resetPasswordFailureReason
    );
    this.showTermsOfService$ = this.store.select(
      fromAuthSelectors.selectTermsOfServiceState.showTermsOfService
    );
  }

  doResetPassword() {
    if (this.resetPasswordFormGroup.controls['newPassword'].value !== this.resetPasswordFormGroup.controls['confirmPassword'].value) {
      this.store.dispatch(
        new AuthActions.ResetPasswordFailure('New and confirm password does not match')
      );
      return;
    }

    if (!this.resetPasswordFormGroup.valid) {
      return;
    }

    const data = {
      password: this.getLoginModel().newPassword,
      resetPasswordToken: this.resetPasswordToken
    };

    this.store.dispatch(new AuthActions.ResetPassword(data));
  }

  termsOfService() {
    this.store.dispatch(new AuthActions.TermsOfService(false));
  }

  getLoginModel() {
    return {
      newPassword: this.resetPasswordFormGroup.controls['newPassword'].value,
      confirmPassword: this.resetPasswordFormGroup.controls['confirmPassword'].value
    };
  }
}

export interface ResetPasswordModel {
  newPassword: string;
  confirmPassword: string;
}

