export const SocialLinks: SocialMediaItem[] = [
  {
    href: 'https://www.facebook.com/profitrhino/',
    title: 'Facebook',
    icon: 'facebook',
  },
  {
    href: 'https://www.linkedin.com/company/flat-rate-price-book/',
    title: 'LinkedIn',
    icon: 'linkedin',
  }
];

export interface SocialMediaItem {
  href: string;
  icon: string;
  title: string;
}
