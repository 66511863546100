import { Injectable } from '@angular/core';
import { UnauthenticatedApiClient } from '../services/api-client';
import { ContentResponse } from '../models/content-response';
import { HttpResponse } from '../models/http-response';
import { LoginPageHtmlResponse } from '../models/login-page-html-response';
import { Observable } from 'rxjs';
@Injectable()
export class ContentApi {

  constructor(private readonly _apiClient: UnauthenticatedApiClient) {
  }

  public getLoginPageHtml(): Observable<HttpResponse<LoginPageHtmlResponse>> {
    return this._apiClient.get<LoginPageHtmlResponse>(`/home/getloginpagehtml`);
  }

  public getTermsAndServiceHtml(): Observable<HttpResponse<ContentResponse>> {
    return this._apiClient.get<ContentResponse>(`/terms_of_service`);
  }

  public feedback(feedback:any): Observable<HttpResponse<any>> {
    return this._apiClient.post<any>(`/api/v2/ContentFeedback`,feedback);
  }
}
