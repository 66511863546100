<ng-container *ngIf="!data.complex">
  <div mat-dialog-content>
    {{data.message}}
  </div>
  <div mat-dialog-actions align='center'>
    <button *ngFor="let action of data.actions" mat-flat-button [color]="action.color"
      [mat-dialog-close]="action.value">{{action.label}}</button>
  </div>
</ng-container>

<ng-container *ngIf="data.complex  && !data.textBox">
  <h2 mat-dialog-title>{{data.message}}</h2>
  <mat-dialog-content style="
    min-height: 75px; 
    display: flex;
    flex-direction: column;
    overflow: hidden;">
    <mat-checkbox *ngFor="let action of data.complexConfigurations" (change)="updateCloseData($event, action)"
      [color]="action.color" [checked]="action.checked">{{action.label}}</mat-checkbox>
    <div [innerHTML]="data.furtherDetails">
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <ng-container *ngIf="data.showCustomActionButtons else builtInActionbuttons">
      <button *ngFor="let action of data.actions" [ngStyle]="action.style" mat-button [color]="action.color"
        [mat-dialog-close]="action.value">{{action.label}}</button>
    </ng-container>
    <ng-template #builtInActionbuttons>
      <button mat-button color="primary" [mat-dialog-close]="false" style="margin-left: auto">CANCEL</button>
      <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
      <button *ngIf="data.complexConfigurations?.length > 0" mat-button color="primary"
        [mat-dialog-close]="closingData">CONFIRM</button>
      <button *ngIf="data.complexConfigurations?.length === 0" mat-button color="primary"
        [mat-dialog-close]="true">CONFIRM</button>
    </ng-template>
  </mat-dialog-actions>
</ng-container>

<ng-container *ngIf="data.complex && data.textBox">
  <h2 mat-dialog-title>{{data.message}}</h2>
  <mat-dialog-content style="min-height: 75px">
    <input style="width: 100%; height: 2rem;" [type]="action.type" [pattern]="action.pattern" #inputField
      *ngFor="let action of data.complexConfigurations" (change)="updateCloseTextData(action)">
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button color="primary" [mat-dialog-close]="false" style="margin-left: auto">CANCEL</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button *ngIf="data.complexConfigurations?.length > 0" mat-button color="primary"
      [mat-dialog-close]="closingData">CONFIRM</button>
    <button *ngIf="data.complexConfigurations?.length === 0" mat-button color="primary"
      [mat-dialog-close]="true">CONFIRM</button>
  </mat-dialog-actions>
</ng-container>