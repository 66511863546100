import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomSnackBarComponent } from './custom-snackbar.component';

const EXPORTED_COMPONENTS = [
  CustomSnackBarComponent,
];

@NgModule({
  declarations: [
    EXPORTED_COMPONENTS,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    EXPORTED_COMPONENTS,
  ],
  entryComponents: [
    CustomSnackBarComponent
  ]
})
export class PrCustomSnackbarModule { }
