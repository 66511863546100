
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { EnumHelpers } from 'core/src/app/common/services/enum-helpers.service';
import { Navigation } from 'core/src/app/ui/common/components/navigation/navigation';
import { NavigationType } from 'core/src/app/ui/common/components/navigation/navigation-type';
import { StylableComponent } from 'core/src/app/ui/common/components/stylable-component';
import { MediaQuery } from 'core/src/app/ui/common/services/media-query.service';
import { filter } from 'rxjs/operators';

const DEFAULT_TYPE: NavigationType = NavigationType.Vertical;
let VALID_TYPES: any[];

/**
 * Navigation component
 */
@Component({
  selector: 'ps-nav',
  templateUrl: './navigation.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  animations: [
    trigger('animateOpenClose', [
      state('open', style({
        width: '223px'
      })),
      state('closed', style({
        width: '50px'
      })),
      transition('* => *', animate(500))
    ])
  ]
})

export class NavigationComponent extends StylableComponent implements OnInit {
  @Input('config') config: Navigation;
  @Input('type') type: NavigationType;
  @Input('bookGeneratedStatus') bookGeneratedStatus: string;
  @Input('contentUpdateStatus') contentUpdateStatus: number;
  showOnlyIcon: boolean;
  activeRoute: string;
  router: any;
  get contentUpdateStatusIcon(): any {
    switch (this.contentUpdateStatus) {
      case 2:
        return "ContentUpdateCompletedIcon";
      default:
        return "ContentUpdateIcon";
    }
  }
  get contentUpdateStatusClass(): any {
    return {
      "update-completed":this.contentUpdateStatus===2,
      "update-available":this.contentUpdateStatus===0,
      "update-pending":this.contentUpdateStatus===1
    };
   
  }
  @Output() fieldEdgeEvent;

  @Output() generateBookEvent = new EventEmitter();

  constructor(
    el: ElementRef,
    mediaQuery: MediaQuery,
    enumHelpers: EnumHelpers,
    router: Router
  ) {
    super(el, mediaQuery);
    if (!VALID_TYPES) {
      VALID_TYPES = enumHelpers.values(NavigationType);
    }

    this.type = DEFAULT_TYPE;

    this.activeRoute = router.url;
    router.events.pipe(
      filter(event => event instanceof NavigationStart)
    )
      .subscribe((event: any) => {
        this.activeRoute = event.url;
      });

    this.router = router;
    this.showOnlyIcon = localStorage.getItem('bodyclass') === 'opened-menu' ? false : true;

    this.fieldEdgeEvent = new EventEmitter();
  }

  ngOnInit() {
    if (!this.config) {
      throw new Error('No navigation config has been defined.');
    }

    if (!this.type) {
      throw new Error(`Navigation type is not defined (valid types: ${VALID_TYPES.join(',')})`);
    }

    // Even though Typescript will prevent setting in an invalid type, Angular dynamically sets it so we need to check it at runtime
    if (VALID_TYPES.indexOf(this.type) === -1) {
      throw new Error(`Invalid navigation type "${this.type}" (valid types: ${VALID_TYPES.join(',')})`);
    }

    this._el.nativeElement.classList.add('nav-' + this.type);
  }

  trackByIndex(_index: number) {
    return _index;
  }

  toggle() {
    this.showOnlyIcon = !this.showOnlyIcon;
    if (this.showOnlyIcon) {
      localStorage.setItem('bodyclass', '');
    } else {
      localStorage.setItem('bodyclass', 'opened-menu');
    }
  }

  getNavigation(type) {
    return this.config.items.filter(item => item.type === type);
  }

  fieldEdgePolling() {
    this.fieldEdgeEvent.next();
  }

  generateBook() {
    if (this.bookGeneratedStatus !== 'InProgress') {
      this.generateBookEvent.next();
    }

  }

  get isExpanded() {
    return localStorage.getItem('bodyclass') === 'opened-menu' ? false : true;
  }

  get isExpandedState() {
    return localStorage.getItem('bodyclass') === 'opened-menu' ? 'open' : 'closed';
  }

  get syncColor() {
    switch (this.bookGeneratedStatus) {
      case 'Completed':
        return '#5587C3';
      case 'InProgress':
        return '#f5a623';
      case 'NotStarted':
        return '#5587C3';
      case 'FailedException':
        return '#D8000C';
      default:
        break;
    }
  }
}
