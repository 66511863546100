import { Routes } from '@angular/router';
import { DBVersionGuard } from './gaurds/db-version-gaurd.service';
import { NoAuthGuard } from './gaurds/no-auth-gaurd.service';
import { NotFoundComponent } from './pages/not-found/not-found.component';
var ɵ0 = function () { return import("./modules/auth/auth.module.ngfactory").then(function (m) { return m.PrAuthModuleNgFactory; }); }, ɵ1 = { preload: true, delay: false }, ɵ2 = function () { return import("./modules/business-information/business-information.module.ngfactory").then(function (m) { return m.BusinessInformationModuleNgFactory; }); }, ɵ3 = { preload: false, delay: true }, ɵ4 = function () { return import("./modules/content-management/content-management.module.ngfactory").then(function (m) { return m.ContentManagementModuleNgFactory; }); }, ɵ5 = { preload: false, delay: true }, ɵ6 = function () { return import("./modules/parts/parts.module.ngfactory").then(function (m) { return m.PartsModuleNgFactory; }); }, ɵ7 = { preload: false, delay: true }, ɵ8 = function () { return import("./modules/tasks/tasks.module.ngfactory").then(function (m) { return m.TasksModuleNgFactory; }); }, ɵ9 = { preload: false, delay: true }, ɵ10 = function () { return import("./modules/option-board/option-board.module.ngfactory").then(function (m) { return m.OptionBoardModuleNgFactory; }); }, ɵ11 = { preload: false, delay: true }, ɵ12 = function () { return import("./modules/books/books.module.ngfactory").then(function (m) { return m.BooksModuleNgFactory; }); }, ɵ13 = { preload: false, delay: true };
// import { NoUsedRouteGuard } from 'shared/src/app/gaurds/no-used-route-gaurd.service';
var RouteConfig = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: '',
        canActivate: [NoAuthGuard],
        loadChildren: ɵ0,
        data: ɵ1
    },
    {
        path: 'Company/Business',
        loadChildren: ɵ2,
        data: ɵ3,
        canActivate: [DBVersionGuard],
    },
    {
        path: 'Company/Contents',
        loadChildren: ɵ4,
        data: ɵ5,
        canActivate: [DBVersionGuard],
    },
    {
        path: 'Company/PriceBook',
        canActivate: [DBVersionGuard],
        children: [
            {
                path: '',
                redirectTo: 'ManageBook/0',
                pathMatch: "full"
            },
            {
                path: '',
                loadChildren: ɵ6,
                data: ɵ7,
            },
            {
                path: '',
                loadChildren: ɵ8,
                data: ɵ9,
            },
            {
                path: 'Options',
                loadChildren: ɵ10,
                data: ɵ11,
            },
            {
                path: 'ManageBook',
                loadChildren: ɵ12,
                data: ɵ13,
            },
        ]
    },
    {
        path: 'Company/ManageBook',
        redirectTo: "Company/PriceBook/ManageBook/0",
        pathMatch: 'full'
    },
    {
        path: 'Company/ManageBook/0',
        redirectTo: "Company/PriceBook/ManageBook/0",
        pathMatch: 'full'
    },
    {
        path: '**',
        component: NotFoundComponent,
        pathMatch: 'full'
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13 };
