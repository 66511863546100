import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as fromApp from '../../../../store/app.reducers';
import * as AuthActions from '../../store/auth.actions';
import * as fromAuthSelectors from '../../store/auth.selectors';
import { IUserState } from '../../store/auth.state';
@Component({
  selector: 'pr-login',
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit, OnDestroy {
  title = 'Login Component';
  isBusy$: Observable<boolean>;
  loginFailureReason$: Observable<string>;
  returnUrl: string;
  loginformGroup: FormGroup;

  showTermsOfService$: Observable<boolean>;
  isAccepted$: Observable<boolean>;
  user$: Observable<IUserState>;

  private _userSubscription: Subscription;
  private _isAcceptedSubscription: Subscription;

  validEmailPattern = /^([A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?)$/;

  constructor(
    private route: ActivatedRoute,
    private readonly _router: Router,
    private fb: FormBuilder,
    private store: Store<fromApp.AppState>
  ) {  }

  ngOnInit() {
    this.isBusy$ = this.store.select(fromAuthSelectors.selectLoginState.isLoading);
    this.loginFailureReason$ = this.store.select(fromAuthSelectors.selectLoginState.loginFailureReason);
    this.showTermsOfService$ = this.store.select(fromAuthSelectors.selectTermsOfServiceState.showTermsOfService);
    this.isAccepted$ = this.store.select(fromAuthSelectors.selectTermsOfServiceState.isAccepted);
    this.user$ = this.store.select(fromAuthSelectors.selectUserState.user);

    this.loginformGroup = this.fb.group({
      userName: new FormControl('', [Validators.required, Validators.pattern(this.validEmailPattern)]),
      password: new FormControl('', Validators.required),
      rememberMe: new FormControl('')
    });

    // reset login status
    this._userSubscription = this.user$.subscribe(user => {
      if (user && user.rememberMe) {
        this.loginformGroup.controls['userName'].setValue(user.username);
        this.loginformGroup.controls['rememberMe'].setValue(user.username);
      }
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this._isAcceptedSubscription = this.isAccepted$.subscribe((isAccepted) => {
      if (isAccepted) {
         this.store.dispatch(new AuthActions.TrySignin(this.getLoginModel()));
      }
    });
  }

  login() {
    // Check if the form are valid, return if they are not
    if (!this.loginformGroup.valid) {
      return;
    }
    this.store.dispatch(new AuthActions.TryGetLoginDetails(this.getLoginModel()));
  }

  termsOfService() {
    this.store.dispatch(new AuthActions.TermsOfService(false));
  }

  forgotPassword() {
    this._router.navigate(['forgot-password']);
  }

  getLoginModel() {
    return {
      userName: this.loginformGroup.controls['userName'].value,
      password: this.loginformGroup.controls['password'].value,
      rememberMe: this.loginformGroup.controls['rememberMe'].value
    };
  }

  ngOnDestroy() {
    this._userSubscription.unsubscribe();
    this._isAcceptedSubscription.unsubscribe();
  }
}

export interface LoginModel {
  userName: string;
  password: string;
  rememberMe: boolean;
}

