import { Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { BreakpointChangeEvent } from 'core/src/app/ui/common/breakpoint-change-event';
import { ScreenBreakpoint } from 'core/src/app/ui/common/screen-breakpoint';
import { debounce } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';

const SCREEN_BREAKPOINTS: ScreenBreakpoint[] = ['xs', 'sm', 'md', 'lg'];

const BREAKPOINT_CONFIGS: BreakpointConfig[] = [
  {
    breakpoint: 'lg',
    size: '75em',
  },
  {
    breakpoint: 'md',
    size: '62em',
  },
  {
    breakpoint: 'sm',
    size: '48em',
  },
  {
    breakpoint: 'xs',
    size: '0em',
  },
];

/**
 * Allows consumers of this service to subscribe to screen breakpoint changes so they can update their behavior accordingly
 */
@Injectable({ providedIn: 'root' })
export class MediaQuery {
  private readonly _changesSubject: BehaviorSubject<BreakpointChangeEvent>;

  constructor(eventManager: EventManager) {
    // Stores the current breakpoint so we know when we need to publish a breakpoint change event
    let currentBreakpoint: ScreenBreakpoint | undefined;
    const self = this;

    const breakpoints: BreakpointInfo[] = BREAKPOINT_CONFIGS.map(breakpointConfig => {
      return {
        breakpoint: breakpointConfig.breakpoint,
        mediaQuery: window.matchMedia(`(min-width: ${breakpointConfig.size})`),
      };
    });

    // Create the subscription that we're going to publish breakpoint change events to
    this._changesSubject = new BehaviorSubject({
      new: getBreakpoint(),
    });

    // Listen to the browser resize event and only process once the screen hasn't been resized for 100ms
    eventManager.addGlobalEventListener('window', 'resize', debounce(onResize, 100));

    function onResize() {
      const newBreakpoint = getBreakpoint();

      if (newBreakpoint === currentBreakpoint) {
        return;
      }

      self._changesSubject.next({
        old: currentBreakpoint,
        new: newBreakpoint,
      });

      currentBreakpoint = newBreakpoint;
    }

    function getBreakpoint(): ScreenBreakpoint {
      let result: ScreenBreakpoint = 'lg';

      for (let i = 0; i < breakpoints.length; i++) {
        const breakpoint = breakpoints[i];

        if (breakpoint.mediaQuery.matches) {
          result = breakpoint.breakpoint;
          break;
        }
      }

      return result;
    }
  }

  static get breakpoints(): ScreenBreakpoint[] {
    return SCREEN_BREAKPOINTS;
  }

  get changes(): Observable<BreakpointChangeEvent> {
    return this._changesSubject.asObservable();
  }
}

interface BreakpointConfig {
  breakpoint: ScreenBreakpoint;
  size: string;
}

interface BreakpointInfo {
  breakpoint: ScreenBreakpoint;
  mediaQuery: MediaQueryList;
}
