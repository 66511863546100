import { OnDestroy, OnInit } from '@angular/core';
import * as AuthActions from '../app/modules/auth/store/auth.actions';
import { ThemeService } from './services/theme.service';
import { select, Store } from '@ngrx/store';
import * as fromAuthSelectors from './modules/auth/store/auth.selectors';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { IconRegisterService } from './services/icon-register.service';
import * as rg4js from 'raygun4js';
import { Subject } from 'rxjs';
import { withLatestFrom, filter, takeUntil } from 'rxjs/operators';
import { RouteInterceptor } from 'shared/src/app/interceptors/router.interceptor.service';
import { LoaderService } from 'shared/src/app/interceptors/loader.service';
var VERSION_NUMBER = '1.0.0.0';
rg4js('apiKey', window.location.href.indexOf('secure') > -1 ? 'JPD8TZzaf8m2p2vGSfcHEw' : 'DeF8dBoi6AEsSn1ibgxtlA');
rg4js('setVersion', VERSION_NUMBER);
rg4js('enablePulse', true);
var AppComponent = /** @class */ (function () {
    function AppComponent(_themeService, store, _routeInterceptor, router, iconRegisterService, loaderService) {
        this._themeService = _themeService;
        this.store = store;
        this._routeInterceptor = _routeInterceptor;
        this.router = router;
        this.iconRegisterService = iconRegisterService;
        this.loaderService = loaderService;
        this.title = 'APP-Component';
        this.loading = true;
        this.excludedNavBarRoutes = ["login", "forgot-password", "reset-password"];
        this.userLoggedIn = false;
        this.unsubscribe$ = new Subject();
        this.showNavBar = false;
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._themeService.intializeTheme();
        this.iconRegisterService.registerIcons();
        this.store.pipe(select(fromAuthSelectors.selectUserState.user), takeUntil(this.unsubscribe$)).subscribe(function (user) {
            if (user && user.token && user.token !== '') {
                _this.store.dispatch(new AuthActions.GetCompanyFeatures());
                _this.store.dispatch(new AuthActions.GetMaskedLabels());
                _this.userLoggedIn = true;
            }
            else {
                _this.userLoggedIn = false;
            }
        });
        // tslint:disable-next-line: no-unused-expression
        this._routeInterceptor;
        this.router.events.subscribe(function (event) {
            _this.navigationInterceptor(event);
        });
        if (window.location.href.indexOf('dev') === -1) {
            this.raygunSubscription = this.router.events
                .pipe(withLatestFrom(this.store.pipe(select(fromAuthSelectors.selectUserState.user))), filter(function (_a) {
                var user = _a[1];
                return user.username !== undefined && user.username !== '';
            }))
                .subscribe(function (_a) {
                var event = _a[0], user = _a[1];
                if (event instanceof NavigationEnd) {
                    // Track navigation end
                    rg4js('trackEvent', {
                        type: 'pageView',
                        path: event.url
                    });
                    rg4js('setUser', {
                        identifier: user.username,
                        isAnonymous: false,
                        email: user.username ? user.username : 'C# USER',
                        firstName: user.username ? user.username : 'C# USER',
                        fullName: user.companyName,
                    });
                }
            });
        }
    };
    AppComponent.prototype.navigationInterceptor = function (event) {
        if (event instanceof NavigationStart) {
            this.loading = true;
            this.setNavBarStatus(event.url);
        }
        if (event instanceof NavigationEnd) {
            this.loading = false;
            this.setNavBarStatus(event.urlAfterRedirects);
        }
        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            this.loading = false;
            this.setNavBarStatus(event.url);
        }
        if (event instanceof NavigationError) {
            this.loading = false;
            this.setNavBarStatus(event.url);
        }
    };
    AppComponent.prototype.setNavBarStatus = function (url) {
        this.showNavBar = !this.excludedNavBarRoutes.some(function (x) { return url.indexOf(x) >= 0; });
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.pollingSub.unsubscribe();
        if (window.location.href.indexOf('dev') === -1) {
            this.raygunSubscription.unsubscribe();
        }
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    return AppComponent;
}());
export { AppComponent };
