<form *ngIf="!(showTermsOfService$ | async)" class="login-form"
 [formGroup]="loginformGroup">
  <mat-form-field appearance="outline" class="mat-full-width"> 
    <mat-label> User Name
      <span class="req">*</span>
    </mat-label>
    <input type="email" matInput formControlName="userName">
    <mat-error *ngIf="this.loginformGroup.controls['userName'].hasError('required')">
        Please enter your username
    </mat-error>
    <mat-error *ngIf="!this.loginformGroup.controls['userName'].hasError('required')">
        Please enter a valid username
    </mat-error>
</mat-form-field>
<mat-form-field appearance="outline" class="mat-full-width">
    <mat-label> Password
        <span class="req">*</span>
    </mat-label>
    <input matInput type="password" formControlName="password"/>
    <mat-error>
        Please enter your password
    </mat-error>
</mat-form-field>
  <div>
    <div class="remember-me">
      <mat-checkbox color="primary" formcontrolName="rememberMe"> Remember Me </mat-checkbox>
    </div>
    <div class="forgot-password">
      <a (click)="forgotPassword()" title="Forgot Your Password">Forgot Your
        Password</a>
    </div>
    
  </div>
  <div *ngIf="loginFailureReason$ | async" class="error">
    {{loginFailureReason$ | async}}
  </div>

  <button [disabled]="isBusy$ | async" mat-flat-button color="primary" class="continue-btn" (click)="login()">
    <span [class.hidden]="isBusy$ | async">Continue</span>
    <mat-progress-spinner [class.hidden]="!(isBusy$ | async)" color="accent" mode="indeterminate" diameter="20"></mat-progress-spinner>
  </button>

  <div class="tos-div">
    <a (click)="termsOfService()" title="Terms of Service - Privacy Policy" class="button text">
      Terms of Service - Privacy Policy
    </a>
  </div>
</form>
<pr-terms-of-service *ngIf="showTermsOfService$ | async" ></pr-terms-of-service>
