import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromApp from '../store/app.reducers';
import * as AuthActions from '../modules/auth/store/auth.actions';
import * as fromAuthSelectors from '../modules/auth/store/auth.selectors';
import { Observable, of } from 'rxjs';
import { first, map, skipWhile, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NoAuthGuard implements CanActivate {
  constructor(
    private store: Store<fromApp.AppState>
  ) { }

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean> {

    return this.store.select(fromAuthSelectors.selectUserState.user).pipe(
      first(),
      map(user => {
        if (user && user.token && user.token !== '' ) {
          this.store.dispatch(new AuthActions.IsTokenExpired(user));
          return false;
        }
        return true;
      }),
      switchMap(res => {
        if (res) {
          return of(true);
        } else {
          return this.store.select(fromAuthSelectors.selectUserState.isCheckingToken).pipe(
            skipWhile(check => check.isCheckingToken),
            map(check => check.isTokenValid),
            first()
          );
        }
      })
    );
  }
}
