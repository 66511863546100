<ng-container *ngIf="subMenu.validatePermission">
    <div class="sub-menu" *validatePermission="subMenu.permissions">
        <ng-container *ngIf="subMenu.hrefLink">
            <a [href]="subMenu.hrefLink" routerLinkActive="active">
                {{ subMenu.label }}
            </a>
        </ng-container>
        <ng-container *ngIf="!subMenu.hrefLink">
            <a [routerLink]="subMenu.routerLink" routerLinkActive="active">
                {{ subMenu.label }}
            </a>
        </ng-container>
    </div>
</ng-container>
<ng-container *ngIf="!subMenu.validatePermission">
    <div class="sub-menu">
        <ng-container *ngIf="subMenu.hrefLink">
            <a [href]="subMenu.hrefLink" routerLinkActive="active">
                {{ subMenu.label }}
            </a>
        </ng-container>
        <ng-container *ngIf="!subMenu.hrefLink">
            <a [routerLink]="subMenu.routerLink" routerLinkActive="active">
                {{ subMenu.label }}
            </a>
        </ng-container>
    </div>
</ng-container>