import { IMaskLabels } from 'frb-app/src/app/modules/auth/store/auth.state';
import { ITask } from 'frb-app/src/app/modules/business-information/store/business-information.models';

export const SAMPLE_TASK: ITask = {
    bookShow: false,
    isManaged: "UserCreated",
    taskAssignmentId: 0,
    brandName: 'test',
    id: 0,
    taskNumber: 'T500001',
    createdDate: '11/11/2019',
    modifiedDate: '11/11/2019',
    name: 'Example Task',
    description: 'Example Description',
    marketingDescription: '<h1>Example Marketing Description</h1>',
    quickAddType: 'none',
    isHidden: false,
    taskDuration: 60,
    taskAsstTime: 0,
    addonEnabled: true,
    addonNumber: 'A500001',
    addonDuration: 30,
    addonAsstTime: 0,
    isActive: true,
    valueRateOnly: false,
    isDiscountService: false,
    taskPriceType: 0,
    businessTypeId: 0,
    businessType: '',
    isOem: true,
    isRefrigerant: false,
    requiresRefrigerant: true,
    images: [
        {
            id: 0,
            order: 0,
            url: 'https://s3.us-east-2.amazonaws.com/profitrhino/nulltasks/D0055.jpg'
        }
    ],
    crossSellTasks: [],
    upSellTasks: [],
    taskPrices: [
        {
            valueRate: '100.00',
            standardRate: '200.00',
            premiumRate: '300.00',
            addonValueRate: '50.00',
            addonStandardRate: '100.00',
            addonPremiumRate: '150.00',
            id: 0,
            refUrl: 'sample.com'
        }
    ],
    parts: [],
    documents: [],
    premiumRate:200,
    valueRate:400,
    standardRate:500
};

export const SAMPLE_MASK: IMaskLabels = {
    value: 'Value',
    standard: 'Standard',
    premium: 'Premium'
};