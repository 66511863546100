<div class="loading-container" *ngIf="loading">
    <div class="row">
        <div id="loader">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="loading"></div>
        </div>
    </div>
</div>
<ng-container *ngIf="showNavBar">
    <mat-progress-bar *ngIf="(loaderService.isLoading | async) as boolean" color="primary" mode="indeterminate"
        class="loader"> </mat-progress-bar>
</ng-container>
<pr-hold-alert></pr-hold-alert>
<div class="nav-content-wrapper">
    <pr-nav-bar *ngIf="showNavBar && userLoggedIn">
    </pr-nav-bar>
    <div class="content-wrapper">
        <router-outlet></router-outlet>
    </div>
</div>