import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { PsIconModule } from 'core/src/app/ui/common/components/icon/icon.module';
import { NavigationComponent } from 'core/src/app/ui/common/components/navigation/navigation.component';

const EXPORTED_COMPONENTS = [
  NavigationComponent,
];

@NgModule({
  declarations: [
    EXPORTED_COMPONENTS,
  ],

  imports: [
    CommonModule,
    RouterModule,
    PsIconModule,
    MatIconModule
  ],

  exports: [
    EXPORTED_COMPONENTS,
  ],
})
export class PsNavigationModule { }
