import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { EnumHelpers } from 'core/src/app/common/services/enum-helpers.service';
import { Navigation } from 'core/src/app/ui/common/components/navigation/navigation';
import { NavigationType } from 'core/src/app/ui/common/components/navigation/navigation-type';
import { StylableComponent } from 'core/src/app/ui/common/components/stylable-component';
import { MediaQuery } from 'core/src/app/ui/common/services/media-query.service';
import { filter } from 'rxjs/operators';
var DEFAULT_TYPE = NavigationType.Vertical;
var VALID_TYPES;
/**
 * Navigation component
 */
var NavigationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(NavigationComponent, _super);
    function NavigationComponent(el, mediaQuery, enumHelpers, router) {
        var _this = _super.call(this, el, mediaQuery) || this;
        _this.generateBookEvent = new EventEmitter();
        if (!VALID_TYPES) {
            VALID_TYPES = enumHelpers.values(NavigationType);
        }
        _this.type = DEFAULT_TYPE;
        _this.activeRoute = router.url;
        router.events.pipe(filter(function (event) { return event instanceof NavigationStart; }))
            .subscribe(function (event) {
            _this.activeRoute = event.url;
        });
        _this.router = router;
        _this.showOnlyIcon = localStorage.getItem('bodyclass') === 'opened-menu' ? false : true;
        _this.fieldEdgeEvent = new EventEmitter();
        return _this;
    }
    Object.defineProperty(NavigationComponent.prototype, "contentUpdateStatusIcon", {
        get: function () {
            switch (this.contentUpdateStatus) {
                case 2:
                    return "ContentUpdateCompletedIcon";
                default:
                    return "ContentUpdateIcon";
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationComponent.prototype, "contentUpdateStatusClass", {
        get: function () {
            return {
                "update-completed": this.contentUpdateStatus === 2,
                "update-available": this.contentUpdateStatus === 0,
                "update-pending": this.contentUpdateStatus === 1
            };
        },
        enumerable: true,
        configurable: true
    });
    NavigationComponent.prototype.ngOnInit = function () {
        if (!this.config) {
            throw new Error('No navigation config has been defined.');
        }
        if (!this.type) {
            throw new Error("Navigation type is not defined (valid types: " + VALID_TYPES.join(',') + ")");
        }
        // Even though Typescript will prevent setting in an invalid type, Angular dynamically sets it so we need to check it at runtime
        if (VALID_TYPES.indexOf(this.type) === -1) {
            throw new Error("Invalid navigation type \"" + this.type + "\" (valid types: " + VALID_TYPES.join(',') + ")");
        }
        this._el.nativeElement.classList.add('nav-' + this.type);
    };
    NavigationComponent.prototype.trackByIndex = function (_index) {
        return _index;
    };
    NavigationComponent.prototype.toggle = function () {
        this.showOnlyIcon = !this.showOnlyIcon;
        if (this.showOnlyIcon) {
            localStorage.setItem('bodyclass', '');
        }
        else {
            localStorage.setItem('bodyclass', 'opened-menu');
        }
    };
    NavigationComponent.prototype.getNavigation = function (type) {
        return this.config.items.filter(function (item) { return item.type === type; });
    };
    NavigationComponent.prototype.fieldEdgePolling = function () {
        this.fieldEdgeEvent.next();
    };
    NavigationComponent.prototype.generateBook = function () {
        if (this.bookGeneratedStatus !== 'InProgress') {
            this.generateBookEvent.next();
        }
    };
    Object.defineProperty(NavigationComponent.prototype, "isExpanded", {
        get: function () {
            return localStorage.getItem('bodyclass') === 'opened-menu' ? false : true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationComponent.prototype, "isExpandedState", {
        get: function () {
            return localStorage.getItem('bodyclass') === 'opened-menu' ? 'open' : 'closed';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationComponent.prototype, "syncColor", {
        get: function () {
            switch (this.bookGeneratedStatus) {
                case 'Completed':
                    return '#5587C3';
                case 'InProgress':
                    return '#f5a623';
                case 'NotStarted':
                    return '#5587C3';
                case 'FailedException':
                    return '#D8000C';
                default:
                    break;
            }
        },
        enumerable: true,
        configurable: true
    });
    return NavigationComponent;
}(StylableComponent));
export { NavigationComponent };
