import { OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
var RouteInterceptor = /** @class */ (function () {
    function RouteInterceptor(router) {
        var _this = this;
        this._routeHistory = [];
        this.routerSubscription = router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (event) {
            _this._setURLs(event);
        });
    }
    RouteInterceptor.prototype._setURLs = function (event) {
        var tempUrl = this._currentUrl;
        this._previousUrl = tempUrl;
        this._currentUrl = event.urlAfterRedirects;
        if (this._routeHistory.indexOf(this._currentUrl) === -1) {
            this._routeHistory.push(event.urlAfterRedirects);
        }
    };
    Object.defineProperty(RouteInterceptor.prototype, "previousUrl", {
        get: function () {
            return this._previousUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RouteInterceptor.prototype, "currentUrl", {
        get: function () {
            return this._currentUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RouteInterceptor.prototype, "routeHistory", {
        get: function () {
            return this._routeHistory;
        },
        enumerable: true,
        configurable: true
    });
    RouteInterceptor.prototype.ngOnDestroy = function () {
        this.routerSubscription.unsubscribe();
    };
    return RouteInterceptor;
}());
export { RouteInterceptor };
