<div class="forgot-password-success-div mat-typography">
  <h1 class="mat-display-1 header">Check your mail</h1>
  <h2 class="content">We sent an email with
    instructions to reset your password</h2>
  <div class="img-div">
    <img width="118" height="118" src="./assets/img/pw-reset.png" alt="Message Box" />
  </div>

  <a [routerLink]="'/login'" title="Login" class="login">Log in</a>
  <div class="tos-div">
    <a (click)="termsOfService()" title="Terms of Service - Privacy Policy">
      Terms of Service - Privacy Policy
    </a>
  </div>
</div>
