<form *ngIf="!(showTermsOfService$ | async)" class="reset-password-form mat-typography"
  [formGroup]="resetPasswordFormGroup">
  <h2 class="mat-h2 header">Reset Password</h2>
  <mat-form-field appearance="outline" class="mat-full-width">
    <mat-label>New Password
      <span class="req">*</span>
    </mat-label>
    <input matInput [type]="show? 'text' : 'password'" formControlName="newPassword" class="display-icon" />
    <div class="show-hide">
      <mat-icon matSuffix (click)="show = !show">{{show ? 'visibility_off' : 'visibility'}}</mat-icon>
    </div>
    <mat-error *ngIf="this.resetPasswordFormGroup.controls['newPassword'].hasError('required')">
      Please enter new password
    </mat-error>
    <mat-error *ngIf="this.resetPasswordFormGroup.controls['newPassword'].hasError('pattern')">
      Please enter valid new password
      <div class="show-tooltip">
        <mat-icon matTooltip="Password must contain an Uppercase,
          Lowercase letter,1 number and minimum 8 characters long.">contact_support</mat-icon>
      </div>
    </mat-error>
    <mat-error *ngIf="!this.resetPasswordFormGroup.controls['newPassword'].hasError('required') &&
    !this.resetPasswordFormGroup.controls['newPassword'].hasError('pattern')">
      Please enter less then 255 characters.
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="mat-full-width">
    <mat-label>Confirm Password
      <span class="req">*</span>
    </mat-label>
    <input matInput [type]="showConfirmPws? 'text' : 'password'" formControlName="confirmPassword"
      class="display-icon" />
    <div class="show-hide">
      <mat-icon matSuffix (click)="showConfirmPws = !showConfirmPws">
        {{showConfirmPws ? 'visibility_off' : 'visibility'}}</mat-icon>
    </div>
    <mat-error *ngIf="this.resetPasswordFormGroup.controls['confirmPassword'].hasError('required')">
      Please confirm new password
    </mat-error>
    <mat-error *ngIf="this.resetPasswordFormGroup.controls['confirmPassword'].hasError('pattern')">
      Please enter valid confirm password
      <div class="show-tooltip">
        <mat-icon matTooltip="Password must contain an Uppercase,
          Lowercase letter,1 number and minimum 8 characters long.">contact_support</mat-icon>
      </div>
    </mat-error>
    <mat-error *ngIf="!this.resetPasswordFormGroup.controls['confirmPassword'].hasError('required') &&
    !this.resetPasswordFormGroup.controls['confirmPassword'].hasError('pattern')">
      Please enter less then 255 characters.
    </mat-error>

  </mat-form-field>
  <div *ngIf="resetPasswordFailureReason$ | async" class="error">
    {{ resetPasswordFailureReason$ | async}}
  </div>
  <button [disabled]="isBusy$ | async" class="btn" mat-flat-button color="primary" (click)="doResetPassword()">
    <span [class.hidden]="isBusy$ | async">Continue</span>
    <mat-progress-spinner [class.hidden]="!(isBusy$ | async)" color="accent" mode="indeterminate" diameter="20">
    </mat-progress-spinner>
  </button>
  <div class="tos-div">
    <a (click)="termsOfService()" title="Terms of Service - Privacy Policy" class="button text">
      Terms of Service - Privacy Policy
    </a>
  </div>
</form>
<pr-terms-of-service *ngIf="showTermsOfService$ | async"></pr-terms-of-service>
