import * as i0 from "@angular/core";
var AppConfig = /** @class */ (function () {
    function AppConfig() {
    }
    Object.defineProperty(AppConfig.prototype, "environment", {
        get: function () {
            if (!this._environment) {
                this._environment = this._parseEnvironmentSettings();
            }
            return this._environment;
        },
        enumerable: true,
        configurable: true
    });
    AppConfig.prototype._parseEnvironmentSettings = function () {
        var settingsPrefix = 'window.prEnvironmentSettings';
        if (!window.prEnvironmentSettings) {
            throw new Error(settingsPrefix + " has not been defined.");
        }
        var settings = window.prEnvironmentSettings;
        return {
            urls: parseUrlSettings(settings, settingsPrefix)
        };
    };
    AppConfig.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppConfig_Factory() { return new AppConfig(); }, token: AppConfig, providedIn: "root" });
    return AppConfig;
}());
export { AppConfig };
function parseUrlSettings(settings, settingsPrefix) {
    if (!settings.hasOwnProperty('urls')) {
        throw new Error(settingsPrefix + ".urls has not been defined.");
    }
    var urls = settings.urls;
    if (!urls.hasOwnProperty('api')) {
        throw new Error(settingsPrefix + ".urls.api has not been defined.");
    }
    if (!urls.api.hasOwnProperty('root')) {
        throw new Error(settingsPrefix + ".urls.api.root has not been defined.");
    }
    return {
        api: {
            root: urls.api.root,
        },
    };
}
