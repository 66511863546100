export class EnumHelpers {
  /**
   * Gets an array of all values assigned in a TypeScript enumeration
   * @param enumObject Enumeration Object to get values from
   */
  values<T>(enumObject: T): any[] {
    return Object.keys(enumObject).map(key => (enumObject as any)[key]);
  }

  /**
   * Finds the key of a given value from a TypeScript enumeration
   * @param enumObject Enumeration Object to get key from
   * @param value Value to find in the enumeration
   */
  getKeyFromValue<T>(enumObject: T, value: any): T[keyof T] | undefined {
    const keys = Object.keys(enumObject);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const keyValue = (enumObject as any)[key];

      if (keyValue === value) {
        return keyValue as any as T[keyof T];
      }
    }
  }
}
