import { Injectable } from '@angular/core';
import { UnauthenticatedApiClient } from '../services/api-client';
import { ICategory } from 'frb-app/src/app/modules/books/store/book-models';
import { ICopyTaskRequest } from 'frb-app/src/app/modules/tasks/store/task.model';
import { HttpResponse } from '../models/http-response';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

export interface CopyConfiguration {
  taskNumber?: number;
  sourceId: number;
  destinationId: number;
  index: number;
  copyChildren: boolean;
  contentType: string;
}


@Injectable()
export class TreeApi {

  constructor(private readonly _apiClient: UnauthenticatedApiClient) {
  }

  public getPriceBookTree() {
    return this._apiClient.get<any>(`/api/v2/PriceBookTree`);
  }

  public copyCategory(copyConfiguration: CopyConfiguration): Observable<HttpResponse<any>> {
    const body = {
      ...copyConfiguration
    };
    return this._apiClient.post<any>(`/api/v2/category/actions/copy`, body);
  }

  public reOrderCategoryTaskAssignment(id: number, index: number): Observable<HttpResponse<any>> {
    const body = {
      id: id,
      index
    };
    return this._apiClient.post<any>(`/api/v2/Category/ReOrderCategoryTaskAssignment`, body);
  }

  public moveCategory(sourceId: number, destinationId: number, index: number): Observable<HttpResponse<any>> {
    const body = {
      sourceId: sourceId,
      destinationId: destinationId,
      index: index
    };
    return this._apiClient.post<any>(`/api/v2/categorytree/actions/movecategory`, body);
  }

  public moveTaskAssignment(assignmentId: number, destinationCategoryId: number, index: number): Observable<HttpResponse<any>> {
    const body = {
      assignmentId: assignmentId,
      destinationCategoryId: destinationCategoryId,
      index: index
    };
    return this._apiClient.post<any>(`/api/v2/categorytree/actions/movetaskassignment`, body);
  }

  public createCategory(category: ICategory, imageName: string) {
    return this._apiClient.post<any>(`/api/v2/Category`, {
      ...category,
      imageData: category.imageUrl,
      imageName
    });
  }

  public updateCategory(category: ICategory, imageName: string) {
    const destruct = {
      id: category.id,
      name: category.name,
      description: category.description,
      isActive: category.isActive,
      account: category.account,
      imageData: category.imageUrl,
      imageName: imageName,
      isOptIn: category.isOptIn
    };
    return this._apiClient.put<any>(`/api/v2/Category`, destruct);
  }

  public deleteCategory(id: number) {
    return this._apiClient.delete<any>(`/api/v2/Category?Id=${id}`);
  }

  public addTaskCategories(categorieIds: { id: number }[], taskId: number) {
    return this._apiClient.post<any>(`/api/v2/task/${taskId}/AssignCategory`, categorieIds);
  }

  public deleteTaskCategory(assignmentId: number, taskId: number) {
    return this._apiClient.delete(`/api/v2/Task/${taskId}/Category/${assignmentId}`);
  }

  public copyTask(CopyTaskRequest: ICopyTaskRequest) {
    let req;
    if(CopyTaskRequest.copyTo!=='') {
      req = {
        taskNumber: CopyTaskRequest.copyTo,
        copyMarketingData: CopyTaskRequest.copyMarketingData,
        buisnessTypeId: CopyTaskRequest.buisnessTypeId
      };
    } else {
      req = {
        copyMarketingData: CopyTaskRequest.copyMarketingData,
        buisnessTypeId: CopyTaskRequest.buisnessTypeId
      };
    }
    
    return this._apiClient.post<any>(`/api/v2/task/${CopyTaskRequest.copyFrom}/CopyTask`, req);
  }

  public fetchCategoryById(id: number) {
    return this._apiClient.get<any>(`/api/v2/Category/${id}/PriceBookTree`);
  }

  public activeInactiveCategoryTask(categoryId: number, TaskId: number, status: boolean) {
    const body = {
      actionType: status ? 'Activate' : 'Inactivate'
    };
    return this._apiClient.post<any>(`/api/v2/Category/${categoryId}/ActiveInactiveTaskAssignment/${TaskId}`, body);
  }

  getTasksOfCategory(id: number) {
    const params = new HttpParams().set('PageSize', '-1');
    return this._apiClient.get<any>(`/api/v2/TaskCategories/${id}/tasks`, {params});
  }

}
