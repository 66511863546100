import { CategoryAPI } from './apis/category-api';
import { NgModule } from '@angular/core';
import { UnauthenticatedApiClient } from './services/api-client';
import { AuthApi } from './apis';
import { ContentApi } from './apis/content-api';
import { fakeBackendProvider } from './helpers/fake-backend';
import { PartApi } from './apis/part-api';
import { PrApiConfig } from './pr-api-config';
import { PrApiSettings } from './pr-api-settings';
import { PR_API_SETTINGS } from './pr-api-settings.token';
import { BusinessInformationAPI } from './apis/business-information-api';
import { TaskApi } from './apis/task-api';
import { TreeApi } from './apis/tree-api';
import { ContentManagementApi } from './apis/content-management-api';
import { OptionApi } from './apis/option-api';

@NgModule({
  declarations: [
  ],
  imports: [],
  providers: [
    ContentApi,
    UnauthenticatedApiClient,
    AuthApi,
    PartApi,
    BusinessInformationAPI,
    TaskApi,
    TreeApi,
    CategoryAPI,
    ContentManagementApi,
    OptionApi,
    {
      provide: PR_API_SETTINGS,
      useValue: undefined,
    },
    {
      provide: PrApiConfig,
      useFactory: PrApiConfigProvider,
      deps: [PR_API_SETTINGS]
    },
    fakeBackendProvider,
  ]
})
export class PrApiModule { }

export function PrApiConfigProvider(settings: PrApiSettings) {
  return new PrApiConfig(settings);
}
