import { Store } from '@ngrx/store';
import * as AuthActions from '../store/auth.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var LoginResolver = /** @class */ (function () {
    function LoginResolver(store) {
        this.store = store;
    }
    LoginResolver.prototype.resolve = function (_route, _state) {
        this.store.dispatch(new AuthActions.LoginRoute());
    };
    LoginResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginResolver_Factory() { return new LoginResolver(i0.ɵɵinject(i1.Store)); }, token: LoginResolver, providedIn: "root" });
    return LoginResolver;
}());
export { LoginResolver };
