import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule, MatDialogModule, MatCheckboxModule } from '@angular/material';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';

const EXPORTED_COMPONENTS = [
  ConfirmationDialogComponent,
];

@NgModule({
  declarations: [
    EXPORTED_COMPONENTS,
  ],

  imports: [
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    CommonModule
  ],

  exports: [
    EXPORTED_COMPONENTS,
  ],
  entryComponents: [
    ConfirmationDialogComponent
  ]
})
export class PrConfirmationDialogModule { }
