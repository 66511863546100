import { Action } from '@ngrx/store';
import { IBookGenerateState, IMaskLabels } from './auth.state';
import { ForgotPasswordModel } from '../components/forgot-password/forgot-password.component';
import { ResetPasswordRequest } from 'frb-api/src/models/reset-password-request';
import { LoginModel } from '../components/login/login.component';


// Login
export const LOGIN_ROUTE = 'LOGIN_ROUTE';

export const TRY_GET_LOGIN_DETAILS = 'TRY_GET_LOGIN_DETAILS';
export const GET_LOGIN_DETAILS_SUCCESS = 'GET_LOGIN_DETAILS_SUCCESS';
export const GET_LOGIN_DETAILS_FAIL = 'GET_LOGIN_DETAILS_FAIL';

export const TRY_SIGNIN = 'TRY_SIGNIN';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';

export const GETLOGINDETAILS = 'GETLOGINDETAILS';

export const TRY_SIGNUP = 'TRY_SIGNUP';
export const SIGNUP = 'SIGNUP';

export const SET_TOKEN = 'SET_TOKEN';

export const IS_TOKEN_EXPIRED = 'IS_TOKEN_EXPIRED';
export const IS_TOKEN_EXPIRED_SUCCESS = 'IS_TOKEN_EXPIRED_SUCCESS';
export const IS_TOKEN_EXPIRED_FAIL = 'IS_TOKEN_EXPIRED_FAIL';


export const VERIFY_RESET_PASSWORD_TOKEN = 'VERIFY_RESET_PASSWORD_TOKEN';
export const VERIFY_RESET_PASSWORD_TOKEN_SUCCESS = 'VERIFY_RESET_PASSWORD_TOKEN_SUCCESS';
export const VERIFY_RESET_PASSWORD_TOKEN_FAIL = 'VERIFY_RESET_PASSWORD_TOKEN_FAIL';

export const GET_NAVIGATION_ITEMS = 'GET_NAVIGATION_ITEMS';
export const GET_NAVIGATION_ITEMS_SUCCESS = 'GET_NAVIGATION_ITEMS_SUCCESS';
export const GET_NAVIGATION_ITEMS_FAIL = 'GET_NAVIGATION_ITEMS_FAIL';

export const GET_COMPANY_FEATURES = 'GET_COMPANY_FEATURES';
export const GET_COMPANY_FEATURES_SUCCESS = 'GET_COMPANY_FEATURES_SUCCESS';
export const GET_COMPANY_FEATURES_FAIL = 'GET_COMPANY_FEATURES_FAIL';

export const FIELD_EDGE_SYNC = 'FIELD_EDGE_SYNC';
export const FIELD_EDGE_SYNC_SUCCESS = 'FIELD_EDGE_SYNC_SUCCESS';
export const FIELD_EDGE_SYNC_FAIL = 'FIELD_EDGE_SYNC_FAIL';

export const FIELD_EDGE_SYNC_POLLING = 'FIELD_EDGE_SYNC_POLLING';
export const FIELD_EDGE_SYNC_POLLING_SUCCESS = 'FIELD_EDGE_SYNC_POLLING_SUCCESS';
export const FIELD_EDGE_SYNC_POLLING_FAIL = 'FIELD_EDGE_SYNC_POLLING_FAIL';

export const SET_FIELD_EDGE_SYNC_POLLING_STATUS = 'SET_FIELD_EDGE_SYNC_POLLING_STATUS';

export const GET_MASKED_LABELS = 'GET_MASKED_LABELS';
export const GET_MASKED_LABELS_SUCCESS = 'GET_MASKED_LABELS_SUCCESS';
export const GET_MASKED_LABELS_FAIL = 'GET_MASKED_LABELS_FAIL';

export const GENERATE_BOOK = 'GENERATE BOOK';
export const BEGIN_BOOK_POLLING = 'BEGIN BOOK POLLING';
export const SET_BOOK_STATUS = 'SET_BOOK_STATUS';
export const BEGIN_CONTENTUPDATE_POLLING = 'BEGIN CONTENT UPDATE POLLING';
export const SET_CONTENTUPDATE_STATUS = 'SET CONTENT UPDATE STATUS';
// Login
export class LoginRoute implements Action {
  readonly type = LOGIN_ROUTE;
}
export class TryGetLoginDetails implements Action {
  readonly type = TRY_GET_LOGIN_DETAILS;

  constructor(public payload: LoginModel) { }
}
export class GetLoginDetailsSuccess implements Action {
  readonly type = GET_LOGIN_DETAILS_SUCCESS;
}
export class GetLoginDetailsFail implements Action {
  readonly type = GET_LOGIN_DETAILS_FAIL;
  constructor(public payload: any) { }
}

export class TrySignin implements Action {
  readonly type = TRY_SIGNIN;

  constructor(public payload: LoginModel) { }
}
export class SigninSuccess implements Action {
  readonly type = SIGNIN_SUCCESS;

  constructor(public payload: any) { }
}
export class SigninFail implements Action {
  readonly type = SIGNIN_FAIL;

  constructor(public payload: LoginModel) { }
}
export class TrySignup implements Action {
  readonly type = TRY_SIGNUP;

  constructor(public payload: LoginModel) { }
}

export class Signup implements Action {
  readonly type = SIGNUP;
}
export class GetLoginDetails implements Action {
  readonly type = GETLOGINDETAILS;
}

export class SetToken implements Action {
  readonly type = SET_TOKEN;

  constructor(public payload: string) { }
}

// FORGOT PASSWORD BEGINS
export const TRY_FORGOT_PASSWORD = 'TRY_FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export class TryForgotPassword implements Action {
  readonly type = TRY_FORGOT_PASSWORD;

  constructor(public payload: ForgotPasswordModel) { }
}

export class ForgotPasswordSuccess implements Action {
  readonly type = FORGOT_PASSWORD_SUCCESS;
}

export class ForgotPasswordFailure implements Action {
  readonly type = FORGOT_PASSWORD_FAILURE;

  constructor(public payload: string) { }
}

// FORGOT PASSWORD ENDS

// TERMS OF SERVICES BEGINS

export const TERMS_OF_SERVICES = 'TERMS_OF_SERVICES';
export const TERMS_OF_SERVICES_CONTENT = 'TERMS_OF_SERVICES_CONTENT';
export const TERMS_OF_SERVICES_CONTENT_SUCCESS = 'TERMS_OF_SERVICES_CONTENT_SUCCESS';
export const TERMS_OF_SERVICES_CONTENT_FAIL = 'TERMS_OF_SERVICES_CONTENT_FAIL';

export const TERMS_OF_SERVICES_ACCEPT = 'TERMS_OF_SERVICES_ACCEPT';
export const TERMS_OF_SERVICES_REJECT = 'TERMS_OF_SERVICES_REJECT';

export class TermsOfService implements Action {
  readonly type = TERMS_OF_SERVICES;
  constructor(public payload: boolean) { }
}

export class TermsOfServiceContent implements Action {
  readonly type = TERMS_OF_SERVICES_CONTENT;
}

export class TermsOfServiceContentSuccess implements Action {
  readonly type = TERMS_OF_SERVICES_CONTENT_SUCCESS;
  constructor(public payload: {
    htmlContentInput: string;
    loaded: boolean;
  }) { }
}

export class TermsOfServiceContentFail implements Action {
  readonly type = TERMS_OF_SERVICES_CONTENT_FAIL;
}

export class TermsOfServiceAccept implements Action {
  readonly type = TERMS_OF_SERVICES_ACCEPT;
}

export class TermsOfServiceReject implements Action {
  readonly type = TERMS_OF_SERVICES_REJECT;
}

// TERMS OF SERVICE ENDS

// Reset Password
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export class ResetPassword implements Action {
  readonly type = RESET_PASSWORD;

  constructor(public payload: ResetPasswordRequest) { }
}

export class ResetPasswordSuccess implements Action {
  readonly type = RESET_PASSWORD_SUCCESS;
}

export class ResetPasswordFailure implements Action {
  readonly type = RESET_PASSWORD_FAILURE;

  constructor(public payload: string) { }
}

export class IsTokenExpired implements Action {
  readonly type = IS_TOKEN_EXPIRED;

  constructor(public payload: any) { }
}
export class IsTokenExpiredSuccess implements Action {
  readonly type = IS_TOKEN_EXPIRED_SUCCESS;

  constructor(public payload: any) { }
}
export class IsTokenExpiredFail implements Action {
  readonly type = IS_TOKEN_EXPIRED_FAIL;

  constructor(public payload: any) { }
}

export class VerifyResetPasswordToken implements Action {
  readonly type = VERIFY_RESET_PASSWORD_TOKEN;

  constructor(public payload: any) { }
}
export class VerifyResetPasswordTokenSuccess implements Action {
  readonly type = VERIFY_RESET_PASSWORD_TOKEN_SUCCESS;
}
export class VerifyResetPasswordTokenFail implements Action {
  readonly type = VERIFY_RESET_PASSWORD_TOKEN_FAIL;
}

export const ADD_GLOBAL_ERROR = 'ADD_GLOBAL_ERROR';
export class AddGlobalError implements Action {
  readonly type = ADD_GLOBAL_ERROR;

  constructor(public payload: any) { }
}

// LOGOUT
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export class Logout implements Action {
  readonly type = LOGOUT;
}

export class LogoutSuccess implements Action {
  readonly type = LOGOUT_SUCCESS;
}

export class LogoutFailure implements Action {
  readonly type = LOGOUT_FAILURE;
}

export class GetNavigationItems implements Action {
  readonly type = GET_NAVIGATION_ITEMS;
}

export class GetNavigationItemsSuccess implements Action {
  readonly type = GET_NAVIGATION_ITEMS_SUCCESS;
  constructor(public payload: any) { }
}
export class GetNavigationItemsFail implements Action {
  readonly type = GET_NAVIGATION_ITEMS_FAIL;
  constructor(public payload: any) { }
}

export class GetMaskedLabels implements Action {
  readonly type = GET_MASKED_LABELS;
}

export class GetMaskedLabelsSuccess implements Action {
  readonly type = GET_MASKED_LABELS_SUCCESS;
  constructor(public payload: IMaskLabels) { }
}
export class GetMaskedLabelsFail implements Action {
  readonly type = GET_MASKED_LABELS_FAIL;
  constructor(public payload: any) { }
}

export class GetCompanyFeatures implements Action {
  readonly type = GET_COMPANY_FEATURES;
}
export class GetCompanyFeaturesSuccess implements Action {
  readonly type = GET_COMPANY_FEATURES_SUCCESS;
  constructor(public payload: any) { }
}
export class GetCompanyFeaturesFail implements Action {
  readonly type = GET_COMPANY_FEATURES_FAIL;
  constructor(public payload: any) { }
}

export class FieldEdgeSync implements Action {
  readonly type = FIELD_EDGE_SYNC;
}

export class FieldEdgeSyncSuccess implements Action {
  readonly type = FIELD_EDGE_SYNC_SUCCESS;
  constructor(public payload: any) { }
}

export class FieldEdgeSyncFail implements Action {
  readonly type = FIELD_EDGE_SYNC_FAIL;
  constructor(public payload: any) { }
}
export class FieldEdgeSyncPolling implements Action {
  readonly type = FIELD_EDGE_SYNC_POLLING;
}

export class FieldEdgeSyncPollingSuccess implements Action {
  readonly type = FIELD_EDGE_SYNC_POLLING_SUCCESS;
  constructor(public payload: any) { }
}

export class FieldEdgeSyncPollingFail implements Action {
  readonly type = FIELD_EDGE_SYNC_POLLING_FAIL;
  constructor(public payload: any) { }
}

export class SetFieldEdgeSyncPollingStatus implements Action {
  readonly type = SET_FIELD_EDGE_SYNC_POLLING_STATUS;
  constructor(public payload: { syncStatus: boolean, syncMessage: string }) { }
}

export class GenerateBook implements Action {
  readonly type = GENERATE_BOOK;
}

export class BeginBookPolling implements Action {
  readonly type = BEGIN_BOOK_POLLING;
}

export class SetBookStatus implements Action {
  readonly type = SET_BOOK_STATUS;

  constructor(public payload: IBookGenerateState) { }
}

export class BeginContentUpdatePolling implements Action {
  readonly type = BEGIN_CONTENTUPDATE_POLLING;
}

export class SetContentUpdateStatus implements Action {
  readonly type = SET_CONTENTUPDATE_STATUS;

  constructor(public payload: { status: number }) { }
}

export type AuthActions = LoginRoute | Signup | TrySignin | SigninSuccess | SigninFail | Logout | SetToken | TrySignup | TryGetLoginDetails | GetLoginDetailsSuccess | GetLoginDetailsFail |
  TryForgotPassword | ForgotPasswordFailure | ForgotPasswordSuccess | TermsOfService | ResetPassword | ResetPasswordSuccess | ResetPasswordFailure | TermsOfServiceAccept |
  TermsOfServiceReject | IsTokenExpired | IsTokenExpiredSuccess | IsTokenExpiredFail | VerifyResetPasswordToken | VerifyResetPasswordTokenSuccess | VerifyResetPasswordTokenFail |
  TermsOfServiceContent | TermsOfServiceContentFail | TermsOfServiceContentSuccess | AddGlobalError |
  Logout | LogoutSuccess | LogoutFailure | GetNavigationItems | GetNavigationItemsSuccess | GetNavigationItemsFail | GetCompanyFeatures | GetCompanyFeaturesSuccess | GetCompanyFeaturesFail | FieldEdgeSync | FieldEdgeSyncSuccess | FieldEdgeSyncFail | FieldEdgeSyncPolling | FieldEdgeSyncPollingSuccess | FieldEdgeSyncPollingFail
  | GetMaskedLabels | GetMaskedLabelsSuccess | GetMaskedLabelsFail | GenerateBook | BeginBookPolling | SetBookStatus | BeginContentUpdatePolling| SetContentUpdateStatus | SetFieldEdgeSyncPollingStatus;
