import { OnDestroy, OnInit } from '@angular/core';
import { Navigation } from 'core/src/app/ui/common/components/navigation/navigation';
import { NavigationLink } from 'core/src/app/ui/common/components/navigation/navigation-link';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as AuthActions from '../modules/auth/store/auth.actions';
import * as fromAuthSelectors from '../modules/auth/store/auth.selectors';
import { CustomerWrapperFooterComponent } from '../components/footer/customer-wrapper-footer.component';
import { CustomerWrapperHeaderComponent } from '../components/header/customer-wrapper-header.component';
import { interval, Subject } from 'rxjs';
import { filter, map, takeUntil, tap, take } from 'rxjs/operators';
import { LoaderService } from 'shared/src/app/interceptors/loader.service';
import { UserService } from 'shared/src/app/services/user.service';
import { LocalStorage } from 'core/src/app/common/services/local-storage.service';
var CustomerWrapperComponent = /** @class */ (function () {
    function CustomerWrapperComponent(store, _localStorage, loaderService, _route, _userService) {
        this.store = store;
        this._localStorage = _localStorage;
        this.loaderService = loaderService;
        this._route = _route;
        this._userService = _userService;
        this.hasSettings = false;
        this.hasMyAccount = false;
        this.shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(function (h) { return h.test(window.location.host); });
        this.unsubscribe$ = new Subject();
    }
    CustomerWrapperComponent.prototype.ngOnInit = function () {
        var _this = this;
        var paramIsAjaxRequest = this._route.snapshot.queryParamMap.get('isAjaxRequest');
        if (paramIsAjaxRequest) {
            this.isAjaxRequest = JSON.parse(paramIsAjaxRequest);
        }
        this.bookGenerateStatus$ = this.store.pipe(select(fromAuthSelectors.selectUserState.selectBookGenerateState), map(function (state) { return state.syncMessage; }));
        this.contentUpdateStatus$ = this.store.pipe(select(fromAuthSelectors.selectUserState.selectContentUpdateState), map(function (state) { return state.status; }));
        this.store.pipe(select(fromAuthSelectors.selectUserState.selectFieldSyncResponse), takeUntil(this.unsubscribe$)).subscribe(function (res) {
            if (res.syncStatus) {
                _this.store.dispatch(new AuthActions.GetNavigationItems());
            }
        });
        this.store.pipe(select(fromAuthSelectors.selectUserState.navigationItems), filter(function (val) { return val.length > 0; }), tap(function (navigationItems) {
            navigationItems.forEach(function (val) {
                if (val.displayName === 'My Account') {
                    _this.hasMyAccount = true;
                }
                if (val.displayName === 'Settings') {
                    _this.hasSettings = true;
                }
            });
        }), map(function (val) {
            return val.filter(function (v) { return v.displayName !== 'My Account' && v.displayName !== 'Settings'; });
        }), tap(function (navigationItems) {
            var customerNavigation = [];
            navigationItems.map(function (val) {
                if (val.linkURL.startsWith('/app2/')) {
                    customerNavigation.push(new NavigationLink({ label: val.displayName, routerLink: val.linkURL.slice(5), icon: val.icon, canSync: val.canSync, type: val.type }));
                }
                else {
                    customerNavigation.push(new NavigationLink({ label: val.displayName, href: val.linkURL, icon: val.icon, canSync: val.canSync, type: val.type }));
                }
                if (val.displayName === 'Mobile Price book') {
                    _this.store
                        .pipe(select(fromAuthSelectors.selectUserState.user), take(1))
                        .subscribe(function (val) {
                        if (val.FeatureFlags['EnableSqlGenerator']) {
                            _this.pollPeriodically();
                        }
                    });
                }
                if (val.displayName === 'Content Updates') {
                    _this.store
                        .pipe(select(fromAuthSelectors.selectUserState.user), take(1))
                        .subscribe(function (val) {
                        if (val.FeatureFlags['EnableContentUpdates']) {
                            _this.pollContentUpdatePeriodically();
                        }
                    });
                }
            });
            _this.navigation = new Navigation(customerNavigation);
        }), takeUntil(this.unsubscribe$)).subscribe();
        this.store.pipe(select(fromAuthSelectors.selectUserState.user), takeUntil(this.unsubscribe$)).subscribe(function (user) {
            if (user && user.token && user.token !== '') {
                // this.pollDotNetSession(user);
            }
        });
    };
    CustomerWrapperComponent.prototype.fieldEdgePolling = function () {
        var _this = this;
        this.store.dispatch(new AuthActions.FieldEdgeSync());
        setTimeout(function () {
            _this.fieldEdgeSyncInProcess();
        }, 90000);
    };
    CustomerWrapperComponent.prototype.fieldEdgeSyncInProcess = function () {
        var _this = this;
        this.pollingSub = interval(60000).subscribe(function () {
            _this.store.dispatch(new AuthActions.FieldEdgeSyncPolling());
        });
        this.store.pipe(select(fromAuthSelectors.selectUserState.selectFieldSyncPollingResponse)).subscribe(function (val) {
            if (val.syncStatus) {
                _this.store.dispatch(new AuthActions.GetNavigationItems());
                _this._localStorage.set('IsSyncInProcess', false);
                if (_this.pollingSub) {
                    _this.pollingSub.unsubscribe();
                }
            }
        });
    };
    CustomerWrapperComponent.prototype.pollDotNetSession = function (user) {
        var _this = this;
        var source = interval(5000 * 60).pipe(takeUntil(this.unsubscribe$));
        source.subscribe(function () {
            _this._userService.isTokenExpired(user.token).subscribe();
        });
    };
    CustomerWrapperComponent.prototype.generateBook = function () {
        this.store.dispatch(new AuthActions.GenerateBook());
        this.store.dispatch(new AuthActions.SetBookStatus({
            syncStatus: false,
            syncMessage: 'InProgress'
        }));
    };
    CustomerWrapperComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    };
    Object.defineProperty(CustomerWrapperComponent.prototype, "isExpandedState", {
        get: function () {
            return localStorage.getItem('bodyclass') === 'opened-menu' ? 'open' : 'closed';
        },
        enumerable: true,
        configurable: true
    });
    CustomerWrapperComponent.prototype.pollPeriodically = function () {
        var _this = this;
        this.store.dispatch(new AuthActions.BeginBookPolling());
        var source = interval(100000).pipe(takeUntil(this.unsubscribe$));
        source.subscribe(function (_) {
            _this.store.dispatch(new AuthActions.BeginBookPolling());
        });
    };
    CustomerWrapperComponent.prototype.pollContentUpdatePeriodically = function () {
        var _this = this;
        this.store.dispatch(new AuthActions.BeginContentUpdatePolling());
        var source = interval(100000).pipe(takeUntil(this.unsubscribe$));
        source.subscribe(function (_) {
            _this.store.dispatch(new AuthActions.BeginContentUpdatePolling());
        });
    };
    return CustomerWrapperComponent;
}());
export { CustomerWrapperComponent };
