  import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'configuredCurrency',
})
export class ConfiguredCurrencyPipe implements PipeTransform {
    transform(value: number, hideDecimals: boolean): string | null {
        if (hideDecimals) {
            return formatCurrency(
                value,
                'en-US',
                getCurrencySymbol('USD', 'wide'),
                'USD',
                '2.0-0',
              );
        } else {
            return formatCurrency(value, 'en-US', getCurrencySymbol('USD', 'wide'), 'USD');
        }
    }
}
