import * as i0 from "@angular/core";
var CoreConfig = /** @class */ (function () {
    function CoreConfig() {
    }
    CoreConfig.init = function (environment) {
        var result = new CoreConfig();
        result.environment = {
            urls: {
                siteRoot: environment.urls.siteRoot || '',
            },
        };
        return result;
    };
    CoreConfig.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CoreConfig_Factory() { return new CoreConfig(); }, token: CoreConfig, providedIn: "root" });
    return CoreConfig;
}());
export { CoreConfig };
