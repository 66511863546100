<div class="customer-main-div"> 
  <div>
    <pr-customer-wrapper-header *ngIf="!isAjaxRequest" [hasMyAccount]="hasMyAccount" [hasSettings]="hasSettings">
    </pr-customer-wrapper-header>
    <mat-progress-bar *ngIf="(loaderService.isLoading | async) as boolean" color="primary" mode="indeterminate"
      class="top-loader"> </mat-progress-bar>
  </div>
  <div class="customer-container-div">    
    <mat-drawer-container *ngIf="!isAjaxRequest" class="mat-drawer-container" hasBackdrop="false">
      <mat-drawer class="mat-drawer" mode="side" opened>
        <ps-nav *ngIf="navigation && navigation.items.length" (fieldEdgeEvent)="fieldEdgePolling()"
          (generateBookEvent)="generateBook()" type="vertical" [config]="navigation"
          [bookGeneratedStatus]="bookGenerateStatus$ | async" [contentUpdateStatus]="contentUpdateStatus$ | async"></ps-nav>
      </mat-drawer>
      <mat-drawer-content class="main-drawer" [@animateOpenClose]="isExpandedState">
        <main class="main">
          <div class="frame">
            <router-outlet></router-outlet>
          </div>
        </main>
      </mat-drawer-content>
    </mat-drawer-container>
    <router-outlet *ngIf="isAjaxRequest"></router-outlet>
  </div>
</div>
