import { OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import * as AuthActions from '../../store/auth.actions';
import * as fromAuthSelectors from '../../store/auth.selectors';
var ForgotPasswordComponent = /** @class */ (function () {
    function ForgotPasswordComponent(fb, store) {
        this.fb = fb;
        this.store = store;
        this.validEmailPattern = /^([A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?)$/;
    }
    ForgotPasswordComponent.prototype.ngOnInit = function () {
        this.forgotPasswordformGroup = this.fb.group({
            email: new FormControl('', [Validators.required, Validators.pattern(this.validEmailPattern)])
        });
        this.setState();
    };
    ForgotPasswordComponent.prototype.doForgotPassword = function () {
        this.forgotPasswordFailureReason = '';
        // Check if the form are valid, return if they are not
        if (!this.forgotPasswordformGroup.valid) {
            return;
        }
        this.store.dispatch(new AuthActions.TryForgotPassword(this.getForgotPasswordModel()));
    };
    ForgotPasswordComponent.prototype.termsOfService = function () {
        this.store.dispatch(new AuthActions.TermsOfService(false));
    };
    Object.defineProperty(ForgotPasswordComponent.prototype, "isValid", {
        get: function () {
            return this.forgotPasswordformGroup.valid;
        },
        enumerable: true,
        configurable: true
    });
    ForgotPasswordComponent.prototype.getForgotPasswordModel = function () {
        return {
            email: this.forgotPasswordformGroup.controls['email'].value
        };
    };
    ForgotPasswordComponent.prototype.setState = function () {
        this.showTermsOfService$ = this.store.select(fromAuthSelectors.selectTermsOfServiceState.showTermsOfService);
        this.isBusy$ = this.store.select(fromAuthSelectors.selectForgotPasswordState.isLoading);
        this.showForgotPasswordSuccess$ = this.store.select(fromAuthSelectors.selectForgotPasswordState.showForgotPasswordSuccess);
        this.forgotPasswordFailureReason$ = this.store.select(fromAuthSelectors.selectForgotPasswordState.forgotPasswordFailureReason);
    };
    return ForgotPasswordComponent;
}());
export { ForgotPasswordComponent };
