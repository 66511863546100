import { Component, Input, OnInit } from '@angular/core';
import { SubMenu, ToggleSubMenuItem } from '../nav-bar/nav-bar.model';

@Component({
  selector: 'pr-toggle-sub-menu',
  templateUrl: './toggle-sub-menu.component.html',
  styleUrls: ['./toggle-sub-menu.component.scss']
})
export class ToggleSubMenuComponent implements OnInit {
  @Input()
  subMenu: ToggleSubMenuItem;
  constructor() { }

  ngOnInit() {
    
  }

  isToggleSubMenu(subMenuItem: SubMenu) {
    return subMenuItem && subMenuItem.type === "ToggleSubMenuItem";
  }
}
