import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IUserState } from '../../modules/auth/store/auth.state';
import { Observable } from 'rxjs/internal/Observable';

import * as fromApp from '../../store/app.reducers';
import * as AuthActions from '../../modules/auth/store/auth.actions';
import * as fromAuthSelectors from '../../modules/auth/store/auth.selectors';
import { map } from 'rxjs/operators';


@Component({
  selector: 'pr-customer-wrapper-header',
  templateUrl: './customer-wrapper-header.component.html',
  styleUrls: ['./customer-wrapper-header.component.scss']
})
export class CustomerWrapperHeaderComponent implements OnInit {
  userState$: Observable<IUserState>;
  smartPriceUser$: Observable<boolean>;
  isExpanded = false;
  @Input('hasSettings') hasSettings: boolean;
  @Input('hasMyAccount') hasMyAccount: boolean;
  constructor(private store: Store<fromApp.AppState>) { }

  ngOnInit() {
    this.setState();
    this.isExpanded = localStorage.getItem('bodyclass') === 'opened-menu' ? false : true;
  }

  logout() {
    this.store.dispatch(new AuthActions.Logout());
  }

  redirectToAdmin() {
    window.location.href = '/Account/Account/LoginAsAdmin?AdminUserId=1';
  }

  setState() {
    this.userState$ = this.store.select(fromAuthSelectors.selectUserState.user);
    this.smartPriceUser$ = this.userState$.pipe(
      map(user => user.SubscriptionName),
      map(type => type.toLowerCase().includes('smart price'))
    );
  }


  toggleNav() {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      localStorage.setItem('bodyclass', '');
    } else {
      localStorage.setItem('bodyclass', 'opened-menu');
    }
  }
}
