<nav [@animateOpenClose]="isExpandedState">
  <ul class="top-menu">
    <li *ngFor="let item of getNavigation('Upper'); trackBy:trackByIndex"
      [ngClass]="[item.canSync === 2 ? 'SyncOrange' : item.canSync === 1 ? 'SyncGreen': '']" routerLinkActive="active">
      <a *ngIf="item.href" [href]="item.href">
        <mat-icon [svgIcon]="item.icon" style="transform: scale(0.8);"></mat-icon>
        <span class="ps-nav-text">{{item.label}} </span>
        <span *ngIf="item.badge && !isExpanded" class="ps-label {{item.badge}}">{{item.badge}}</span>
      </a>
      <a *ngIf="item.routerLink" [routerLink]="item.routerLink">
        <mat-icon [svgIcon]="item.icon" style="transform: scale(0.8);"></mat-icon>
        <span class="ps-nav-text">{{item.label}} </span>
        <span *ngIf="item.badge && !isExpanded" class="ps-label {{item.badge}}">{{item.badge}}</span>
      </a>
      <span *ngIf="!item.href && !item.routerLink">
        <ps-icon *ngIf="item.icon" [name]="item.icon"></ps-icon>
        <span class="ps-nav-text">{{item.label}}</span>
        <span *ngIf=item.badge class="ps-label {{item.badge}}">{{item.badge}}</span>
      </span>

      <ul class="submenu" *ngIf="item.children && item.children.length">
        <li *ngFor="let child of item.children; trackBy:trackByIndex">
          <a *ngIf="item.href" [href]="child.href">
            <ps-icon *ngIf="child.icon" [name]="child.icon"></ps-icon>
            <span class="ps-nav-text">{{child.label}}</span>
            <span *ngIf=child.badge class="ps-label {{child.badge}}">{{child.badge}}</span>
          </a>
          <a *ngIf="item.routerLink" [routerLink]="child.routerLink">
            <ps-icon *ngIf="child.icon" [name]="child.icon"></ps-icon>
            <span class="ps-nav-text">{{child.label}}</span>
            <span *ngIf=child.badge class="ps-label {{child.badge}}">{{child.badge}}</span>
          </a>
        </li>
      </ul>
    </li>
  </ul>

  <ul class="bottom-menu">
    <li *ngFor="let item of getNavigation('Lower'); trackBy:trackByIndex" [ngClass]="[item.label === 'FieldEdge' ? 
        (item.canSync === 1 ? (!isExpanded ? 'FieldEdgeli Sync': 'FieldEdgeli Sync closed') : 
        item.canSync === 2 ? (!isExpanded ? 'FieldEdgeli Syncinprogress' : 'FieldEdgeli Syncinprogress closed') : 
        (!isExpanded ? 'FieldEdgeli SyncComplete': 'FieldEdgeli SyncComplete closed')): ''
         , activeRoute === item.routerLink ? 'active': '',activeRoute.indexOf(item.routerLink)>=0 && item.label==='Content Updates' ?' active':'']">
      <!-- Link if item is an href instead of a router link -->
      <a *ngIf="item.href && item.label !== 'FieldEdge' && item.label !== 'Mobile Price book' && item.label !== 'Content Updates'"
        [href]="item.href">
        <mat-icon [svgIcon]="item.icon" style="transform: scale(0.8);"></mat-icon>
        <span class="ps-nav-text">{{item.label}} </span>
        <span *ngIf="item.badge && !isExpanded" class="ps-label {{item.badge}}">{{item.badge}}</span>
      </a>
      <!-- Link if item is a router link and not a fieldedge button -->
      <a *ngIf="item.routerLink && item.label !== 'FieldEdge' && item.label !== 'Mobile Price book'&& item.label !== 'Content Updates'"
        [routerLink]="item.routerLink">
        <mat-icon [svgIcon]="item.icon" style="transform: scale(0.8);"></mat-icon>
        <span class="ps-nav-text">{{item.label}} </span>
        <span *ngIf="item.badge && !isExpanded" class="ps-label {{item.badge}}">{{item.badge}}</span>
      </a>
      <!-- Text/Icon for non fieldedge buttons that are not router links -->
      <span
        *ngIf="!item.href && !item.routerLink && item.label !== 'FieldEdge' && item.label !== 'Mobile Price book' && item.label !== 'Content Updates'">
        <ps-icon *ngIf="item.icon" [name]="item.icon"></ps-icon>
        <span class="ps-nav-text">{{item.label}}</span>
        <span *ngIf=item.badge class="ps-label {{item.badge}}">{{item.badge}}</span>
      </span>
      <!-- Special text for Fieldedge Button with no link -->
      <span *ngIf="item.label === 'FieldEdge'" class="caption FieldEdgeIcon" (click)="fieldEdgePolling()">
        <span *ngIf="!isExpanded" class="Innercaption">{{ item.canSync === 1 ? 'Sync': ''}}</span>
      </span>

      <!-- Button for SQLlite File -->
      <a *ngIf="item.label === 'Mobile Price book'" (click)="generateBook()" [ngStyle]="{
        'color': syncColor
      }">
        <mat-icon [svgIcon]="item.icon" style="transform: scale(0.8);" [ngClass]="{
            'spin-sync-nav': bookGeneratedStatus === 'InProgress'
          }"></mat-icon>
        <span class="ps-nav-text">
          Mobile Price Book
        </span>
        <span *ngIf="item.badge && !isExpanded" class="ps-label {{item.badge}}">{{item.badge}}</span>
      </a>

      <a *ngIf="item.label === 'Content Updates'" class="content-update-icon" [ngClass]="contentUpdateStatusClass"
        [routerLink]="item.routerLink" routerLinkActive="content-update-icon-active">
        <mat-icon [svgIcon]="contentUpdateStatusIcon"></mat-icon>
        <span class="ps-nav-text">
          {{item.label}}
        </span>
        <span *ngIf="item.badge && !isExpanded" class="ps-label {{item.badge}}">{{item.badge}}</span>
      </a>
    </li>
  </ul>
</nav>