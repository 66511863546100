import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromApp from '../../../../store/app.reducers';
import * as AuthActions from '../../store/auth.actions';
import * as fromAuthSelectors from '../../store/auth.selectors';
@Component({
  selector: 'pr-terms-of-service',
  templateUrl: './terms-of-service.component.html'
})
export class TermsOfServiceComponent implements OnInit {
  loaded: boolean;
  enableAction$: Observable<boolean>;

  constructor(
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit() {
    this.enableAction$ = this.store.select(fromAuthSelectors.selectTermsOfServiceState.enableAction);
  }

  loadedEvent($event) {
    this.loaded = $event;
  }

  close(action: boolean) {
    if (action) {
      this.store.dispatch(new AuthActions.TermsOfServiceAccept());
    } else {
      this.store.dispatch(new AuthActions.TermsOfServiceReject());
    }
  }
}
