/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hold-alert.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./hold-alert.component";
import * as i4 from "@ngrx/store";
var styles_HoldAlertComponent = [i0.styles];
var RenderType_HoldAlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HoldAlertComponent, data: {} });
export { RenderType_HoldAlertComponent as RenderType_HoldAlertComponent };
function View_HoldAlertComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "no-payment-alert"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" Your account is past due. You will be locked out in ", " days."])), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["href", "/Company/Company/Dashboard"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Click here to update your subscription."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.daysToFailure$)); _ck(_v, 1, 0, currVal_0); }); }
export function View_HoldAlertComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_HoldAlertComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.subscriptionStatus$)) === "InGracePeriod"); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HoldAlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pr-hold-alert", [], null, null, null, View_HoldAlertComponent_0, RenderType_HoldAlertComponent)), i1.ɵdid(1, 114688, null, 0, i3.HoldAlertComponent, [i4.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HoldAlertComponentNgFactory = i1.ɵccf("pr-hold-alert", i3.HoldAlertComponent, View_HoldAlertComponent_Host_0, {}, {}, []);
export { HoldAlertComponentNgFactory as HoldAlertComponentNgFactory };
