import { ContentManagementApi } from 'frb-api/src/apis/content-management-api';
import { AuthApi } from 'frb-api/src/pr-api';
import { map } from 'rxjs/internal/operators/map';
import * as i0 from "@angular/core";
import * as i1 from "../../../../frb-api/src/apis/auth-api";
import * as i2 from "../../../../frb-api/src/apis/content-management-api";
var UserService = /** @class */ (function () {
    function UserService(_authApi, _contentManagementApi) {
        this._authApi = _authApi;
        this._contentManagementApi = _contentManagementApi;
    }
    UserService.prototype.login = function (loginModel) {
        var loginRequest = {
            username: loginModel.userName,
            password: loginModel.password,
        };
        return this._authApi.loginUser(loginRequest);
    };
    UserService.prototype.logout = function () {
        return this._authApi.logoutUser();
    };
    UserService.prototype.isTokenExpired = function (token) {
        return this._authApi.isTokenExpired(token).pipe(map(function () {
            return true;
        }));
    };
    UserService.prototype.getLoginDetails = function (loginModel) {
        var loginRequest = {
            username: loginModel.userName,
            password: loginModel.password,
            newpassword: loginModel.password,
        };
        return this._authApi.getLoginDetails(loginRequest);
    };
    UserService.prototype.forgotPassword = function (email) {
        var forgotPasswordRequest = {
            EmailAddress: email,
            isFromAngular: true
        };
        return this._authApi.forgotPassword(forgotPasswordRequest);
    };
    UserService.prototype.resetPassword = function (resetPasswordRequest) {
        return this._authApi.resetPassword(resetPasswordRequest);
    };
    UserService.prototype.verifyResetPasswordToken = function (resetPasswordToken) {
        return this._authApi.verifyResetPasswordToken(resetPasswordToken);
    };
    UserService.prototype.getNavigationItems = function () {
        return this._authApi.getNavigationItems();
    };
    UserService.prototype.getcompanyfeatures = function () {
        return this._authApi.getcompanyfeatures();
    };
    UserService.prototype.fieldEdgeSync = function () {
        return this._authApi.fieldEdgeSync();
    };
    UserService.prototype.fieldEdgeSyncPolling = function () {
        return this._authApi.fieldEdgeSyncPolling();
    };
    UserService.prototype.getMaskedLabels = function () {
        return this._authApi.getMaskedPriceColumnNames();
    };
    UserService.prototype.generateBook = function () {
        return this._authApi.generateBook();
    };
    UserService.prototype.getBookStatus = function () {
        return this._authApi.getBookStatus();
    };
    UserService.prototype.getContentUpdateStatus = function () {
        return this._contentManagementApi.getContentUpdateInfo();
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.AuthApi), i0.ɵɵinject(i2.ContentManagementApi)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
