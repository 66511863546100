import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UnauthenticatedApiClient } from './../services/api-client';
import { IBusinessInformation, IBusinessInformationVendor, IPartMarkup } from './../../../frb-app/src/app/modules/business-information/store/business-information.models';
import { PatchRequest } from 'frb-app/src/app/modules/tasks/store/task.model';
import { HttpResponse } from './../models/http-response';
import { Observable } from 'rxjs';

@Injectable()
export class BusinessInformationAPI {

  constructor(private readonly _apiClient: UnauthenticatedApiClient) {
  }

  public getVendors(): Observable<HttpResponse<any>> {

    const params = new HttpParams().set('PageNo', '1').set('PageSize', '-1');


    return this._apiClient.get<any>(`/api/v2/Vendors`, {params: params});
  }

  public createVendor(vendor: IBusinessInformationVendor): Observable<HttpResponse<any>> {

    return this._apiClient.post<IBusinessInformationVendor>(`/api/v2/Vendor`, vendor);
  }

  public deleteVendor(id: number) {
    return this._apiClient.delete(`/api/v2/Vendor/${id}`);
  }

  public updateVendor(vendor: IBusinessInformationVendor) {
    const params = new HttpParams().set('Id', vendor.id.toString());
    return this._apiClient.put<IBusinessInformationVendor>(`/api/v2/Vendor`, vendor, {params} );
  }

  public getVendorNextPage(pageNo: number, search?: string): Observable<HttpResponse<any>> {

    const params = new HttpParams().set('PageNo', pageNo.toString()).set('PageSize', '15');

    if (search) {
      params.set('SearchKeyword', search);
    }
    return this._apiClient.get<any>(`/api/v2/Vendors`, {params: params});
  }

  public searchVendor(search: string): Observable<HttpResponse<any>> {

    let params;
    if (search !== '') {
      params = new HttpParams().set('PageSize', '-1').set('SearchKeyword', search);
    } else {
      params = new HttpParams().set('PageSize', '-1');
    }


    return this._apiClient.get<any>(`/api/v2/Vendors`, {params: params});
  }


  getBusinessTypeList() {
    return this._apiClient.get<any>(`/api/v2/TaskCategories/0`);
  }

  getBusinessInformation(id: number) {
    return this._apiClient.get<any>(`/api/v2/businessinformation/${id}`);
  }

  getRefrigerantSettings() {
    return this._apiClient.get<any>('/api/v2/settings/refrigerantsettings');
  }

  updateRefrigerantSettings(settings: {refrigerantType: string, isPriceAdditionallbs: boolean}) {
    return this._apiClient.post<any>('/api/v2/settings/refrigerantsettings', settings);
  }

  getRefrigerants() {
    return this._apiClient.get<any>('/api/v2/Refrigerants');
  }

  addRefrigerant(body: {taskId: number}) {
    return this._apiClient.post<any>('/api/v2/Refrigerants', body);
  }

  deleteRefrigerant(id: number) {
    return this._apiClient.delete(`/api/v2/Refrigerants/${id}`);
  }

  patchRefrigerant(patch: PatchRequest[], id: number) {
    return this._apiClient.patch(`/api/v2/Refrigerants/${id}`, patch);
  }

  getLiquidDriers() {
    return this._apiClient.get<any>('/api/v2/LiquidDriers');
  }

  addLiquidDrier(body: {taskId: number}) {
    return this._apiClient.post<any>('/api/v2/LiquidDriers', body);
  }

  deleteLiquidDrier(id: number) {
    return this._apiClient.delete(`/api/v2/LiquidDriers/${id}`);
  }

  patchLiquidDrier(patch: PatchRequest[], id: number) {
    return this._apiClient.patch(`/api/v2/LiquidDriers/${id}`, patch);
  }

  getSuctionDrier() {
    return this._apiClient.get<any>('/api/v2/SuctionDriers');
  }

  addSuctionDrier(body: {taskId: number}) {
    return this._apiClient.post<any>('/api/v2/SuctionDrier', body);
  }


  patchSuctionDrier(patch: PatchRequest[], id: number) {
    return this._apiClient.patch(`/api/v2/SuctionDrier/${id}`, patch);
  }

  deleteSuctionDrier(id: number) {
    return this._apiClient.delete(`/api/v2/SuctionDrier/${id}`);
  }

  getPartMarkups() {
    return this._apiClient.get<any>('/api/v2/partsmarkup');
  }

  getIndustryDefault() {
    return this._apiClient.get<any>('/api/v2/defaultmarkups');
  }

  saveMarkups(markups: IPartMarkup[]) {
    const body = {
      partMarkups: markups
    };
    return this._apiClient.post<any>('/api/v2/SaveMarkup', body);
  }

  saveBusinessInformation(businessInformation: IBusinessInformation) {
    const body = {
      businessTypeID: businessInformation.businessTypeID,
      name: businessInformation.name,
      breakEvenBillOutLaborCost: businessInformation.breakEvenBillOutLaborCost,
      breakEvenBillOutAssistantLaborCost: businessInformation.breakEvenBillOutAssistantLaborCost,
      breakEvenBillOutMiscOrTosCost: businessInformation.breakEvenBillOutMiscOrTosCost,
      breakEvenBillOutLaborRate: businessInformation.breakEvenBillOutLaborRate,
      breakEvenBillOutAssistantLaborRate: businessInformation.breakEvenBillOutAssistantLaborRate,
      breakEvenBillOutMiscOrTosRate: businessInformation.breakEvenBillOutMiscOrTosRate,
      laborType: businessInformation.laborType,
      laborPercentage: businessInformation.laborPercentage,
      partsType: businessInformation.partsType,
      partsPercentage: businessInformation.partsPercentage,
      invoiceType: businessInformation.invoiceType,
      invoicePercentage: businessInformation.invoicePercentage,
      firstLabor: businessInformation.firstLabor,
      secondLabor: businessInformation.secondLabor,
      firstMaterial: businessInformation.firstMaterial,
      secondMaterial: businessInformation.secondMaterial,
      miscSettingRoundUpRates: businessInformation.miscSettingRoundUpRates,
      miscSettingMinimumTime: businessInformation.miscSettingMinimumTime,
      miscSettingMinimumTimeForAddon: businessInformation.miscSettingMinimumTimeForAddon,
      miscSettingHideDecimals: businessInformation.miscSettingHideDecimals
    };

    return this._apiClient.post<any>(`/api/v2/businesstypes/${businessInformation.id}/actions/saveandrecalculate`, body);
  }


  copyBusinessInformation(id: number) {
    return this._apiClient.post<any>(`/api/v2/businessinformation/${id}/copybusinessinformation`, {});
  }

  setTaskTimeToWrenchTime(id: number) {
    return this._apiClient.get<any>(`/api/v2/businessinformation/${id}/actions/settasktimetowrenchtime`);
  }

  getBrands() {
    const params = new HttpParams().set('PageSize', '-1');
    return this._apiClient.get<any>(`/api/v2/Brand`, {params});
  }

  addBrand(key: string, code: string) {
    return this._apiClient.post<any>('/api/v2/Brand', {key, code});
  }

  updateBrand(updatedBrand: any) {
    return this._apiClient.put<any>(`/api/v2/Brand/${updatedBrand.id}`, updatedBrand);
  }

  deleteBrand(id: number) {
    return this._apiClient.delete<any>(`/api/v2/Brand/${id}`);
  }

  getManufacturers() {
    const params = new HttpParams().set('PageSize', '-1');
    return this._apiClient.get<any>(`/api/v2/manufacturer`, {params});
  }

  addManufacturer(key: string, code: string) {
    return this._apiClient.post<any>('/api/v2/manufacturer', {key, code});
  }

  updateManufacturer(updatedManufacturer: any) {
    return this._apiClient.put<any>(`/api/v2/manufacturer/${updatedManufacturer.id}`, updatedManufacturer);
  }

  deleteManufacturer(id: number) {
    return this._apiClient.delete<any>(`/api/v2/manufacturer/${id}`);
  }

}
