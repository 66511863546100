import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthWrapperComponent } from './pages/auth-wrapper.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { LoginResolver } from './resolver/loginResolver';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ResetTokenGuard } from '../../gaurds/reset-token-gaurd.service';

const routes: Routes = [
  {
    path: '',
    component: AuthWrapperComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
        resolve: { data: LoginResolver }
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'reset-password/:resetPasswordToken',
        component: ResetPasswordComponent,
        canActivate : [ResetTokenGuard]
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
