import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as AuthActions from './auth.actions';
import { IUserState } from './auth.state';
import { LocalStorage } from 'core/src/app/common/services/local-storage.service';
import { ForgotPasswordModel } from '../components/forgot-password/forgot-password.component';
import { ResetPasswordRequest } from 'frb-api/src/models/reset-password-request';
import { ContentService } from 'frb-app/src/app/services/content.service';
import { LoginModel } from '../components/login/login.component';
import { NgxPendoService } from 'ngx-pendo';
import { defer, Observable, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, switchMap } from 'rxjs/operators';
import { CustomSnackBarComponent } from 'shared/src/app/components/custom-snackbar/custom-snackbar.component';
import { UserService } from 'shared/src/app/services/user.service';
import { SAMPLE_MASK } from 'shared/src/app/components/task-card/task-sample-data';


@Injectable()
export class AuthEffects {
  @Effect()
  init$ = defer((): Observable<AuthActions.SigninSuccess | AuthActions.LogoutSuccess> => {
    let userData: any = this._localStorage.get('ACTIVE_USER') as string;
    userData = JSON.parse(userData);
    if (userData && userData.userData && userData.userData.Company) {
      this._ngxPendoService.initialize({
        id: userData.username,
        user_id: userData.userData.Company.CustomerNumber,
        Customer_Number: userData.userData.Company.CustomerNumber,
        Phone: userData.userData.Company.Phone,
        company_id: userData.userData.Company.CustomerNumber,
        _RoleName: userData.RoleName,
        _EmailAddress: userData.username,
        _viewAllQuotes: userData.ViewAllQuotes.toString(),
        _canShowHideTasks: userData.CanShowHideTasks.toString(),
        _canDeleteQuotes: userData.CanDeleteQuotes.toString(),
        company: {
          id: userData.userData.Company.CustomerNumber,
          name: userData.userData.CompanyName
        }.toString()
      }, {
          id: userData.userData.Company.CompanyId.toString(),
          ...userData.FeatureFlags,
          _Subscription: userData.userData.SubscriptionName,
          _SubscriptionType: userData.SubscriptionType,
          _PartnerType: userData.PartnerType,
          _MRR: userData.Mrr ? userData.Mrr.toString() : '',
          _Timezone: userData.TimeZone,
          _DemoAccount: userData.DemoAccount ? userData.DemoAccount.toString() : '',
          _CNumber: userData.userData.Company.CustomerNumber,
          _State: userData.State,
          _ZipCode: userData.ZipCode,
          _BusinessTypeList: userData.BusinessTypesList,
          _InvoiceDate: userData.invoiceDate ? userData.invoiceDate.toString() : '',
          _SubscriptionPurchaseDate: userData.SubscriptionPurchaseDate ? userData.SubscriptionPurchaseDate.toString() : '',
          _BillingDate: userData.BillingDate ? userData.BillingDate.toString() : '',
          _SubscriptionDate: userData.SubscriptionDate,
          _UsersAllowed: userData.UsersAllowed ? userData.UsersAllowed.toString() : ''
        });

      return of(new AuthActions.SigninSuccess(userData));
    } else {
      // if the user isn't there return a logout action
      return of(new AuthActions.LogoutSuccess());
    }
  });
  @Effect()
  authSignup = this.actions$
    .pipe(
      ofType(AuthActions.TRY_SIGNUP));
  @Effect()
  authGetLoginDetails = this.actions$
    .pipe(
      ofType(AuthActions.TRY_GET_LOGIN_DETAILS),
      map((action: AuthActions.TryGetLoginDetails) => {
        return action.payload;
      }),
      exhaustMap((authData: LoginModel) => {
        const activeAdminUser = this._localStorage.get('ACTIVEAdmin_USER') as string;
        if (activeAdminUser) {
          this._localStorage.remove('ACTIVEAdmin_USER');
        }
        return this._userService.getLoginDetails(authData)
          .pipe(
            map((response) => {
              if (response && response.status) {
                if (response.termsaccepted) {
                  return {
                    type: AuthActions.TRY_SIGNIN,
                    payload: authData
                  };
                } else {
                  return {
                    type: AuthActions.TERMS_OF_SERVICES,
                    payload: true
                  };
                }
              } else {
                return {
                  type: AuthActions.GET_LOGIN_DETAILS_FAIL,
                  payload: response.message,
                };
              }
            })
          );
      })
    );

  @Effect()
  authSignin = this.actions$
    .pipe(
      ofType(AuthActions.TRY_SIGNIN),
      map((action: AuthActions.TrySignin) => {
        return action.payload;
      }),
      exhaustMap((loginModel: LoginModel) => {
        const activeAdminUser = this._localStorage.get('ACTIVEAdmin_USER') as string;
        if (activeAdminUser) {
          this._localStorage.remove('ACTIVEAdmin_USER');
        }
        return this._userService.login(loginModel).pipe(
          map((response) => {
            // Redirect page on response base
            if (response && response.status && response.data && response.data.redirect) {
              const user: IUserState = {
                id: response.data.username,
                email: response.data.email,
                username: loginModel.userName,
                role: response.data.role,
                token: response.data.token,
                rememberMe: loginModel.rememberMe,
                url: response.data.url,
                redirect: response.data.redirect,
                isTokenValid: true,
                isCheckingToken: response.data.isCheckingToken,
                companyName: response.data.userData.CompanyName,
                companyLogo: response.data.userData.CompanyLogo,
                userTypeId: response.data.userData.UserTypeId,
                isSinglePartDB: response.data.isSinglePartDB,
                TaskNumberAutoIncrement: response.data.TaskNumberAutoIncrement,
                SubscriptionName: response.data.userData.SubscriptionName,
                SubscriptionStatus: response.data.userData.SubscriptionStatusString,
                DaysLeft: response.data.userData.DaysLeft,
                FeatureFlags: response.data.FeatureFlags,
                BusinessTypes: response.data.BusinessTypes,
                SubscriptionType: response.data.SubscriptionType,
                DemoAccount: response.data.DemoAccount,
                TimeZone: response.data.TimeZone,
                PartnerType: response.data.PartnerType,
                RoleName: response.data.RoleName,
                Mrr: response.data.Mrr,
                ViewAllQuotes: response.data.ViewAllQuotes,
                CanDeleteQuotes: response.data.CanDeleteQuotes,
                CanShowHideTasks: response.data.CanShowHideTasks,
                State: response.data.State,
                ZipCode: response.data.ZipCode,
                userData: response.data.userData,
                BusinessTypesList: response.data.BusinessTypesList,
                InvoiceDate: response.data.InvoiceDate,
                SubscriptionPurchaseDate: response.data.SubscriptionPurchaseDate,
                BillingDate: response.data.BillingDate,
                SubscriptionDate: response.data.SubscriptionDate,
                UsersAllowed: response.data.UsersAllowed
              };
              this._localStorage.set('ACTIVE_USER', user);

              this._ngxPendoService.initialize({
                id: response.data.username,
                user_id: response.data.userData.Company.CustomerNumber,
                Customer_Number: response.data.userData.Company.CustomerNumber,
                Phone: response.data.userData.Company.Phone,
                company_id: response.data.userData.Company.CustomerNumber,
                company: {
                  id: response.data.userData.Company.CustomerNumber,
                  name: response.data.userData.CompanyName
                }.toString(),
                _RoleName: response.data.RoleName,
                _EmailAddress: response.data.username,
                _viewAllQuotes: response.data.ViewAllQuotes.toString(),
                _canShowHideTasks: response.data.CanShowHideTasks.toString(),
                _canDeleteQuotes: response.data.CanDeleteQuotes.toString(),
              }, {
                  id: response.data.userData.Company.CompanyId.toString(),
                  ...response.data.FeatureFlags,
                  _Subscription: response.data.userData.SubscriptionName,
                  _SubscriptionType: response.data.SubscriptionType,
                  _PartnerType: response.data.PartnerType,
                  _MRR: response.data.Mrr ? response.data.Mrr.toString() : '',
                  _Timezone: response.data.TimeZone,
                  _DemoAccount: response.data.DemoAccount ? response.data.DemoAccount.toString() : '',
                  _CNumber: response.data.userData.Company.CustomerNumber,
                  _State: response.data.State,
                  _ZipCode: response.data.ZipCode,
                  _BusinessTypeList: response.data.BusinessTypesList,
                  _InvoiceDate: response.data.InvoiceDate ? response.data.InvoiceDate.toString() : '',
                  _SubscriptionPurchaseDate: response.data.SubscriptionPurchaseDate ? response.data.SubscriptionPurchaseDate.toString() : '',
                  _BillingDate: response.data.BillingDate ? response.data.BillingDate.toString() : '',
                  _SubscriptionDate: response.data.SubscriptionDate,
                  _UsersAllowed: response.data.UsersAllowed ? response.data.UsersAllowed.toString() : ''
                });

              window.location.href = response.data.url;
              return {
                type: AuthActions.SIGNIN_SUCCESS,
                payload: user
              };
            } else {
              return {
                type: AuthActions.SIGNIN_FAIL,
                payload: response.message
              };
            }
          })
        );
      })
    );

  // LOGOUT
  @Effect()
  logout = this.actions$.pipe(
    ofType(AuthActions.LOGOUT),
    switchMap(() => {
      return this._userService.logout();
    }),
    map(res => {
      if (res.status) {
        let userData: any = this._localStorage.get('ACTIVE_USER') as string;
        userData = JSON.parse(userData);
        userData.token = '';
        userData.SubscriptionStatus = 'LoggedOut';
        userData.username = undefined;
        this._localStorage.set('ACTIVE_USER', userData);
        this.router.navigate(['login']);
        return new AuthActions.LogoutSuccess();
      }
      return new AuthActions.LogoutFailure();
    }),
    catchError(() => {
      return [{
        type: AuthActions.LOGOUT_FAILURE
      }];
    })
  );
  // @Effect({ dispatch: false })
  // logout = this.actions$
  //   .pipe(
  //     ofType(AuthActions.LOGOUT),
  //     exhaustMap(() => {
  //       return this._userService.logout();
  //     }),
  //     tap(() => {
  //       let userData: any = this._localStorage.get('ACTIVE_USER') as string;
  //       userData =  JSON.parse(userData);
  //       userData.token = '';
  //       this._localStorage.set('ACTIVE_USER', userData);
  //       this.router.navigate(['login']);
  //     }));

  // FORGOT PASSWORD
  @Effect()
  forgotPassword = this.actions$
    .pipe(
      ofType(AuthActions.TRY_FORGOT_PASSWORD),
      map((action: AuthActions.TryForgotPassword) => {
        return action.payload;
      }),
      exhaustMap((authData: ForgotPasswordModel) => {
        return this._userService.forgotPassword(authData.email);
      }),
      switchMap((res) => {
        if (res.status) {
          return [
            {
              type: AuthActions.FORGOT_PASSWORD_SUCCESS,
            }
          ];
        }
        return [{
          type: AuthActions.FORGOT_PASSWORD_FAILURE,
          payload: res.message,
        }];
      }),
      catchError((error: string) => {
        return [{
          type: AuthActions.FORGOT_PASSWORD_FAILURE,
          payload: error,
        }];
      }),
    );

  // Reset Password
  @Effect()
  resetPassword = this.actions$
    .pipe(
      ofType(AuthActions.RESET_PASSWORD),
      map((action: AuthActions.ResetPassword) => {
        return action.payload;
      }),
      exhaustMap((authData: ResetPasswordRequest) => {
        return this._userService.resetPassword(authData);
      }),
      switchMap((res) => {
        if (res.status) {
          this.router.navigate(['login']);
          this.getMessage(res.message);
          return [
            {
              type: AuthActions.RESET_PASSWORD_SUCCESS
            }
          ];
        }
        return [{
          type: AuthActions.RESET_PASSWORD_FAILURE,
          payload: res.message,
        }];
      }),
      catchError((error: string) => {
        return [{
          type: AuthActions.RESET_PASSWORD_FAILURE,
          payload: error,
        }];
      })
    );

  // TERMS OF SERVICES CONTENT
  @Effect()
  temsOfServicesContent = this.actions$
    .pipe(
      ofType(AuthActions.TERMS_OF_SERVICES_CONTENT),
      map((action: AuthActions.TermsOfServiceContent) => {
        return action;
      }),
      switchMap(() => {
        return this._contentService.getTermsAndServiceHtml();
      }),
      switchMap((res) => {
        if (res && res.data) {
          return [{
            type: AuthActions.TERMS_OF_SERVICES_CONTENT_SUCCESS,
            payload: {
              htmlContentInput: res.data.content,
              loaded: true,
            }
          }];
        }
        return [{
          type: AuthActions.TERMS_OF_SERVICES_CONTENT_FAIL
        }];
      })
    );

  @Effect()
  IsTokenExpired = this.actions$.pipe(
    ofType(AuthActions.IS_TOKEN_EXPIRED),
    map((action: AuthActions.IsTokenExpired) => {
      return action.payload;
    }),
    exhaustMap((user) => {
      return this._userService.isTokenExpired(user.token).pipe(
        map((isAuth) => {
          return {
            isAuth: isAuth,
            user: user
          };
        })
      );
    }),
    switchMap((res) => {
      if (res.isAuth) {
        window.location.href = res.user.url;
        return [{
          type: AuthActions.IS_TOKEN_EXPIRED_SUCCESS,
          payload: res.user
        }];
      }
      return [{
        type: AuthActions.IS_TOKEN_EXPIRED_FAIL,
        payload: res.user
      }];
    }),
    catchError(err => {
      return [{
        type: AuthActions.IS_TOKEN_EXPIRED_FAIL,
        payload: err
      }];
    })
  );

  @Effect()
  VerifyResetPasswordToken = this.actions$.pipe(
    ofType(AuthActions.VERIFY_RESET_PASSWORD_TOKEN),
    map((action: AuthActions.VerifyResetPasswordToken) => {
      return action.payload;
    }),
    exhaustMap((resetPasswordToken) => {
      return this._userService.verifyResetPasswordToken(resetPasswordToken);
    }),
    switchMap((res) => {
      if (res.status) {
        return [{
          type: AuthActions.VERIFY_RESET_PASSWORD_TOKEN_SUCCESS
        }];
      }
      this.getMessage(res.message);
      this.router.navigate(['login']);
      return [{
        type: AuthActions.VERIFY_RESET_PASSWORD_TOKEN_FAIL
      }];
    })
  );

  @Effect()
  GetNavigationItems = this.actions$.pipe(
    ofType(AuthActions.GET_NAVIGATION_ITEMS),
    switchMap(() => {
      return this._userService.getNavigationItems();
    }),
    map((res) => {
      if (res.status) {
        return new AuthActions.GetNavigationItemsSuccess(res.data.menues);
      }
      return [{
        type: AuthActions.GET_NAVIGATION_ITEMS_FAIL
      }];
    })
  );

  @Effect()
  GetCompanyFeatures = this.actions$.pipe(
    ofType(AuthActions.GET_COMPANY_FEATURES),
    switchMap(() => {
      return this._userService.getcompanyfeatures();
    }),
    map((res) => {
      if (res.status) {
        return new AuthActions.GetCompanyFeaturesSuccess(res.data.screenList);
      }
      return [{
        type: AuthActions.GET_COMPANY_FEATURES_FAIL
      }];
    })
  );

  @Effect()
  getMaskedLabels = this.actions$.pipe(
    ofType(AuthActions.GET_MASKED_LABELS),
    switchMap(() => {
      return this._userService.getMaskedLabels();
    }),
    map((res) => {
      if (res.status) {
        return res.data.length ? new AuthActions.GetMaskedLabelsSuccess(res.data[0]) : new AuthActions.GetMaskedLabelsSuccess(SAMPLE_MASK);
      }
      return [new AuthActions.GetMaskedLabelsFail(SAMPLE_MASK)];
    })
  );

  @Effect()
  FieldEdgeSync = this.actions$.pipe(
    ofType(AuthActions.FIELD_EDGE_SYNC),
    switchMap(() => {
      return this._userService.fieldEdgeSync();
    }),
    map((res) => {
      this.getMessage(res.message);
      if (res.status) {
        this._localStorage.set('IsSyncInProcess', true);
        return new AuthActions.FieldEdgeSyncSuccess(res.message);
      }
      return new AuthActions.FieldEdgeSyncFail(res.message);
    })
  );

  @Effect()
  generateBook$ = this.actions$.pipe(
    ofType<AuthActions.GenerateBook>(AuthActions.GENERATE_BOOK),
    switchMap(() => this._userService.generateBook().pipe(
      map(_ => {
        this.snackbar.openFromComponent(CustomSnackBarComponent, { horizontalPosition: 'center', verticalPosition: 'top', duration: 3000, data: { message: 'Your book is being generated, this will take a moment.', status: true } });
        return new AuthActions.SetBookStatus({
          syncStatus: false,
          syncMessage: 'InProgress'
        });
      }),
      catchError(_ => {
        return of(new AuthActions.SetBookStatus({
          syncStatus: true,
          syncMessage: 'FailedException'
        }));
      })
    ))
  );

  @Effect()
  pollBookStatus$ = this.actions$.pipe(
    ofType<AuthActions.BeginBookPolling>(AuthActions.BEGIN_BOOK_POLLING),
    concatMap(_ => this._userService.getBookStatus().pipe(
      map(res => {
        return new AuthActions.SetBookStatus({
          syncStatus: res.data !== 'InProgress',
          syncMessage: res.data
        });
      })
    ))
  );
  @Effect()
  pollContentUpdateStatus$ = this.actions$.pipe(
    ofType<AuthActions.BeginContentUpdatePolling>(AuthActions.BEGIN_CONTENTUPDATE_POLLING),
    concatMap(_ => this._userService.getContentUpdateStatus().pipe(
      map(res => {
        return new AuthActions.SetContentUpdateStatus({ status: res.data.productStatus });
      })
    ))
  );
  @Effect()
  FieldEdgeSyncPolling = this.actions$.pipe(
    ofType(AuthActions.FIELD_EDGE_SYNC_POLLING),
    switchMap(() => {
      return this._userService.fieldEdgeSyncPolling();
    }),
    map((res) => {
      if (!res.status) {
        return new AuthActions.FieldEdgeSyncPollingSuccess(res.message);
      }
      return new AuthActions.FieldEdgeSyncPollingFail(res.message);
    })
  );

  @Effect()
  GlobalError = this.actions$.pipe(
    ofType(AuthActions.ADD_GLOBAL_ERROR),
    map((action: AuthActions.AddGlobalError) => {
      return action.payload;
    }),
    switchMap((error) => {
      if (error.status === 412 || error.status === 401) {
        let userData: any = this._localStorage.get('ACTIVE_USER') as string;
        if (userData) {
          userData = JSON.parse(userData);
          userData.token = '';
          this._localStorage.set('ACTIVE_USER', userData);
        }
        this.router.navigate(['login']);
        return [
          {
            type: AuthActions.LOGOUT_SUCCESS
          }
        ];
      } else if (error.status === 500) {
        this.snackbar.openFromComponent(CustomSnackBarComponent, { horizontalPosition: 'center', verticalPosition: 'top', duration: 3000, data: { message: 'Something went wrong, please try again', status: false } });
      } else if (error.status === 400) {
        this.snackbar.openFromComponent(CustomSnackBarComponent, { horizontalPosition: 'center', verticalPosition: 'top', duration: 3000, data: { message: error.error.message, status: false } });
      } else if (error.status === 405) {
        this.snackbar.openFromComponent(CustomSnackBarComponent, { horizontalPosition: 'center', verticalPosition: 'top', duration: 3000, data: { message: error.error.message, status: false } });
      }

      return [
        { type: 'NO_ACTION' }
      ];
    })
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private readonly _userService: UserService,
    private readonly snackbar: MatSnackBar,
    private readonly _localStorage: LocalStorage,
    private readonly _contentService: ContentService,
    private readonly _ngxPendoService: NgxPendoService) {
  }

  private getMessage(message: string) {
    this.snackbar.openFromComponent(CustomSnackBarComponent, { horizontalPosition: 'center', verticalPosition: 'top', duration: 3000, data: { message: message, status: true } });
  }
}
