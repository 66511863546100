import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/icon";
import * as i2 from "@angular/platform-browser";
var IconRegisterService = /** @class */ (function () {
    function IconRegisterService(matIconRegistry, domSanitizer) {
        this.matIconRegistry = matIconRegistry;
        this.domSanitizer = domSanitizer;
    }
    IconRegisterService.prototype.registerIcons = function () {
        this.matIconRegistry.addSvgIcon('facebook', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/facebook.svg'));
        this.matIconRegistry.addSvgIcon('linkedin', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/linkedin.svg'));
        this.matIconRegistry.addSvgIcon('smart_price', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/smart-price.svg'));
        this.matIconRegistry.addSvgIcon('BusinessInformationxzIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/business-information.svg'));
        this.matIconRegistry.addSvgIcon('BookIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/manage-book.svg'));
        this.matIconRegistry.addSvgIcon('TaskIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/tasks.svg'));
        this.matIconRegistry.addSvgIcon('PartsIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/parts.svg'));
        this.matIconRegistry.addSvgIcon('OptionBuildeIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/option-boards.svg'));
        this.matIconRegistry.addSvgIcon('UsersIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/customers-icon.svg'));
        this.matIconRegistry.addSvgIcon('InvoicesIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/work-orders.svg'));
        this.matIconRegistry.addSvgIcon('ReportsIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/exports-imports.svg'));
        this.matIconRegistry.addSvgIcon('sqlLiteFileGenerator', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/sync-icon.svg'));
        this.matIconRegistry.addSvgIcon('intercom', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/intercom.svg'));
        this.matIconRegistry.addSvgIcon('managedlists', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/managedlists.svg'));
        this.matIconRegistry.addSvgIcon('pendoguide', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/pendoguide.svg'));
        this.matIconRegistry.addSvgIcon('partsmarkup', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/partsmarkup.svg'));
        this.matIconRegistry.addSvgIcon('ratemanagement', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ratemanagement.svg'));
        this.matIconRegistry.addSvgIcon('refrigerant', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/refrigerant.svg'));
        this.matIconRegistry.addSvgIcon('vendors', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/Vendors.svg'));
        this.matIconRegistry.addSvgIcon('ContentUpdateIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/content-updates.svg'));
        this.matIconRegistry.addSvgIcon('ContentUpdateCompletedIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/content-update-completed.svg'));
        this.matIconRegistry.addSvgIcon('group1194', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/Group 1194.svg'));
        this.matIconRegistry.addSvgIcon('businessInfoIcon-new', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_domain_24px.svg'));
        this.matIconRegistry.addSvgIcon('manageBookIcon-new', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_chrome_reader_mode_24px.svg'));
        this.matIconRegistry.addSvgIcon('schedulingIcon-new', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_today_24px.svg'));
        this.matIconRegistry.addSvgIcon('workOrderIcon-new', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_assignment_24px.svg'));
        this.matIconRegistry.addSvgIcon('customerIcon-new', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_person_24px.svg'));
        this.matIconRegistry.addSvgIcon('importExportIcon-new', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_folder_24px.svg'));
        this.matIconRegistry.addSvgIcon('generateSQLIcon-new', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_sync_24px.svg'));
        this.matIconRegistry.addSvgIcon('logOutIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_logout.svg'));
        this.matIconRegistry.addSvgIcon('accountIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_account_circle_24px.svg'));
        this.matIconRegistry.addSvgIcon('contentUpdateDone', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_cloud_done_24px.svg'));
        this.matIconRegistry.addSvgIcon('contentUpdate', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_cloud.svg'));
        this.matIconRegistry.addSvgIcon('integrate-logo-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/integrate-logo-icon.svg'));
        this.matIconRegistry.addSvgIcon('smart-price-logo-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/smart-price-logo-icon.svg'));
        this.matIconRegistry.addSvgIcon('graduate-hat-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_school.svg'));
        this.matIconRegistry.addSvgIcon('feedback-submitted', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/feedback-submitted.svg'));
    };
    IconRegisterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IconRegisterService_Factory() { return new IconRegisterService(i0.ɵɵinject(i1.MatIconRegistry), i0.ɵɵinject(i2.DomSanitizer)); }, token: IconRegisterService, providedIn: "root" });
    return IconRegisterService;
}());
export { IconRegisterService };
