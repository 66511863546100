import { Store } from '@ngrx/store';
import * as fromAuthSelectors from '../modules/auth/store/auth.selectors';
import { first, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var DBVersionGuard = /** @class */ (function () {
    function DBVersionGuard(store) {
        this.store = store;
    }
    DBVersionGuard.prototype.canActivate = function (_route, _state) {
        return this.store.select(fromAuthSelectors.selectUserState.user).pipe(first(), map(function (user) {
            if (user && user.token && user.token !== '') {
                if (!user.isSinglePartDB) {
                    window.location.href = '/';
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                window.location.href = '/';
                return false;
            }
        }));
    };
    DBVersionGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DBVersionGuard_Factory() { return new DBVersionGuard(i0.ɵɵinject(i1.Store)); }, token: DBVersionGuard, providedIn: "root" });
    return DBVersionGuard;
}());
export { DBVersionGuard };
