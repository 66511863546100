import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromApp from '../../../../../frb-app/src/app/store/app.reducers';
import * as fromAuthSelectors from '../../../../../frb-app/src/app/modules/auth/store/auth.selectors';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { isBoolean } from 'util';

@Directive({
  selector: '[validatePermission]'
})

export class ValidatePermissionDirective implements OnInit, OnDestroy {
  @Input('validatePermission') permission: string[];
  permissionSubscription: Subscription;

  constructor(private store: Store<fromApp.AppState>,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) { }

  ngOnInit() {
    this.validateFeatures();
  }

  ngOnDestroy() {
    this.permissionSubscription.unsubscribe();
  }

  validateFeatures(): void {
    /*
      permission[0] = Screen Name
      permission[1] = Permission
      permission[2] = Feature Name
      permission[3] = Manual Show - Not mandatory
    */
    if (!this.permission[0]) {
      throw new Error('Screen Name Required');
    }
    const permissionType = ['canRead', 'canCreate', 'canUpdate', 'canDelete', undefined];
    if (permissionType.indexOf(this.permission[1]) < 0) {
      throw new Error('You can enter only: canRead, canCreate, canUpdate, canDelete or undefined');
    }
    if (this.permission[3] && !isBoolean(this.permission[3])) {
      throw new Error('Manual Show should be boolean!');
    }
    this.permissionSubscription = this.store
      .pipe(
        select(fromAuthSelectors.selectCompanyFeature.selectFeature, {
          name: this.permission[0],
          permission: this.permission[1],
          featureName: this.permission[2],
          manualShow: this.permission[3]
        }),
        filter(val => val !== undefined),
      ).subscribe(val => {
        val ? this.viewContainer.createEmbeddedView(this.templateRef) : this.viewContainer.clear();
      });
  }
}
