import { Injectable } from '@angular/core';
import { ContentManagementApi } from 'frb-api/src/apis/content-management-api';
import { ForgotPasswordRequest } from 'frb-api/src/models/forgot-password-request';
import { ForgotPasswordResponse } from 'frb-api/src/models/forgot-password-response';
import { HttpResponse } from 'frb-api/src/models/http-response';
import { LoginResponse } from 'frb-api/src/models/login-response';
import { LogoutResponse } from 'frb-api/src/models/logout-response';
import { ResetPasswordRequest } from 'frb-api/src/models/reset-password-request';
import { ResetPasswordResponse } from 'frb-api/src/models/reset-password-response';
import { VerifyResetPasswordTokenResponse } from 'frb-api/src/models/verify-reset-password-token-response';
import { AuthApi } from 'frb-api/src/pr-api';
import { LoginModel } from 'frb-app/src/app/modules/auth/components/login/login.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

@Injectable({ providedIn: 'root' })
export class UserService {

  constructor(private readonly _authApi: AuthApi, private readonly _contentManagementApi : ContentManagementApi) {
  }

  public login(loginModel: LoginModel): Observable<HttpResponse<LoginResponse>> {
    const loginRequest = {
      username: loginModel.userName,
      password: loginModel.password,
    };

    return this._authApi.loginUser(loginRequest);
  }


  logout(): Observable<HttpResponse<LogoutResponse>> {
    return this._authApi.logoutUser();
  }

  public isTokenExpired(token: string): Observable<boolean> {
    return this._authApi.isTokenExpired(token).pipe(map(() => {
      return true;
    }));
  }

  public getLoginDetails(loginModel: LoginModel): Observable<HttpResponse<LoginResponse>> {
    const loginRequest = {
      username: loginModel.userName,
      password: loginModel.password,
      newpassword: loginModel.password, // we are using newpassword in current app why....
    };

    return this._authApi.getLoginDetails(loginRequest);
  }

  forgotPassword(email: string): Observable<HttpResponse<ForgotPasswordResponse>> {
    const forgotPasswordRequest: ForgotPasswordRequest = {
      EmailAddress: email,
      isFromAngular: true
    };
    return this._authApi.forgotPassword(forgotPasswordRequest);
  }

  resetPassword(resetPasswordRequest: ResetPasswordRequest): Observable<HttpResponse<ResetPasswordResponse>> {
    return this._authApi.resetPassword(resetPasswordRequest);
  }

  verifyResetPasswordToken(resetPasswordToken: string): Observable<HttpResponse<VerifyResetPasswordTokenResponse>> {
    return this._authApi.verifyResetPasswordToken(resetPasswordToken);
  }

  getNavigationItems(): Observable<HttpResponse<any>> {
    return this._authApi.getNavigationItems();
  }

  getcompanyfeatures(): Observable<HttpResponse<any>> {
    return this._authApi.getcompanyfeatures();
  }

  public fieldEdgeSync(): Observable<HttpResponse<any>> {
    return this._authApi.fieldEdgeSync();
  }

  public fieldEdgeSyncPolling(): Observable<HttpResponse<any>> {
    return this._authApi.fieldEdgeSyncPolling();
  }

  public getMaskedLabels() {
    return this._authApi.getMaskedPriceColumnNames();
  }

  public generateBook(): Observable<HttpResponse<any>> {
    return this._authApi.generateBook();
  }

  public getBookStatus(): Observable<HttpResponse<any>> {
    return this._authApi.getBookStatus();
  }
  public getContentUpdateStatus(): Observable<HttpResponse<any>> {
    return this._contentManagementApi.getContentUpdateInfo();
  }
}
