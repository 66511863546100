import * as AuthActions from './auth.actions';
import * as fromAuthState from './auth.state';

export function authReducer(state = fromAuthState.initialAuthState, action: AuthActions.AuthActions): fromAuthState.IAuthState {
  switch (action.type) {
    case (AuthActions.LOGIN_ROUTE):
      return {
        ...state,
        login: {
          isLoading: false,
          loginFailureReason: ''
        },
        termsOfService: fromAuthState.initialTermsOfServiceState,
        forgotPassword: fromAuthState.initialforgotPasswordState
      };
    case (AuthActions.TRY_GET_LOGIN_DETAILS):
      return {
        ...state,
        login: {
          isLoading: true,
          loginFailureReason: ''
        }
      };
    case (AuthActions.GET_LOGIN_DETAILS_SUCCESS):
      return {
        ...state,
        login: {
          isLoading: true,
          loginFailureReason: ''
        }
      };
    case (AuthActions.SIGNIN_FAIL):
    case (AuthActions.GET_LOGIN_DETAILS_FAIL):
      return {
        ...state,
        login: {
          isLoading: false,
          loginFailureReason: action.payload,
        }
      };
    case (AuthActions.SIGNIN_SUCCESS):
      return {
        ...state,
        login: {
          isLoading: true,
          loginFailureReason: '',
        },
        user: {
          id: action.payload.id,
          email: action.payload.email,
          username: action.payload.username,
          role: action.payload.role,
          token: action.payload.token,
          rememberMe: action.payload.rememberMe,
          redirect: action.payload.redirect,
          url: action.payload.url,
          isTokenValid: action.payload.isTokenValid,
          isCheckingToken: false,
          /// For toolbar image and name
          companyName: action.payload.companyName ? action.payload.companyName : 'User Name',
          companyLogo: action.payload.companyLogo ? action.payload.companyLogo : './assets/img/user-logo.png',
          userTypeId: action.payload.userTypeId,
          isSinglePartDB: action.payload.isSinglePartDB,
          TaskNumberAutoIncrement: action.payload.TaskNumberAutoIncrement,
          AdminReturnUserId: action.payload.AdminReturnUserId,
          SubscriptionName: action.payload.SubscriptionName,
          SubscriptionStatus: action.payload.SubscriptionStatus,
          DaysLeft: action.payload.DaysLeft,
          FeatureFlags: action.payload.FeatureFlags,
          BusinessTypes: action.payload.BusinessTypes,
          SubscriptionType: action.payload.SubscriptionType,
          DemoAccount: action.payload.DemoAccount,
          TimeZone: action.payload.TimeZone,
          PartnerType: action.payload.PartnerType,
          RoleName: action.payload.RoleName,
          Mrr: action.payload.Mrr,
          ViewAllQuotes: action.payload.ViewAllQuotes,
          CanDeleteQuotes: action.payload.CanDeleteQuotes,
          CanShowHideTasks: action.payload.CanShowHideTasks,
          State: action.payload.State,
          ZipCode: action.payload.ZipCode,
          userData: action.payload.UserData,
          BusinessTypesList: action.payload.BusinessTypesList,
          InvoiceDate: action.payload.InvoiceDate,
          SubscriptionPurchaseDate: action.payload.SubscriptionPurchaseDate,
          BillingDate: action.payload.BillingDate,
          SubscriptionDate: action.payload.SubscriptionDate,
          UsersAllowed: action.payload.UsersAllowed

        }
      };
    case (AuthActions.IS_TOKEN_EXPIRED):
      return {
        ...state,
        user: {
          id: action.payload.id,
          email: action.payload.email,
          username: action.payload.username,
          role: action.payload.role,
          token: action.payload.token,
          rememberMe: action.payload.rememberMe,
          redirect: action.payload.redirect,
          url: action.payload.url,
          isTokenValid: false,
          isCheckingToken: false,
          // For toolbar image and name
          companyName: action.payload.companyName,
          companyLogo: action.payload.companyLogo,
          userTypeId: action.payload.userTypeId,
          isSinglePartDB: action.payload.isSinglePartDB,
          TaskNumberAutoIncrement: action.payload.TaskNumberAutoIncrement,
          SubscriptionName: action.payload.SubscriptionName,
          SubscriptionStatus: action.payload.SubscriptionStatus,
          DaysLeft: action.payload.DaysLeft,
          FeatureFlags: action.payload.FeatureFlags,
          BusinessTypes: action.payload.BusinessTypes,
          SubscriptionType: action.payload.SubscriptionType,
          DemoAccount: action.payload.DemoAccount,
          TimeZone: action.payload.TimeZone,
          PartnerType: action.payload.PartnerType,
          RoleName: action.payload.RoleName,
          Mrr: action.payload.Mrr,
          ViewAllQuotes: action.payload.ViewAllQuotes,
          CanDeleteQuotes: action.payload.CanDeleteQuotes,
          CanShowHideTasks: action.payload.CanShowHideTasks,
          State: action.payload.State,
          ZipCode: action.payload.ZipCode,
          userData: action.payload.userData,
          BusinessTypesList: action.payload.BusinessTypesList,
          InvoiceDate: action.payload.InvoiceDate,
          SubscriptionPurchaseDate: action.payload.SubscriptionPurchaseDate,
          BillingDate: action.payload.BillingDate,
          SubscriptionDate: action.payload.SubscriptionDate,
          UsersAllowed: action.payload.UsersAllowed
        }
      };
    case (AuthActions.IS_TOKEN_EXPIRED_SUCCESS):
      return {
        ...state,
        user: {
          id: action.payload.id,
          email: action.payload.email,
          username: action.payload.username,
          role: action.payload.role,
          token: action.payload.token,
          rememberMe: action.payload.rememberMe,
          redirect: action.payload.redirect,
          url: action.payload.url,
          isTokenValid: true,
          isCheckingToken: true,
          // For toolbar image and name
          companyName: '',
          companyLogo: '',
          userTypeId: action.payload.userTypeId,
          isSinglePartDB: action.payload.isSinglePartDB,
          TaskNumberAutoIncrement: action.payload.TaskNumberAutoIncrement,
          SubscriptionName: action.payload.SubscriptionName,
          SubscriptionStatus: action.payload.SubscriptionStatus,
          DaysLeft: action.payload.DaysLeft,
          FeatureFlags: action.payload.FeatureFlags,
          BusinessTypes: action.payload.BusinessTypes,
          SubscriptionType: action.payload.SubscriptionType,
          DemoAccount: action.payload.DemoAccount,
          TimeZone: action.payload.TimeZone,
          PartnerType: action.payload.PartnerType,
          RoleName: action.payload.RoleName,
          Mrr: action.payload.Mrr,
          ViewAllQuotes: action.payload.ViewAllQuotes,
          CanDeleteQuotes: action.payload.CanDeleteQuotes,
          CanShowHideTasks: action.payload.CanShowHideTasks,
          State: action.payload.State,
          ZipCode: action.payload.ZipCode,
          userData: action.payload.userData,
          BusinessTypesList: action.payload.BusinessTypesList,
          InvoiceDate: action.payload.InvoiceDate,
          SubscriptionPurchaseDate: action.payload.SubscriptionPurchaseDate,
          BillingDate: action.payload.BillingDate,
          SubscriptionDate: action.payload.SubscriptionDate,
          UsersAllowed: action.payload.UsersAllowed
        }
      };
    case (AuthActions.IS_TOKEN_EXPIRED_FAIL):
      return {
        ...state,
        user: {
          id: action.payload.id,
          email: action.payload.email,
          username: action.payload.username,
          role: action.payload.role,
          token: action.payload.token,
          rememberMe: action.payload.rememberMe,
          redirect: action.payload.redirect,
          url: action.payload.url,
          isTokenValid: false,
          isCheckingToken: true,
          // For toolbar image and name
          companyName: action.payload.companyName,
          companyLogo: action.payload.companyLogo,
          userTypeId: action.payload.userTypeId,
          isSinglePartDB: action.payload.isSinglePartDB,
          TaskNumberAutoIncrement: action.payload.TaskNumberAutoIncrement,
          SubscriptionName: action.payload.SubscriptionName,
          SubscriptionStatus: action.payload.SubscriptionStatusString,
          DaysLeft: action.payload.DaysLeft,
          FeatureFlags: action.payload.FeatureFlags,
          BusinessTypes: action.payload.BusinessTypes,
          SubscriptionType: action.payload.SubscriptionType,
          DemoAccount: action.payload.DemoAccount,
          TimeZone: action.payload.TimeZone,
          PartnerType: action.payload.PartnerType,
          RoleName: action.payload.RoleName,
          Mrr: action.payload.Mrr,
          ViewAllQuotes: action.payload.ViewAllQuotes,
          CanDeleteQuotes: action.payload.CanDeleteQuotes,
          CanShowHideTasks: action.payload.CanShowHideTasks,
          State: action.payload.State,
          ZipCode: action.payload.ZipCode,
          userData: action.payload.userData,
          BusinessTypesList: action.payload.BusinessTypesList,
          InvoiceDate: action.payload.InvoiceDate,
          SubscriptionPurchaseDate: action.payload.SubscriptionPurchaseDate,
          BillingDate: action.payload.BillingDate,
          SubscriptionDate: action.payload.SubscriptionDate,
          UsersAllowed: action.payload.UsersAllowed
        }
      };
    case (AuthActions.VERIFY_RESET_PASSWORD_TOKEN):
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          isCheckingToken: true
        }
      };
    case (AuthActions.VERIFY_RESET_PASSWORD_TOKEN_SUCCESS):
      return {
        ...state,
        resetPassword: {
          resetPasswordFailureReason: '',
          isResetPasswordTokenValid: true,
          isLoading: false,
          isCheckingToken: false
        }
      };
    case (AuthActions.VERIFY_RESET_PASSWORD_TOKEN_FAIL):
      return {
        ...state,
        resetPassword: {
          resetPasswordFailureReason: '',
          isResetPasswordTokenValid: false,
          isLoading: false,
          isCheckingToken: false
        }
      };
    case (AuthActions.LOGOUT):
    return state;
    case (AuthActions.LOGOUT_SUCCESS):
    case (AuthActions.LOGOUT_FAILURE):
      const userState = fromAuthState.initialUserState;
      userState.username = state.user.rememberMe ? state.user.username : '';
      userState.email = state.user.rememberMe ? state.user.email : '';
      userState.rememberMe = state.user.rememberMe;
      return {
        ...state,
        user: userState,
        termsOfService: fromAuthState.initialTermsOfServiceState,
        forgotPassword: fromAuthState.initialforgotPasswordState,
        login: fromAuthState.initialLoginState
      };
    case (AuthActions.TRY_FORGOT_PASSWORD):
      return {
        ...state,
        forgotPassword: {
          isLoading: true,
          forgotPasswordFailureReason: '',
          showForgotPasswordSuccess: false
        },
      };
    case (AuthActions.FORGOT_PASSWORD_SUCCESS):
      return {
        ...state,
        forgotPassword: {
          isLoading: false,
          forgotPasswordFailureReason: '',
          showForgotPasswordSuccess: true
        },
      };
    case (AuthActions.FORGOT_PASSWORD_FAILURE):
      return {
        ...state,
        forgotPassword: {
          isLoading: false,
          forgotPasswordFailureReason: action.payload,
          showForgotPasswordSuccess: false
        },
      };
    case (AuthActions.TERMS_OF_SERVICES):
      return {
        ...state,
        termsOfService: {
          enableAction: action.payload,
          isAccepted: false,
          showTermsOfService: true,
          loaded: false,
          htmlContentInput: '',
        },
        forgotPassword: {
          showForgotPasswordSuccess: false,
          forgotPasswordFailureReason: '',
          isLoading: false
        }
      };
      case (AuthActions.RESET_PASSWORD):
      return {
        ...state,
        resetPassword: {
          isLoading: true,
          resetPasswordFailureReason: '',
          isResetPasswordTokenValid: true,
          isCheckingToken: false
        }
      };
      case (AuthActions.RESET_PASSWORD_SUCCESS):
      return {
        ...state,
        resetPassword: {
          isLoading: false,
          resetPasswordFailureReason: '',
          isResetPasswordTokenValid: true,
          isCheckingToken: false
        }
      };
      case (AuthActions.RESET_PASSWORD_FAILURE):
      return {
        ...state,
        resetPassword: {
          isLoading: false,
          resetPasswordFailureReason: action.payload,
          isResetPasswordTokenValid: false,
          isCheckingToken: false
        }
      };
    case (AuthActions.TERMS_OF_SERVICES_ACCEPT):
      return {
        ...state,
        termsOfService: {
          loaded: false,
          htmlContentInput: '',
          enableAction: false,
          isAccepted: true,
          showTermsOfService: false
        }
      };
    case (AuthActions.TERMS_OF_SERVICES_REJECT):
      return {
        ...state,
        login: {
          isLoading: false,
          loginFailureReason: ''
        },
        termsOfService: {
          loaded: false,
          htmlContentInput: '',
          enableAction: false,
          isAccepted: false,
          showTermsOfService: false,
        }
      };
    case (AuthActions.TERMS_OF_SERVICES_CONTENT):
      return {
        ...state,
      };
      case (AuthActions.TERMS_OF_SERVICES_CONTENT_SUCCESS):
        return {
          ...state,
          termsOfService: {
            enableAction: state.termsOfService.enableAction,
            isAccepted: false,
            showTermsOfService: state.termsOfService.showTermsOfService,
            loaded: action.payload.loaded,
            htmlContentInput: action.payload.htmlContentInput,
          },
          forgotPassword: {
            showForgotPasswordSuccess: false,
            forgotPasswordFailureReason: '',
            isLoading: false
          }
        };
        case (AuthActions.TERMS_OF_SERVICES_CONTENT_FAIL):
          return {
            ...state,
            termsOfService: {
              enableAction: state.termsOfService.enableAction,
              isAccepted: false,
              showTermsOfService: state.termsOfService.showTermsOfService,
              loaded: false,
              htmlContentInput: '',
            },
            forgotPassword: {
              showForgotPasswordSuccess: false,
              forgotPasswordFailureReason: '',
              isLoading: false
            }
          };
    case (AuthActions.ADD_GLOBAL_ERROR):
      return {
        ...state,
        user: {
          ...state.user,
          isCheckingToken: true,
        }
      };
    case (AuthActions.GET_NAVIGATION_ITEMS):
        return {
          ...state,
          navigationItems: {
            ...state.navigationItems,
            loadNavigationStatus: undefined,
            loadNavigationMessage: '',
            loading: true
          }
        };
    case (AuthActions.GET_NAVIGATION_ITEMS_SUCCESS):
      return {
        ...state,
        navigationItems: fromAuthState.adapterNavigationItem.addAll(action.payload, {
          ...state.navigationItems,
          loadNavigationStatus: undefined,
          loadNavigationMessage: '',
          loading: false
        })
      };
      case (AuthActions.GET_COMPANY_FEATURES):
      return {
        ...state,
        companyFeatures: {
          ...state.companyFeatures,
          status: true,
          message: '',
          loading: true
        }
      };
      case (AuthActions.GET_COMPANY_FEATURES_SUCCESS):
      return {
        ...state,
         companyFeatures: fromAuthState.adapterCompanyFeatures.addAll(action.payload, {
           ...state.companyFeatures,
           status: undefined,
           message: '',
           loading: true
         })
      };
    case (AuthActions.FIELD_EDGE_SYNC):
      return {
        ...state,
        fieldEdge: {
          ...state.fieldEdge,
          syncStatus: undefined,
          syncMessage: '',
          loading: true
        }
      };
    case (AuthActions.FIELD_EDGE_SYNC_SUCCESS):
      return {
        ...state,
        fieldEdge: {
          ...state.fieldEdge,
          syncStatus: true,
          syncMessage: action.payload,
          loading: false
        }
      };
    case (AuthActions.FIELD_EDGE_SYNC_FAIL):
      return {
        ...state,
        fieldEdge: {
          ...state.fieldEdge,
          syncStatus: false,
          syncMessage: action.payload,
          loading: false
        }
    };
    case (AuthActions.FIELD_EDGE_SYNC_POLLING):
      return {
        ...state,
        fieldEdgePolling: {
          ...state.fieldEdgePolling,
          syncStatus: undefined,
          syncMessage: '',
          loading: true
        }
      };
    case (AuthActions.FIELD_EDGE_SYNC_POLLING_SUCCESS):
      return {
        ...state,
        fieldEdgePolling: {
          ...state.fieldEdgePolling,
          syncStatus: true,
          syncMessage: action.payload,
          loading: false
        }
      };
    case (AuthActions.FIELD_EDGE_SYNC_POLLING_FAIL):
      return {
        ...state,
        fieldEdgePolling: {
          ...state.fieldEdgePolling,
          syncStatus: false,
          syncMessage: action.payload,
          loading: false
        }
    };
    case (AuthActions.SET_FIELD_EDGE_SYNC_POLLING_STATUS):
      return {
        ...state,
        fieldEdgePolling: {
          ...state.fieldEdgePolling,
          syncStatus: action.payload.syncStatus,
          syncMessage: action.payload.syncMessage,
        }
      };
    case (AuthActions.GET_MASKED_LABELS_SUCCESS):
      return {
        ...state,
        maskedLabels: action.payload
    };

    case (AuthActions.GET_MASKED_LABELS_FAIL):
      return {
        ...state,
        maskedLabels: action.payload
    };

    case(AuthActions.SET_BOOK_STATUS): {
      return {
        ...state,
        bookGenerateState: action.payload
      };
    }

    case(AuthActions.SET_CONTENTUPDATE_STATUS): {
      return {
        ...state,
        contentUpdateState:action.payload
      };
    }

    default:
      return state;
  }
}
