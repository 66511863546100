import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppPreloadingStrategyService } from './app-preloading-strategy.service';
import { DBVersionGuard } from './gaurds/db-version-gaurd.service';

import { NoAuthGuard } from './gaurds/no-auth-gaurd.service';
import { NotFoundComponent } from './pages/not-found/not-found.component';
// import { NoUsedRouteGuard } from 'shared/src/app/gaurds/no-used-route-gaurd.service';

const RouteConfig: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '',
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.PrAuthModule),
    data: { preload: true, delay: false }
  },


  {
    path: 'Company/Business',
    loadChildren: () => import('./modules/business-information/business-information.module').then(m => m.BusinessInformationModule),
    data: { preload: false, delay: true },
    canActivate: [DBVersionGuard],
  },

  {
    path: 'Company/Contents',
    loadChildren: () => import('./modules/content-management/content-management.module').then(m => m.ContentManagementModule),
    data: { preload: false, delay: true },
    canActivate: [DBVersionGuard],
  },
  {
    path: 'Company/PriceBook',
    canActivate: [DBVersionGuard],
    children: [
      {
        path: '',
        redirectTo: 'ManageBook/0',
        pathMatch: "full"
      },
      {
        path: '',
        loadChildren: () => import('./modules/parts/parts.module').then(m => m.PartsModule),
        data: { preload: false, delay: true },
      },
      {
        path: '',
        loadChildren: () => import('./modules/tasks/tasks.module').then(m => m.TasksModule),
        data: { preload: false, delay: true },
      },
      {
        path: 'Options',
        loadChildren: () => import('./modules/option-board/option-board.module').then(m => m.OptionBoardModule),
        data: { preload: false, delay: true },
      },
      {
        path: 'ManageBook',
        loadChildren: () => import('./modules/books/books.module').then(m => m.BooksModule),
        data: { preload: false, delay: true },
      },
    ]
  },
  {
    path: 'Company/ManageBook',
    redirectTo: "Company/PriceBook/ManageBook/0",
    pathMatch: 'full'
  },
  {
    path: 'Company/ManageBook/0',
    redirectTo: "Company/PriceBook/ManageBook/0",
    pathMatch: 'full'
  },
  {
    path: '**',
    component: NotFoundComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(RouteConfig,
      {
        preloadingStrategy: AppPreloadingStrategyService
      })
  ],
  exports: [
    RouterModule
  ],
  providers: [AppPreloadingStrategyService]
})
export class AppRoutingModule { }
