
export class ToggleSubMenuItem {
    type: "ToggleSubMenuItem";
    toolTipText: string;
    validatePermission: boolean;
    subMenus: SubMenu[];
    permissions: any[];
    label: string;
}

export class SubMenuItem {
    type: "SubMenuItem";
    toolTipText: string;
    validatePermission: boolean;
    routerLink: string;
    hrefLink?: string;
    label: string;
    permissions: any[];
}

export declare type SubMenu = SubMenuItem | ToggleSubMenuItem;

export class Menu {
    toolTipText: string | undefined;
    validatePermission: boolean;
    routerLink: string | null;
    hrefLink?: string
    svgIcon: string;
    subMenus: SubMenu[];
    subMenuLabel: string;
    permissions: any[];
}

export const TOP_MENU_DATA: Menu[] = [
    {
        routerLink: "/Company/Business",
        subMenus: [
            {
                type: "SubMenuItem",
                routerLink: "/Company/Business/BusinessTypes",
                toolTipText: "Business Types",
                validatePermission: false,
                label: "Business Types",
                permissions: []
            },
            {
                type: "SubMenuItem",
                routerLink: "/Company/Business/Markups",
                toolTipText: "Parts Mark-up",
                validatePermission: false,
                label: "Parts Mark-up",
                permissions: []
            },
            {
                type: "SubMenuItem",
                routerLink: "/Company/Business/Refrigerant",
                toolTipText: "Refrigerant",
                validatePermission: false,
                label: "Refrigerant",
                permissions: []
            },
            {
                type: "SubMenuItem",
                routerLink: "/Company/Business/Vendors",
                toolTipText: "Vendors",
                validatePermission: true,
                label: "Vendors",
                permissions: ['Business Information', undefined, 'Vendor']
            },
            {
                type: "SubMenuItem",
                routerLink: "/Company/Business/Lists",
                toolTipText: "Managed Lists",
                validatePermission: false,
                label: "Managed Lists",
                permissions: ["Business Information", "canRead"]
            }
        ],
        svgIcon: "businessInfoIcon-new",
        toolTipText: undefined,
        validatePermission: true,
        subMenuLabel: "Business Info",
        permissions: ['Business Information', 'canRead']
    },
    {
        routerLink: "/Company/PriceBook",
        subMenus: [{
            type: "SubMenuItem",
            routerLink: "/Company/PriceBook/ManageBook",
            toolTipText: "Categories",
            validatePermission: true,
            label: "Categories",
            permissions: ["Manage Book", "canRead"]
        },
        {
            type: "SubMenuItem",
            routerLink: "/Company/PriceBook/Tasks",
            toolTipText: "Task List",
            validatePermission: true,
            label: "Task List",
            permissions: ["Task", "canRead"]
        },
        {
            type: "SubMenuItem",
            routerLink: "/Company/PriceBook/Parts",
            toolTipText: "Part List",
            validatePermission: true,
            label: "Part List",
            permissions: ["Parts", "canRead"]
        },
        {
            type: "SubMenuItem",
            routerLink: "/Company/PriceBook/Options",
            toolTipText: "Option List",
            validatePermission: true,
            label: "Option List",
            permissions: ["Option Boards", "canRead"]
        }
        ],
        toolTipText: undefined,
        svgIcon: "manageBookIcon-new",
        validatePermission: false,
        subMenuLabel: "Manage Book",
        permissions: []
    },
    // {
    //     routerLink: "/Company/Scheduling",
    //     subMenus: [],
    //     svgIcon: "schedulingIcon-new",
    //     toolTipText: "Scheduling",
    //     validatePermission: false,
    //     subMenuLabel: "Scheduling",
    //     permissions: []
    // },
    {
        routerLink: null,
        hrefLink: "/Company/WorkOrder",
        subMenus: [],
        svgIcon: "workOrderIcon-new",
        toolTipText: "Work Orders",
        validatePermission: true,
        subMenuLabel: "Work Orders",
        permissions: ["Work Orders", "canRead"]
    },
    {
        routerLink: null,
        subMenus: [],
        hrefLink: "/Company/Customer",
        svgIcon: "customerIcon-new",
        toolTipText: "Customers",
        validatePermission: true,
        subMenuLabel: "Customers",
        permissions: ["Customers", "canRead"]
    },
    {
        routerLink: null,
        subMenus: [],
        hrefLink: "/Company/ReportandExport/ExportOrImport",
        svgIcon: "importExportIcon-new",
        toolTipText: "Import/Export",
        validatePermission: true,
        subMenuLabel: "Import/Export",
        permissions: ["Reports Exports", "canRead"]
    }
]

export const BOTTOM_MENU_DATA: Menu[] = [
    {
        routerLink: null,
        subMenus: [],
        svgIcon: "generateSQLIcon-new",
        toolTipText: "Mobile Price Book",
        validatePermission: false,
        subMenuLabel: "Mobile Price Book",
        permissions: []
    },
    {
        routerLink: "/Company/Contents",
        subMenus: [],
        svgIcon: "contentUpdateStatusIcon",
        toolTipText: "Content Management",
        validatePermission: false,
        subMenuLabel: "Content Management",
        permissions: []
    },
    {
        routerLink: '/Company/Setting',
        svgIcon: "accountIcon",
        hrefLink: "/Company/Company/Dashboard",
        toolTipText: "My Account",
        validatePermission: false,
        subMenuLabel: "My Account",
        permissions: [],
        subMenus: []
    }
]