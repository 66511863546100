import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuthState from './auth.state';

const authState = createFeatureSelector<fromAuthState.IAuthState>('auth');

// Login Page Selectors
const login = {
  isLoading: (state: fromAuthState.IAuthState) => state.login.isLoading,
  loginFailureReason: (state: fromAuthState.IAuthState) => state.login.loginFailureReason
};
// Login Page State
export const selectLoginState = {
  isLoading: createSelector(authState, login.isLoading),
  loginFailureReason: createSelector(authState, login.loginFailureReason),
};

// Reset Password Selectors
const resetPassword = {
  isLoading: (state: fromAuthState.IAuthState) => state.resetPassword.isLoading,
  resetPasswordFailureReason: (state: fromAuthState.IAuthState) => state.resetPassword.resetPasswordFailureReason,
  isResetPasswordTokenValid: (state: fromAuthState.IAuthState) => state.resetPassword.isResetPasswordTokenValid,
  isCheckingToken: (state: fromAuthState.IAuthState) => state.resetPassword.isCheckingToken
};

export const selectResetPasswordState = {
  isLoading: createSelector(authState, resetPassword.isLoading),
  resetPasswordFailureReason: createSelector(authState, resetPassword.resetPasswordFailureReason),
  isResetPasswordTokenValid: createSelector(authState, resetPassword.isResetPasswordTokenValid),
  isCheckingToken: createSelector(
    authState,
    state => {
      return { isCheckingToken: state.resetPassword.isCheckingToken, isTokenValid: state.resetPassword.isResetPasswordTokenValid };
    }
  )
};

export const selectMaskLabels = createSelector(
  authState,
  (state) => state.maskedLabels
);

export const selectNavLoading = createSelector(
  authState,
  (state) => state.navigationItems.loading
);


// TOS state
const termsOfService = {
  enableAction: (state: fromAuthState.IAuthState) => state.termsOfService.enableAction,
  isAccepted: (state: fromAuthState.IAuthState) => state.termsOfService.isAccepted,
  showTermsOfService: (state: fromAuthState.IAuthState) => state.termsOfService.showTermsOfService,
  loaded: (state: fromAuthState.IAuthState) => state.termsOfService.loaded,
  htmlContentInput: (state: fromAuthState.IAuthState) => state.termsOfService.htmlContentInput
};

export const selectTermsOfServiceState = {
  enableAction: createSelector(authState, termsOfService.enableAction),
  isAccepted: createSelector(authState, termsOfService.isAccepted),
  showTermsOfService: createSelector(authState, termsOfService.showTermsOfService),
  loaded: createSelector(authState, termsOfService.loaded),
  htmlContentInput: createSelector(authState, termsOfService.htmlContentInput)
};

// User state
const user = {
  user: (state: fromAuthState.IAuthState) => state.user,
  navigationItems: (state: fromAuthState.IAuthState) => state.navigationItems,
  isCheckingToken: (state: fromAuthState.IAuthState) => state.user.isCheckingToken,
};

export const navigationItemsState = createSelector(authState, user.navigationItems);
export const selectUserState = {
  user: createSelector(authState, user.user),
  navigationItems: createSelector(navigationItemsState, fromAuthState.adapterNavigationItem.getSelectors().selectAll),
  isCheckingToken: createSelector(
    authState,
    state => {
      return { isCheckingToken: state.user.isCheckingToken, isTokenValid: state.user.isTokenValid };
    }
  ),
  selectFieldSyncResponse: createSelector(authState, authState => authState.fieldEdge),
  selectFieldSyncPollingResponse: createSelector(authState, authState => authState.fieldEdgePolling),
  selectBookGenerateState: createSelector(authState, authState => authState.bookGenerateState),
  selectContentUpdateState: createSelector(authState, authState => authState.contentUpdateState)
};

// Forgot password selector
const forgotPassword = {
  isLoading: (state: fromAuthState.IAuthState) => state.forgotPassword.isLoading,
  forgotPasswordFailureReason: (state: fromAuthState.IAuthState) => state.forgotPassword.forgotPasswordFailureReason,
  showForgotPasswordSuccess: (state: fromAuthState.IAuthState) => state.forgotPassword.showForgotPasswordSuccess
};

// Forgot password state
export const selectForgotPasswordState = {
  isLoading: createSelector(authState, forgotPassword.isLoading),
  showForgotPasswordSuccess: createSelector(authState, forgotPassword.showForgotPasswordSuccess),
  forgotPasswordFailureReason: createSelector(authState, forgotPassword.forgotPasswordFailureReason)
};

// Company Features enable/disable
const companyFeature = {
  loading: (state: fromAuthState.IAuthState) => state.companyFeatures.loading,
  status: (state: fromAuthState.IAuthState) => state.companyFeatures.status,
  message: (state: fromAuthState.IAuthState) => state.companyFeatures.message,
  companyFeatures: (state: fromAuthState.IAuthState) => state.companyFeatures
};

export const companyFeatureState = createSelector(authState, companyFeature.companyFeatures);
export const selectCompanyFeature = {
  companyFeatures: createSelector(companyFeatureState, fromAuthState.adapterCompanyFeatures.getSelectors().selectAll),
  selectFeature: createSelector(companyFeatureState, (companyFeatureState, props) => {
    const screenName = companyFeatureState.entities[props.name];
    if (screenName) {
      if (props.permission && props.featureName === undefined) { // Only screenName and Permissions
        const permissionStatus = screenName['permission'].filter(ele => {
          switch (props.permission) {
            case 'canRead': {
              return ele.canRead;
            }
            case 'canCreate': {
              return ele.canCreate;
            }
            case 'canUpdate': {
              return ele.canUpdate;
            }
            case 'canDelete': {
              return ele.canDelete;
            }
          }
        });
        return permissionStatus.length > 0;
      }
      const feature = screenName['features'].filter(ele => {
        return ele.featureName === props.featureName;
      })[0];

      if (props.featureName !== undefined && props.permission === undefined && props.manualShow === undefined) { // Only for feature
        return feature.status;
      } else if (props.permission === undefined && props.manualShow !== undefined) { // Feature with Manual
        return feature.status === props.manualShow;
      } else if (props.permission && props.manualShow !== undefined) { // Feature with Permission and Manual
        const permissionStatus = screenName['permission'].filter(ele => {
          switch (props.permission) {
            case 'canRead': {
              return ele.canRead;
            }
            case 'canCreate': {
              return ele.canCreate;
            }
            case 'canUpdate': {
              return ele.canUpdate;
            }
            case 'canDelete': {
              return ele.canDelete;
            }
          }
        });
        return props.manualShow === feature.status && permissionStatus.length > 0;
      } else if (feature !== undefined && feature.status && props.permission && props.manualShow === undefined) { // Feature with Permission
        const permissionStatus = screenName['permission'].filter(ele => {
          switch (props.permission) {
            case 'canRead': {
              return ele.canRead;
            }
            case 'canCreate': {
              return ele.canCreate;
            }
            case 'canUpdate': {
              return ele.canUpdate;
            }
            case 'canDelete': {
              return ele.canDelete;
            }
          }
        });
        return permissionStatus.length > 0;
      }
    }
  }),
  status: createSelector(authState, companyFeature.status),
  message: createSelector(authState, companyFeature.message)
};
