import { NgxPendoModule } from 'ngx-pendo';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { MatButtonModule, MatDialogModule, MatExpansionModule, MatIconModule, MatInputModule, MatMenuModule, MatProgressBarModule, MatTooltipModule } from '@angular/material';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { EditorModule } from '@progress/kendo-angular-editor';
import { GridModule } from '@progress/kendo-angular-grid';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { reducers } from './store/app.reducers';
import { AuthEffects } from './modules/auth/store/auth.effects';
import { PrAuthModule } from './modules/auth/auth.module';
import { CoreConfig } from 'core/src/app/common/services/core-config.service';
import { PsNavigationModule } from 'core/src/app/ui/common/components/navigation/navigation.module';
import { CustomerWrapperFooterComponent } from './components/footer/customer-wrapper-footer.component';
import { CustomerWrapperHeaderComponent } from './components/header/customer-wrapper-header.component';
import { PrApiModule, PrApiSettings } from 'frb-api/src/pr-api';
import { PR_API_SETTINGS } from 'frb-api/src/pr-api-settings.token';
import { HoldAlertComponent } from './components/hold-alert/hold-alert.component';
import { IntercomModule } from 'ng-intercom';
import { NgxMaskModule } from 'ngx-mask';
import { PrConfirmationDialogModule } from 'shared/src/app/components/confirmation-dialog/confirmation-dialog.module';
import { PrCustomSnackbarModule } from 'shared/src/app/components/custom-snackbar/custom-snackbar.module';
import { AuthInterceptor } from 'shared/src/app/interceptors/auth.interceptor';
import { RouteInterceptor } from 'shared/src/app/interceptors/router.interceptor.service';
import { AppConfig } from 'shared/src/app/services/app-config.service';
import { ThemeService } from './services/theme.service';
import { LoaderInterceptor } from 'shared/src/app/interceptors/loader.interceptor';
import { RaygunErrorHandler } from './services/raygun-error-handler';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { NavSubMenuBarComponent } from './components/nav-sub-menu-bar/nav-sub-menu-bar.component';
import { ToggleSubMenuComponent } from './components/toggle-sub-menu/toggle-sub-menu.component';
import { SubMenuComponent } from './components/sub-menu/sub-menu.component';
import { CustomDirectiveModule } from 'shared/src/app/directives/custom-directive.module';
import { ThemeSettingComponent } from './components/theme-setting/theme-setting.component';
import { CustomerWrapperComponent } from './pages/customer-wrapper.component';

const ENTRY_COMPONENTS = [
  CustomerWrapperComponent,
  CustomerWrapperHeaderComponent,
  CustomerWrapperFooterComponent,
  NavBarComponent,
  CustomerWrapperHeaderComponent,
  CustomerWrapperFooterComponent,
];

@NgModule({
  declarations: [
    AppComponent,
    ENTRY_COMPONENTS,
    HoldAlertComponent,
    NotFoundComponent,
    NavSubMenuBarComponent,
    ToggleSubMenuComponent,
    SubMenuComponent,
    ThemeSettingComponent,
    
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    PsNavigationModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    PrApiModule,
    PrAuthModule,
    PrConfirmationDialogModule,
    PrCustomSnackbarModule,
    StoreModule.forRoot(reducers),
    StoreRouterConnectingModule.forRoot(),
    MatSidenavModule,
    EffectsModule.forRoot([AuthEffects]),
    StoreDevtoolsModule.instrument(),
    EditorModule,
    NgxMaskModule.forRoot(),
    DateInputsModule,
    IntercomModule.forRoot({
      appId: 'shjc1q61', // from your Intercom config
      updateOnRouterChange: true
    }),
    TreeViewModule,
    NgxPendoModule.forRoot({
      pendoApiKey: 'f9ed14db-9229-45c5-5afc-e3ab6eb2d734'
    }),
    GridModule,
    MatTooltipModule,
    MatExpansionModule,
    CustomDirectiveModule
  ],
  providers: [
    {
      provide: ErrorHandler, 
      useClass: RaygunErrorHandler 
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    RouteInterceptor,
    ThemeService,
    {
      provide: CoreConfig,
      useFactory: () => {
        return CoreConfig.init({
          urls: {
            siteRoot: './',
          },
        });
      },
      deps: [],
    },
    {
      provide: PR_API_SETTINGS,
      useFactory: (config: AppConfig) => {
        const result: PrApiSettings = {
          urls: {
            apiRoot: config.environment.urls.api.root,
          },
        };

        return result;
      },

      deps: [AppConfig],
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: ENTRY_COMPONENTS,
})
export class AppModule { }
