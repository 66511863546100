<div class="customer-wrapper-header">
  <mat-toolbar color="primary">
    <mat-toolbar-row style="height: 50px; margin-top: 5px;">
      <span style=
      "margin-right: 10px;
      display: flex;
      align-items: center;"
      > 
      <ng-container *ngIf="smartPriceUser$ | async">
        <img class="pr-logo" src="./assets/img/smart-price-no-text.png" style="margin-right: 10px">
        <img class="pr-logo" src="./assets/img/smart-price-text.png" *ngIf="!isExpanded" style="height: 21px">
      </ng-container>

      <ng-container *ngIf="!(smartPriceUser$ | async)">
          <img class="pr-logo" src="./assets/img/integrate-icon.png" style="margin-right: 10px">
          <img class="pr-logo" src="./assets/img/integrate-text.png" *ngIf="!isExpanded" style="height: 21px">
        </ng-container>
      </span>
      <a mat-icon-button (click)="toggleNav()" style="color: white; margin-bottom: 5px">
          <mat-icon>menu</mat-icon>
      </a>
      <span class="mat-spacer"></span>
      <div fxLayout="column">
          <a mat-button [matMenuTriggerFor]="menu" style="color: white; margin-bottom: 5px;"><span class="user-text">
            <span>{{ (userState$ | async).companyName }}</span>
            <span>{{ (userState$ | async).username }}</span>
          </span>
          
          <mat-icon>keyboard_arrow_down</mat-icon></a>
          <mat-menu #menu="matMenu" overlapTrigger="false">
              <a mat-menu-item class="admin-menu-item" href="/Company/Company/Dashboard" *ngIf="hasMyAccount">My Account</a>
              <a mat-menu-item class="admin-menu-item" href="/Company/Subscription/ManageSubscription" *ngIf="hasSettings">Settings</a>
            <a mat-menu-item (click)="logout()" *ngIf="!(userState$ | async).AdminReturnUserId" class="admin-menu-item">Sign Out</a>
            <a mat-menu-item (click)="redirectToAdmin()" *ngIf="(userState$ | async).AdminReturnUserId" class="admin-menu-item">Back to Admin</a>
          </mat-menu>
        </div>
      <div fxLayout="column">
        <span>
          <a href="/Company/Company/DashBoard">
            <img class="company-image" src="{{(userState$ | async).companyLogo}}">
          </a>
        </span>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
