import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'brandCode'
})
export class BrandCodePipe implements PipeTransform {

  transform(value: any): any {
    if (value && typeof (value) === 'string' && value.length > 3) {
      return value.substr(0,3);
    }
    return null;
  }

}
