import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as fromApp from '../../../../store/app.reducers';
import * as AuthActions from '../../store/auth.actions';
import * as fromAuthSelectors from '../../store/auth.selectors';

@Component({
  selector: 'pr-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
  isBusy$: Observable<boolean>;
  showForgotPasswordSuccess$: Observable<boolean>;
  showTermsOfService$: Observable<boolean>;
  forgotPasswordFailureReason$: Observable<string>;

  forgotPasswordFailureReason: string;
  forgotPasswordformGroup: FormGroup;

  validEmailPattern = /^([A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?)$/;

  constructor(
    private fb: FormBuilder,
    private store: Store<fromApp.AppState>
  ) {  }

  ngOnInit() {
    this.forgotPasswordformGroup = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.pattern(this.validEmailPattern)])
    });
    this.setState();
  }

  doForgotPassword() {
    this.forgotPasswordFailureReason = '';

    // Check if the form are valid, return if they are not
    if (!this.forgotPasswordformGroup.valid) {
      return;
    }

    this.store.dispatch(new AuthActions.TryForgotPassword(this.getForgotPasswordModel()));
  }

  termsOfService() {
    this.store.dispatch(new AuthActions.TermsOfService(false));
  }

  get isValid(): boolean {
    return this.forgotPasswordformGroup.valid;
  }

  getForgotPasswordModel() {
    return {
      email: this.forgotPasswordformGroup.controls['email'].value
    };
  }

  setState() {
    this.showTermsOfService$ = this.store.select(fromAuthSelectors.selectTermsOfServiceState.showTermsOfService);
    this.isBusy$ = this.store.select(fromAuthSelectors.selectForgotPasswordState.isLoading);
    this.showForgotPasswordSuccess$ = this.store.select(fromAuthSelectors.selectForgotPasswordState.showForgotPasswordSuccess);
    this.forgotPasswordFailureReason$ = this.store.select(fromAuthSelectors.selectForgotPasswordState.forgotPasswordFailureReason);
  }
}
export interface ForgotPasswordModel {
    email: string;
  }
