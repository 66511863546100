import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Role } from 'shared/src/app/types/user';
// Login State
export interface ILoginState {
  isLoading: boolean;
  loginFailureReason: string;
}

export const initialLoginState: ILoginState = {
  isLoading: false,
  loginFailureReason: ''
};

export interface IMaskLabels {
  value: string;
  standard: string;
  premium: string;
}

// Forgot Password
export interface IForgotPasswordState {
  forgotPasswordFailureReason: string;
  showForgotPasswordSuccess: boolean;
  isLoading: boolean;
}

export const initialforgotPasswordState: IForgotPasswordState = {
  forgotPasswordFailureReason: '',
  showForgotPasswordSuccess: false,
  isLoading: false,
};

// TOS state
export interface ITermsOfServiceState {
  enableAction: boolean;
  isAccepted: boolean;
  showTermsOfService: boolean;
  loaded: boolean;
  htmlContentInput: string;
}

export const initialTermsOfServiceState: ITermsOfServiceState = {
  enableAction: false,
  isAccepted: false,
  showTermsOfService: false,
  loaded: false,
  htmlContentInput: ''
};

// Reset Password
export interface IResetPasswordState {
  isLoading: boolean;
  resetPasswordFailureReason: string;
  isResetPasswordTokenValid: boolean;
  isCheckingToken: boolean;

}

export const initialResetPasswordState: IResetPasswordState = {
  isLoading: false,
  resetPasswordFailureReason: '',
  isResetPasswordTokenValid: false,
  isCheckingToken: false
};

// Loggedin user state
export interface IUserState {
  id: number | string;
  email: string;
  username: string;
  role: Role;
  token: string;
  rememberMe: boolean;
  redirect: boolean;
  url: string;
  isTokenValid: boolean;
  isCheckingToken: boolean;
  // For toolbar image and name
  companyName: string;
  companyLogo: string;
  userTypeId: number;
  isSinglePartDB: boolean;
  TaskNumberAutoIncrement: boolean;
  AdminReturnUserId?: string;
  SubscriptionName: string;
  SubscriptionStatus: string;
  DaysLeft: number;
  FeatureFlags: {};
  BusinessTypes: {};
  SubscriptionType: string;
  DemoAccount: boolean;
  TimeZone: string;
  PartnerType: string;
  RoleName: string;
  Mrr: number;
  ViewAllQuotes: boolean;
  CanDeleteQuotes: boolean;
  CanShowHideTasks: boolean;
  State: string;
  ZipCode: string;
  BusinessTypesList: string;
  InvoiceDate: Date;
  SubscriptionPurchaseDate: Date;
  BillingDate: string;
  SubscriptionDate: string;
  UsersAllowed: number;
  userData: {
    token: string,
    CompanyName: string,
    CompanyLogo: string,
    CompanyMobileLogo: string,
    RedirectUrl: string,
    Redirect: boolean,
    TermAccepted: boolean,
    DeviceId: string,
    UserTypeId: number,
    TaskNumberAutoIncrement: boolean,
    IsSinglePartDB: boolean,
    SubscriptionName: string,
    SubscriptionStatus: number,
    SubscriptionStatusString: string,
    DaysLeft: number,
    Phone: string,
    CustomerNumber: string,
    CompanyId: number,
    Company: {
      CompanyId: number,
      Name: string,
      Phone: string,
      CustomerNumber: string
    }
  } | null;

}

export const initialUserState: IUserState = {
  id: 0,
  email: '',
  username: '',
  role: Role.User,
  token: '',
  rememberMe: false,
  redirect: false,
  url: '',
  isTokenValid: false,
  isCheckingToken: false,
  // For toolbar image and name
  companyName: '',
  companyLogo: '',
  userTypeId: 0,
  isSinglePartDB: false,
  TaskNumberAutoIncrement: false,
  SubscriptionName: '',
  SubscriptionStatus: '',
  DaysLeft: -1,
  FeatureFlags: {},
  BusinessTypes: {},
  SubscriptionType: '',
  DemoAccount: false,
  TimeZone: '',
  PartnerType: '',
  RoleName: '',
  Mrr: 0,
  ViewAllQuotes: false,
  CanDeleteQuotes: false,
  CanShowHideTasks: false,
  State: '',
  ZipCode: '',
  userData: null,
  BusinessTypesList: '',
  InvoiceDate: new Date(),
  SubscriptionPurchaseDate: new Date(),
  BillingDate: '',
  SubscriptionDate: '',
  UsersAllowed: 1
};

export interface INavigationItem {
  displayName: string;
  type: string;
  liClick: string;
  icon: string;
  linkURL: string;
  actionName: string;
  controllerName: string;
  areaName: string;
  canSync: number;
  canShow: boolean;
  sortOrder: number;
}

export interface INavigationItemState extends EntityState<INavigationItem> {
  loading: boolean;
  loadNavigationStatus: boolean | undefined;
  loadNavigationMessage: string;
}

export const adapterNavigationItem: EntityAdapter<INavigationItem> = createEntityAdapter<INavigationItem>({
  selectId: (instance) => instance.displayName
});

export const intialNavigationItemState: INavigationItemState = adapterNavigationItem.getInitialState({
  loading: false,
  loadNavigationStatus: undefined,
  loadNavigationMessage: ''
});

// Company features list
export interface ICompanyFeaturePermission {
  canRead: boolean;
  canUpdate: boolean;
  canCreate: boolean;
  canDelete: boolean;
}
export interface ICompanyFeatures {
  featureName: string;
  status: boolean;
}

export interface ICompanyFeature {
    id: number;
    name: string;
    permission: ICompanyFeaturePermission;
    features: ICompanyFeatures[];
}
export interface ICompanyFeaturesState extends EntityState<ICompanyFeature> {
  status: boolean | undefined;
  message: string;
  loading: boolean;
}
export const adapterCompanyFeatures: EntityAdapter<ICompanyFeature> = createEntityAdapter<ICompanyFeature>({
  selectId: (instance) => instance.name
});
export const initialCompanyFeaturesState: ICompanyFeaturesState = adapterCompanyFeatures.getInitialState({
  status: undefined,
  message: '',
  loading: false
  });
export interface IFieldEdgeSyncState {
  loading: boolean;
  syncStatus: boolean | undefined;
  syncMessage: string;
}

export const intialFieldEdgeSyncState: IFieldEdgeSyncState = {
  loading: false,
  syncStatus: undefined,
  syncMessage: ''
};

export interface IFieldEdgeSyncPollingState {
  loading: boolean;
  syncStatus: boolean | undefined;
  syncMessage: string;
}

export const intialFieldEdgeSyncPollingState: IFieldEdgeSyncPollingState = {
  loading: false,
  syncStatus: undefined,
  syncMessage: ''
};

export interface IBookGenerateState {
  syncStatus: boolean;
  syncMessage: string;
}
export interface IContentUpdateState {
  status: number;
}

export const initialBookGenerateState: IBookGenerateState = {
  syncStatus: true,
  syncMessage: ''
};
export const initialContentUpdateState: IContentUpdateState = {
  status:0
};

// Auth module state
export interface IAuthState {
  login: ILoginState;
  user: IUserState;
  forgotPassword: IForgotPasswordState;
  termsOfService: ITermsOfServiceState;
  resetPassword: IResetPasswordState;
  navigationItems: INavigationItemState;
  companyFeatures: ICompanyFeaturesState;
  fieldEdge: IFieldEdgeSyncState;
  fieldEdgePolling: IFieldEdgeSyncPollingState;
  maskedLabels: IMaskLabels;
  bookGenerateState: IBookGenerateState;
  contentUpdateState:IContentUpdateState
}

export const initialAuthState: IAuthState = {
  login: initialLoginState,
  user: initialUserState,
  forgotPassword: initialforgotPasswordState,
  termsOfService: initialTermsOfServiceState,
  resetPassword: initialResetPasswordState,
  navigationItems: intialNavigationItemState,
  companyFeatures: initialCompanyFeaturesState,
  fieldEdge: intialFieldEdgeSyncState,
  fieldEdgePolling: intialFieldEdgeSyncPollingState,
  maskedLabels: {
    value: 'Value',
    standard: 'Standard',
    premium: 'Premium'
  },
  bookGenerateState: initialBookGenerateState,
  contentUpdateState:initialContentUpdateState
};

