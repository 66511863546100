import { Component, Input, OnInit } from '@angular/core';
import { SubMenuItem } from './../nav-bar/nav-bar.model'
@Component({
  selector: 'pr-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss']
})
export class SubMenuComponent implements OnInit {
  @Input()
  subMenu: SubMenuItem;
  constructor() { }

  ngOnInit() {
  }

}
