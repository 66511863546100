import { Injectable } from '@angular/core';
import { EnvironmentSettings, UrlEnvironmentSettings } from './environment-settings';

declare var window: any;

@Injectable({ providedIn: 'root' })
export class AppConfig {
  private _environment: EnvironmentSettings | undefined;

  get environment(): EnvironmentSettings {
    if (!this._environment) {
      this._environment = this._parseEnvironmentSettings();
    }

    return this._environment;
  }

  private _parseEnvironmentSettings(): EnvironmentSettings {
    const settingsPrefix = 'window.prEnvironmentSettings';

    if (!window.prEnvironmentSettings) {
      throw new Error(`${settingsPrefix} has not been defined.`);
    }

    const settings = window.prEnvironmentSettings;

    return {
      urls: parseUrlSettings(settings, settingsPrefix)
    };
  }
}

function parseUrlSettings(settings: any, settingsPrefix: string): UrlEnvironmentSettings {
  if (!settings.hasOwnProperty('urls')) {
    throw new Error(`${settingsPrefix}.urls has not been defined.`);
  }

  const urls = settings.urls;

  if (!urls.hasOwnProperty('api')) {
    throw new Error(`${settingsPrefix}.urls.api has not been defined.`);
  }

  if (!urls.api.hasOwnProperty('root')) {
    throw new Error(`${settingsPrefix}.urls.api.root has not been defined.`);
  }

  return {
    api: {
      root: urls.api.root,
    },
  };
}
