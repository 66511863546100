import { CoreConfig } from 'core/src/app/common/services/core-config.service';
import { CustomerWrapperFooterComponent } from './components/footer/customer-wrapper-footer.component';
import { CustomerWrapperHeaderComponent } from './components/header/customer-wrapper-header.component';
import { PrApiSettings } from 'frb-api/src/pr-api';
import { AppConfig } from 'shared/src/app/services/app-config.service';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { CustomerWrapperComponent } from './pages/customer-wrapper.component';
var ENTRY_COMPONENTS = [
    CustomerWrapperComponent,
    CustomerWrapperHeaderComponent,
    CustomerWrapperFooterComponent,
    NavBarComponent,
    CustomerWrapperHeaderComponent,
    CustomerWrapperFooterComponent,
];
var ɵ0 = function () {
    return CoreConfig.init({
        urls: {
            siteRoot: './',
        },
    });
}, ɵ1 = function (config) {
    var result = {
        urls: {
            apiRoot: config.environment.urls.api.root,
        },
    };
    return result;
};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
