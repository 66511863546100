import { Component, Directive, ElementRef, Input, OnInit } from '@angular/core';
import { CoreConfig } from 'core/src/app/common/services/core-config.service';
import { EnumHelpers } from 'core/src/app/common/services/enum-helpers.service';
import { Icon } from 'core/src/app/ui/common/icon';
import { StyleSize } from 'core/src/app/ui/common/style-size';
import { Documentable } from 'core/src/app/ui/documentable';

const VALID_ICON_SIZES: StyleSize[] = [StyleSize.Xs, StyleSize.Sm, StyleSize.Md, StyleSize.Lg, StyleSize.Xl, StyleSize.Xxl];
const DEFAULT_ICON_SIZE: StyleSize = StyleSize.Sm;

let VALID_ICON_NAMES: string[];

/**
 * Displays an icon
 */
@Component({
  selector: 'ps-icon',
  templateUrl: './icon.component.html',
})
export class IconComponent implements OnInit, Documentable {
  rootUrl: string;
  @Input('name') name: Icon;
  @Input('size') size: StyleSize;

  constructor(private readonly _el: ElementRef, enumHelpers: EnumHelpers, config: CoreConfig) {
    if (!VALID_ICON_NAMES) {
      VALID_ICON_NAMES = enumHelpers.values(Icon);
    }

    this.size = DEFAULT_ICON_SIZE;
    this.rootUrl = config.environment.urls.siteRoot;
  }

  ngOnInit() {
    this.ensureValidIcon();
    this.ensureValidIconSize();

    this._el.nativeElement.classList.add('icon-size-' + this.size);
  }

  document() {
    const attributes: string[] = [
      `name="${this.name}"`,
    ];

    if (this.size !== DEFAULT_ICON_SIZE) {
      attributes.push(`size="${this.size}"`);
    }

    return `<ps-icon ${attributes.join(' ')}></ps-icon>`;
  }

  private ensureValidIcon() {
    if (!this.name) {
      throw new Error(`Missing "name" attribute.`);
    }

    // Even though Typescript will prevent setting in an invalid icon, Angular dynamically sets it so we need to check it at runtime
    if (VALID_ICON_NAMES.indexOf(this.name) === -1) {
      throw new Error(`Invalid icon name "${this.name}" (valid names: "${VALID_ICON_NAMES.join(',')}").`);
    }
  }

  private ensureValidIconSize() {
    // Even though Typescript will prevent setting in an invalid icon size, Angular dynamically sets it so we need to check it at runtime
    if (VALID_ICON_SIZES.indexOf(this.size) === -1) {
      throw new Error(`Invalid icon size "${this.size}" (valid sizes: "${VALID_ICON_SIZES.join(',')}").`);
    }
  }
}


@Directive({ selector: 'ps-icon-inner' })
// tslint:disable-next-line:directive-class-suffix
export class IconComponentInnerTag { }

export const IconComponentAggregate = [IconComponent, IconComponentInnerTag];
