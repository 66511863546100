import { Injectable } from '@angular/core';
import { UnauthenticatedApiClient } from '../services/api-client';
import { Observable } from 'rxjs';
import { HttpResponse } from '../models/http-response';
import { ITasksRequest, IPartsRequest } from 'frb-app/src/app/modules/content-management/store/content-management.models';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ContentManagementApi {


    /**
     * Creates an instance of content management api.
     * @param _apiClient 
     */
    constructor(private readonly _apiClient: UnauthenticatedApiClient) {
    }

    /**
     * Gets content update info
     * @returns content update info 
     */
    public getContentUpdateInfo(): Observable<HttpResponse<any>> {
        return this._apiClient.get<any>(`/api/v2/contentupdate/GetUpdateInfo`);
    }
    public updateContentUpdate(model :{ "revisionCompanyId": number, "startFieldEdgeSync": boolean, "generateSqlLiteFile": boolean }): Observable<HttpResponse<any>> {
        return this._apiClient.post<any>(`/api/v2/contentupdate`,model);
    }
    /**
     * Gets content task list
     * @param model 
     * @returns content task list 
     */
    public getContentTasksList(model: ITasksRequest): Observable<HttpResponse<any>> {
        const request = `${toDataSourceRequestString(model.request)}`;
        const params = new HttpParams().set('searchText', model.searchText ? model.searchText : '');
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._apiClient.post<any>(`/api/v2/contentupdates/${model.contentUpdateId}/tasks`, request, { params: params, headers: headers });
    }

    /**
     * Gets content parts list
     * @param model 
     * @returns content parts list 
     */
    public getContentPartsList(model: IPartsRequest): Observable<HttpResponse<any>> {
        const request = `${toDataSourceRequestString(model.request)}`;
        const params = new HttpParams().set('searchText', model.searchText ? model.searchText : '');
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._apiClient.post<any>(`/api/v2/contentupdates/${model.contentUpdateId}/parts`, request, { params: params, headers: headers });
    }

    /**
     * Gets task compare
     * @param taskId 
     * @returns task compare 
     */
    public getTaskCompare(taskId: number): Observable<HttpResponse<any>> {
        return this._apiClient.get<any>(`/api/v2/contentupdate/task/${taskId}/compare`);
    }

    /**
     * Gets part compare
     * @param partId 
     * @returns part compare 
     */
    public getPartCompare(partId: number): Observable<HttpResponse<any>> {
        return this._apiClient.get<any>(`/api/v2/contentupdate/part/${partId}/compare`);
    }


    /**
     * Resets task
     * @param taskId 
     * @returns task 
     */
    public resetTask(taskId: number): Observable<HttpResponse<any>> {
        return this._apiClient.post<any>(`/api/v2/contentupdates/tasks/${taskId}/reset`, {});
    }

    /**
     * Resets part
     * @param partId 
     * @returns part 
     */
    public resetPart(partId: number): Observable<HttpResponse<any>> {
        return this._apiClient.post<any>(`/api/v2/contentupdates/parts/${partId}/reset`, {});
    }


    /**
     * Copys task field
     * @param taskId 
     * @param [model] 
     * @returns task field 
     */
    public copyTaskField(taskId: number, fieldName: string): Observable<HttpResponse<any>> {
        const params = new HttpParams().append('FieldName', fieldName);
        return this._apiClient.post<any>(`/api/v2/contentupdate/tasks/${taskId}/copyfield`, {}, {
            params: params
        });
    }

    /**
     * Copys part field
     * @param partId 
     * @param [model] 
     * @returns part 
     */
    public copyPartField(partId: number, fieldName: string): Observable<HttpResponse<any>> {
        const params = new HttpParams().append('FieldName', fieldName);
        return this._apiClient.post<any>(`/api/v2/contentupdate/parts/${partId}/copyfield`, {}, {
            params: params
        });
    }

    /**
     * Links task
     * @param taskId 
     * @param status 
     * @returns task 
     */
    public linkTask(taskId: number, status:boolean): Observable<HttpResponse<any>> {
        return this._apiClient.post<any>(`/api/v2/contentupdates/tasks/${taskId}/link?status=${status}`,{});
    }

    /**
     * Links part
     * @param partId 
     * @param status 
     * @returns part 
     */
    public linkPart(partId: number, status:boolean): Observable<HttpResponse<any>> {
        return this._apiClient.post<any>(`/api/v2/contentupdates/parts/${partId}/link?status=${status}`, {status});
    }
}