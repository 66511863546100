/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../../../../../shared/src/app/pipes/safe-html.pipe";
import * as i7 from "@angular/platform-browser";
import * as i8 from "./terms-of-service-content.component";
import * as i9 from "@ngrx/store";
var styles_TermsOfServiceContentComponent = [];
var RenderType_TermsOfServiceContentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TermsOfServiceContentComponent, data: {} });
export { RenderType_TermsOfServiceContentComponent as RenderType_TermsOfServiceContentComponent };
function View_TermsOfServiceContentComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), i0.ɵunv(_v, 0, 0, i0.ɵnov(_v, 1).transform(_co.htmlContentInput$)))); _ck(_v, 0, 0, currVal_0); }); }
function View_TermsOfServiceContentComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mat-progress-spinner", [["class", "mat-progress-spinner"], ["color", "primary"], ["diameter", "60"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"], [1, "aria-valuemin", 0], [1, "aria-valuemax", 0], [1, "aria-valuenow", 0], [1, "mode", 0]], null, null, i2.View_MatProgressSpinner_0, i2.RenderType_MatProgressSpinner)), i0.ɵdid(1, 114688, null, 0, i3.MatProgressSpinner, [i0.ElementRef, i4.Platform, [2, i1.DOCUMENT], [2, i5.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { color: [0, "color"], diameter: [1, "diameter"], mode: [2, "mode"] }, null)], function (_ck, _v) { var currVal_7 = "primary"; var currVal_8 = "60"; var currVal_9 = "indeterminate"; _ck(_v, 1, 0, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i0.ɵnov(_v, 1).diameter; var currVal_2 = i0.ɵnov(_v, 1).diameter; var currVal_3 = ((i0.ɵnov(_v, 1).mode === "determinate") ? 0 : null); var currVal_4 = ((i0.ɵnov(_v, 1).mode === "determinate") ? 100 : null); var currVal_5 = ((i0.ɵnov(_v, 1).mode === "determinate") ? i0.ɵnov(_v, 1).value : null); var currVal_6 = i0.ɵnov(_v, 1).mode; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_TermsOfServiceContentComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i6.SafeHtmlPipe, [i7.DomSanitizer]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TermsOfServiceContentComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "terms-of-services-spinner"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TermsOfServiceContentComponent_2)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.loaded$)); _ck(_v, 2, 0, currVal_0); var currVal_1 = !i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_co.loaded$)); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_TermsOfServiceContentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pr-terms-of-service-content", [], null, null, null, View_TermsOfServiceContentComponent_0, RenderType_TermsOfServiceContentComponent)), i0.ɵdid(1, 245760, null, 0, i8.TermsOfServiceContentComponent, [i9.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TermsOfServiceContentComponentNgFactory = i0.ɵccf("pr-terms-of-service-content", i8.TermsOfServiceContentComponent, View_TermsOfServiceContentComponent_Host_0, {}, { loadEvent: "loadEvent" }, []);
export { TermsOfServiceContentComponentNgFactory as TermsOfServiceContentComponentNgFactory };
