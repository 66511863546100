import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { LocalStorage } from 'core/src/app/common/services/local-storage.service';
import { Subject } from 'rxjs';
import { ThemeType } from '../components/theme-setting/theme-type';

@Injectable()
export class ThemeService {
  private _renderer: Renderer2;
  private _themeClass: Subject<ThemeType> = new Subject<ThemeType>();
  private activeThemeKey = 'ACTIVE_THEME';
  themeClass = this._themeClass.asObservable();

  constructor(private readonly _localStorage: LocalStorage, rendererFactory: RendererFactory2, @Inject(DOCUMENT) private _document: Document) {
    this._renderer = rendererFactory.createRenderer(null, null);
  }

  setTheme(themeClass: ThemeType) {
    this._localStorage.set(this.activeThemeKey, themeClass);
    this._themeClass.next(themeClass);
  }

  getTheme(): Promise<ThemeType> {
    const activeTheme = this._localStorage.get(this.activeThemeKey) || ThemeType.DefaultTheme;
    return Promise.resolve(activeTheme as ThemeType);
  }

  intializeTheme(): void {
    this.themeClass.subscribe((themeClass) => {
      // Logic to remove old and set new theme
      const bodyContainerClasses = this._document.body.classList;
      const themeClassesToRemove = Array.from(bodyContainerClasses).filter((item: string) => item.includes('-theme'));
      if (themeClassesToRemove.length) {
        themeClassesToRemove.forEach((themeClassToRemove) => {
          this._renderer.removeClass(this._document.body, themeClassToRemove);
        });
      }

      this._renderer.addClass(this._document.body, themeClass);
    });

    // Set theme on intialization of application
    this.getTheme().then((themeClass) => {
      this._renderer.addClass(this._document.body, themeClass);
    });
  }
}
