/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-snackbar.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./custom-snackbar.component";
import * as i4 from "@angular/material/snack-bar";
var styles_CustomSnackBarComponent = [i0.styles];
var RenderType_CustomSnackBarComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CustomSnackBarComponent, data: {} });
export { RenderType_CustomSnackBarComponent as RenderType_CustomSnackBarComponent };
export function View_CustomSnackBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "toast"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "toast-header"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "strong", [["class", "mr-auto"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "ml-2 mb-1 close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "toast"; var currVal_1 = (_co.data.status ? "success" : "failure"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "toast-header"; var currVal_3 = (_co.data.status ? "success" : "failure"); _ck(_v, 5, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.data.message; _ck(_v, 6, 0, currVal_4); var currVal_5 = (_co.data.status ? "Success!" : "Failure"); _ck(_v, 7, 0, currVal_5); }); }
export function View_CustomSnackBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-snackbar", [], null, null, null, View_CustomSnackBarComponent_0, RenderType_CustomSnackBarComponent)), i1.ɵdid(1, 114688, null, 0, i3.CustomSnackBarComponent, [i4.MatSnackBarRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomSnackBarComponentNgFactory = i1.ɵccf("app-custom-snackbar", i3.CustomSnackBarComponent, View_CustomSnackBarComponent_Host_0, {}, {}, []);
export { CustomSnackBarComponentNgFactory as CustomSnackBarComponentNgFactory };
