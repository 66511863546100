/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./social-media.component";
import * as i3 from "../../../../../../../core/src/app/common/services/core-config.service";
var styles_SocialMediaComponent = [];
var RenderType_SocialMediaComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SocialMediaComponent, data: {} });
export { RenderType_SocialMediaComponent as RenderType_SocialMediaComponent };
function View_SocialMediaComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "img", [["src", "./assets/icons/facebook.svg"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.icon; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SocialMediaComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "img", [["src", "./assets/icons/linkedin.svg"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.icon; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SocialMediaComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "a", [["target", "_blank"]], [[8, "href", 4], [8, "title", 0]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SocialMediaComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SocialMediaComponent_3)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = (_v.context.index ? "space" : "none"); _ck(_v, 2, 0, currVal_2); var currVal_3 = (_v.context.$implicit.icon === "facebook"); _ck(_v, 4, 0, currVal_3); var currVal_4 = (_v.context.$implicit.icon === "linkedin"); _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.href; var currVal_1 = _v.context.$implicit.title; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_SocialMediaComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "pr-social-media"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SocialMediaComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.socialLinks; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SocialMediaComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pr-social-media", [], null, null, null, View_SocialMediaComponent_0, RenderType_SocialMediaComponent)), i0.ɵdid(1, 49152, null, 0, i2.SocialMediaComponent, [i3.CoreConfig], null, null)], null, null); }
var SocialMediaComponentNgFactory = i0.ɵccf("pr-social-media", i2.SocialMediaComponent, View_SocialMediaComponent_Host_0, {}, {}, []);
export { SocialMediaComponentNgFactory as SocialMediaComponentNgFactory };
