/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i2 from "@angular/material/button";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "./terms-of-service-content.component.ngfactory";
import * as i6 from "./terms-of-service-content.component";
import * as i7 from "@ngrx/store";
import * as i8 from "@angular/common";
import * as i9 from "./terms-of-service.component";
var styles_TermsOfServiceComponent = [];
var RenderType_TermsOfServiceComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TermsOfServiceComponent, data: {} });
export { RenderType_TermsOfServiceComponent as RenderType_TermsOfServiceComponent };
function View_TermsOfServiceComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn"], ["color", "primary"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(1, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i0.ɵted(-1, 0, ["Back"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.loaded; var currVal_3 = "primary"; _ck(_v, 1, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 1).disabled || null); var currVal_1 = (i0.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_TermsOfServiceComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn"], ["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(1, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i0.ɵted(-1, 0, ["Decline"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.loaded; var currVal_3 = "accent"; _ck(_v, 1, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 1).disabled || null); var currVal_1 = (i0.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_TermsOfServiceComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn"], ["color", "primary"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(1, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i0.ɵted(-1, 0, ["Accept"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.loaded; var currVal_3 = "primary"; _ck(_v, 1, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 1).disabled || null); var currVal_1 = (i0.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_TermsOfServiceComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "div", [["class", "terms-of-service-page mat-typography"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h1", [["class", "mat-display-1 header"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Terms of service "])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "html-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "pr-terms-of-service-content", [], null, [[null, "loadEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loadEvent" === en)) {
        var pd_0 = (_co.loadedEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_TermsOfServiceContentComponent_0, i5.RenderType_TermsOfServiceContentComponent)), i0.ɵdid(5, 245760, null, 0, i6.TermsOfServiceContentComponent, [i7.Store], null, { loadEvent: "loadEvent" }), (_l()(), i0.ɵeld(6, 0, null, null, 9, "div", [["class", "btn-div"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TermsOfServiceComponent_1)), i0.ɵdid(8, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i8.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TermsOfServiceComponent_2)), i0.ɵdid(11, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i8.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TermsOfServiceComponent_3)), i0.ɵdid(14, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i8.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 5, 0); var currVal_0 = !i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform(_co.enableAction$)); _ck(_v, 8, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform(_co.enableAction$)); _ck(_v, 11, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 14, 0, i0.ɵnov(_v, 15).transform(_co.enableAction$)); _ck(_v, 14, 0, currVal_2); }, null); }
export function View_TermsOfServiceComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pr-terms-of-service", [], null, null, null, View_TermsOfServiceComponent_0, RenderType_TermsOfServiceComponent)), i0.ɵdid(1, 114688, null, 0, i9.TermsOfServiceComponent, [i7.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TermsOfServiceComponentNgFactory = i0.ɵccf("pr-terms-of-service", i9.TermsOfServiceComponent, View_TermsOfServiceComponent_Host_0, {}, {}, []);
export { TermsOfServiceComponentNgFactory as TermsOfServiceComponentNgFactory };
