
import { Component, Input, OnInit } from '@angular/core';
import { IUserState } from '../../modules/auth/store/auth.state';
import { Menu, SubMenu } from '../nav-bar/nav-bar.model';

@Component({
  selector: 'pr-nav-sub-menu-bar',
  templateUrl: './nav-sub-menu-bar.component.html',
  styleUrls: ['./nav-sub-menu-bar.component.scss']
})
export class NavSubMenuBarComponent implements OnInit {
  @Input()
  selectedTopMainMenu: Menu | undefined;
  @Input()
  userState: IUserState;
  constructor() { }

  ngOnInit() {
  }
  isToggleSubMenu(subMenuItem: SubMenu) {
    return subMenuItem && subMenuItem.type === "ToggleSubMenuItem";
  }
}
