export enum Icon {
  AddCustomer = 'add-customer',
  AddList = 'add-list',
  AddO = 'add-o',
  Amex = 'amex',
  Back = 'back',
  Bank = 'bank',
  Calendar = 'calendar',
  CaretDown = 'caret-down',
  CaretLeft = 'caret-left',
  CaretRight = 'caret-right',
  CaretUp = 'caret-up',
  Cart = 'cart',
  Catalog = 'catalog',
  Chat = 'chat',
  Check = 'check',
  Checkbox = 'checkbox',
  CheckO = 'check-o',
  Clock = 'clock',
  Close = 'close',
  Cog = 'cog',
  Collapse = 'collapse',
  CreditCard = 'credit-card',
  Customer = 'customer',
  Customers = 'customers',
  Dashboard = 'dashboard',
  Discover = 'discover',
  Download = 'download',
  Duplicate = 'duplicate',
  Edit = 'edit',
  ErrorO = 'error-o',
  Expand = 'expand',
  Facebook = 'facebook',
  Linkedin = 'linkedin',
  Graph = 'graph',
  Hide = 'hide',
  Home = 'home',
  Invoices = 'invoices',
  Linkout = 'linkout',
  Loader = 'loader',
  Lock = 'lock',
  Magnify = 'magnify',
  Mail = 'mail',
  MailDefault = 'mail-default',
  MapPin = 'map-pin',
  Mastercard = 'mastercard',
  Merge = 'merge',
  MinusO = 'minus-o',
  MoreHorz = 'more-horz',
  MoreVert = 'more-vert',
  Moto = 'moto',
  Pause = 'pause',
  Pencil = 'pencil',
  Phone = 'phone',
  PhoneDefault = 'phone-default',
  Play = 'play',
  Plug = 'plug',
  QuestionO = 'question-o',
  Recurring = 'recurring',
  Reports = 'reports',
  Return = 'return',
  Search = 'search',
  Settings = 'settings',
  Spinner = 'spinner',
  Squarespace = 'squarespace',
  Stock = 'stock',
  Subscribe = 'subscribe',
  SubtractO = 'subtract-o',
  Tag = 'tag',
  Thumbsup = 'thumbsup',
  Trash = 'trash',
  Twitter = 'twitter',
  Upload = 'upload',
  Usd = 'usd',
  View = 'view',
  Visa = 'visa',
  Warning = 'warning',
  Website = 'website',
  Weebly = 'weebly',
  Wix = 'wix',
  Wordpress = 'wordpress',
  Yelp = 'yelp',
}
