import { Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, Renderer2, AfterViewInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectUserState } from 'frb-app/src/app/modules/auth/store/auth.selectors';
import { AppState } from 'frb-app/src/app/store/app.reducers';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[disableWhileSyncing]'
})
export class DisableWhileSyncingDirective implements OnDestroy, OnChanges, AfterViewInit {

  @Input('disableWhileSyncing') disableWhileSyncing = false;
  @Output('disabledEvent') disabledEvent = new EventEmitter<boolean>();
  currenySyncStatus = '';
  disableButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  pollingSubscription: Subscription;
  internalSubscription: Subscription;
  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private store: Store<AppState>
  ) {

  }

  ngAfterViewInit() {
    this.pollingSubscription = this.store.pipe(
      select(selectUserState.selectBookGenerateState),
      map(state => state.syncMessage)
    ).subscribe(val => {
      if (val === 'InProgress') {
        this.disableButton.next(true);
      } else {
        this.disableButton.next(this.disableWhileSyncing);
      }
      this.currenySyncStatus = val;
    });

    this.internalSubscription = this.disableButton
      .subscribe(val => {
        if (val) {
          this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
        } else {
          this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
        }

        this.disabledEvent.emit(val);
      });
  }

  ngOnDestroy() {
    this.pollingSubscription.unsubscribe();
    this.internalSubscription.unsubscribe();
  }

  ngOnChanges(changes) {
    if (this.currenySyncStatus === 'InProgress') {
      this.disableButton.next(true);
    } else {
      this.disableButton.next(changes.disableWhileSyncing.currentValue);
    }
  }
}
