import { isObjectLike, toString } from 'lodash-es';
import * as i0 from "@angular/core";
var LocalStorage = /** @class */ (function () {
    function LocalStorage() {
    }
    LocalStorage.key = function (key) {
        return "ps:" + key;
    };
    LocalStorage.prototype.get = function (key) {
        return localStorage.getItem(LocalStorage.key(key));
    };
    LocalStorage.prototype.set = function (key, value) {
        var valueToStore = isObjectLike(value) ? JSON.stringify(value) : toString(value);
        localStorage.setItem(LocalStorage.key(key), valueToStore);
    };
    LocalStorage.prototype.remove = function (key) {
        localStorage.removeItem(LocalStorage.key(key));
    };
    LocalStorage.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalStorage_Factory() { return new LocalStorage(); }, token: LocalStorage, providedIn: "root" });
    return LocalStorage;
}());
export { LocalStorage };
