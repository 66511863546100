import { Injectable } from '@angular/core';
import { EnvironmentSettings } from 'core/src/app/common/services/environment-settings';

@Injectable({ providedIn: 'root' })
export class CoreConfig {
  environment: {
    urls: {
      siteRoot: string;
    };
  };

  static init(environment: EnvironmentSettings): CoreConfig {
    const result = new CoreConfig();

    result.environment = {
      urls: {
        siteRoot: environment.urls.siteRoot || '',
      },
    };

    return result;
  }
}
