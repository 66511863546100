import { NgModule } from '@angular/core';
import { IconComponentAggregate } from 'core/src/app/ui/common/components/icon/icon.component';
import { InlineSVGModule } from 'ng-inline-svg';

const EXPORTED_COMPONENTS = [
  IconComponentAggregate,
];

@NgModule({
  declarations: [
    EXPORTED_COMPONENTS,
  ],

  imports: [
    InlineSVGModule.forRoot(),
  ],

  exports: [
    EXPORTED_COMPONENTS,
  ],
})
export class PsIconModule { }
