<div class="row">
    <div class="image-wrapper">
        <img src="{{ errorDisplayObj.imageUrl }}">
    </div>
    <div>
        <div class="heading">Error 404</div>
        <div class="title">{{ errorDisplayObj.title }}</div>
        <div class="message">The page you are looking for can’t be found. It was either moved,<br/> removed, or possibly
            never existed.</div>
        <div>
            <button mat-raised-button color="primary" [routerLink]="['/Company','ManageBook']"> Manage Book
            </button>
            <button mat-button color="primary"> Visit Support
            </button>
        </div>
    </div>
</div>