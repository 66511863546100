import { Component, OnDestroy, OnInit } from '@angular/core';
import { Navigation } from 'core/src/app/ui/common/components/navigation/navigation';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducers';
import * as AuthActions from '../../modules/auth/store/auth.actions';
import * as fromAuthSelectors from '../../modules/auth/store/auth.selectors';
import { interval, Subject, Subscription } from 'rxjs';
import { map, takeUntil, take } from 'rxjs/operators';
import { LoaderService } from 'shared/src/app/interceptors/loader.service';
import { UserService } from 'shared/src/app/services/user.service';
import { LocalStorage } from 'core/src/app/common/services/local-storage.service';
import { BOTTOM_MENU_DATA, Menu, TOP_MENU_DATA } from './nav-bar.model';
import { IUserState, INavigationItem } from '../../modules/auth/store/auth.state';
import { CustomSnackBarComponent } from 'shared/src/app/components/custom-snackbar/custom-snackbar.component';
import { MatSnackBar } from '@angular/material';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: "pr-nav-bar",
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  animations: [
    trigger('slideFadeInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-10%)' }),
        animate(200, style({ opacity: 1, transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        animate(200, style({ opacity: 0, transform: 'translateX(-10%)' }))
      ]),
    ])
  ]
})
export class NavBarComponent implements OnInit, OnDestroy {
  navigation: Navigation;
  isAjaxRequest: boolean;
  pollingSub: Subscription;
  bookGeneratedStatus: string;

  hasSettings = false;
  hasMyAccount = false;
  topMainMenu = TOP_MENU_DATA;
  bottomMainMenu = BOTTOM_MENU_DATA;
  selectedMenu: Menu | undefined | null;
  shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));
  hasEnableSqlGeneratorFeature: boolean = false;
  hasEnableContentUpdatesFetaure: boolean = false;
  hasFieldEdgeFetaure: boolean = false;
  menus = [{
    toolTipText: "Business "
  }];
  isSmartPriceUser: boolean;
  private unsubscribe$: Subject<void> = new Subject();
  userState: IUserState;
  fieldEdgeSyncStatus: boolean = false;
  navigationItems: INavigationItem[] = [];
  visibilityFunction = () => {
    if (document.hidden) {
      this.poolingEnabled = false;
    } else {
      this.poolingEnabled = true;
    }
  }
  poolingEnabled = true;
  get fieldEdgeNavigationItem() {
    return this.navigationItems.find(x => x.displayName === "FieldEdge");
  }
  constructor(
    private store: Store<fromApp.AppState>,
    private _localStorage: LocalStorage,
    public loaderService: LoaderService,
    private _route: ActivatedRoute,
    private _userService: UserService,
    private _router: Router,
    private readonly snackbar: MatSnackBar,
  ) { }

  ngOnInit() {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setSelectedMainMenu(null);
      }
    })

    this.store.dispatch(new AuthActions.GetNavigationItems());
    this.store.pipe(
      select(fromAuthSelectors.selectUserState.navigationItems),
      takeUntil(this.unsubscribe$)
    ).subscribe(data => {
      this.navigationItems = data;
    });

    const paramIsAjaxRequest = this._route.snapshot.queryParamMap.get('isAjaxRequest');
    if (paramIsAjaxRequest) {
      this.isAjaxRequest = JSON.parse(paramIsAjaxRequest);
    }

    this.store.pipe(
      select(fromAuthSelectors.selectUserState.selectBookGenerateState),
      map(state => state.syncMessage),
      takeUntil(this.unsubscribe$)
    ).subscribe(status => {
      this.bookGeneratedStatus = status;
    });
    this.store.pipe(
      select(fromAuthSelectors.selectUserState.selectContentUpdateState),
      takeUntil(this.unsubscribe$),
      map(state => state.status)
    ).subscribe(status => {
      this.contentUpdateStatus = status;
    });
    this.store.pipe(
      select(fromAuthSelectors.selectUserState.selectFieldSyncResponse),
      takeUntil(this.unsubscribe$)
    ).subscribe(res => {
      if (res.syncStatus) {
        this.store.dispatch(new AuthActions.GetNavigationItems());
      }
    });
    this.store
      .pipe(select(fromAuthSelectors.selectUserState.user), take(1))
      .subscribe(val => {
        if (val.FeatureFlags['EnableSqlGenerator']) {
          this.hasEnableSqlGeneratorFeature = true;
          this.pollPeriodically();
        }
      });
    this.store
      .pipe(select(fromAuthSelectors.selectUserState.user), take(1))
      .subscribe(val => {
        if (val.FeatureFlags['EnableContentUpdates']) {
          this.hasEnableContentUpdatesFetaure = true;
          this.pollContentUpdatePeriodically();
        }
      });

    this.store
      .pipe(select(fromAuthSelectors.selectUserState.user), take(1))
      .subscribe(val => {
        if (val.FeatureFlags['FieldEdge']) {
          this._userService.fieldEdgeSyncPolling().subscribe(response => {
            this.fieldEdgeSyncStatus = !response.status;
            this._localStorage.set('IsSyncInProcess', this.fieldEdgeSyncStatus ? false : true);
            this.store.dispatch(new AuthActions.SetFieldEdgeSyncPollingStatus({ syncMessage: response.message, syncStatus: this.fieldEdgeSyncStatus }));
            this.fieldEdgePolling();
            this.hasFieldEdgeFetaure = true;
          })
        }
      });
    this.store.pipe(
      select(fromAuthSelectors.selectUserState.user),
      takeUntil(this.unsubscribe$)
    ).subscribe(user => {
      if (user && user.token && user.token !== '') {
        // this.pollDotNetSession(user);
      }
    });

    this.store
      .select(fromAuthSelectors.selectUserState.user)
      .pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(user => {
        this.userState = user;
        this.isSmartPriceUser = user.SubscriptionName.toLowerCase().includes('smart price');
      });

    document.addEventListener("visibilitychange", this.visibilityFunction);
  }

  hasNavigationItem(displayName: string) {
    return this.navigationItems.some(x => x.displayName.toLowerCase() === displayName.toLowerCase());
  }

  setSelectedMainMenu(menu: Menu | null): void {
    this.selectedMenu = menu
  }

  fieldEdgeSync() {
    this._localStorage.set('IsSyncInProcess', true);
    this.store.dispatch(new AuthActions.FieldEdgeSync());
  }


  fieldEdgePolling() {
    if (this.pollingSub) {
      this.pollingSub.unsubscribe();
    }
    this.pollingSub = interval(120000).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      if (this.poolingEnabled)
        this.store.dispatch(new AuthActions.FieldEdgeSyncPolling());
    });

    this.store.pipe(
      select(fromAuthSelectors.selectUserState.selectFieldSyncPollingResponse),
      takeUntil(this.unsubscribe$)
    ).subscribe(val => {
      if (this.fieldEdgeSyncStatus !== undefined && this.fieldEdgeSyncStatus !== val.syncStatus && val.syncStatus) {
        this._localStorage.set('IsSyncInProcess', false);
        this.store.dispatch(new AuthActions.GetNavigationItems());
        this.snackbar.openFromComponent(CustomSnackBarComponent, { horizontalPosition: 'center', verticalPosition: 'top', duration: 3000, data: { message: val.syncMessage, status: true } });
      }
      switch (val.syncStatus) {
        case true:
          this.fieldEdgeSyncStatus = true;
          break;
        case false:
          this.fieldEdgeSyncStatus = false;
          break;
        default:
          this.fieldEdgeSyncStatus = this.fieldEdgeSyncStatus;
          break;
      }

    });

  }

  pollDotNetSession(user) {
    const source = interval(5000 * 60).pipe(
      takeUntil(this.unsubscribe$)
    );
    source.subscribe(() => {
      this._userService.isTokenExpired(user.token).subscribe();
    });
  }

  generateBook() {
    this.store.dispatch(new AuthActions.GenerateBook());
    this.store.dispatch(new AuthActions.SetBookStatus({
      syncStatus: false,
      syncMessage: 'InProgress'
    }));
  }

  ngOnDestroy() {
    if (this.unsubscribe$) {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    }
    document.removeEventListener("visibilitychange", this.visibilityFunction);
  }

  get isExpandedState() {
    return localStorage.getItem('bodyclass') === 'opened-menu' ? 'open' : 'closed';
  }

  pollPeriodically() {
    this.store.dispatch(new AuthActions.BeginBookPolling());
    const source = interval(120000).pipe(
      takeUntil(this.unsubscribe$)
    );
    source.subscribe(_ => {
      if (this.poolingEnabled)
        this.store.dispatch(new AuthActions.BeginBookPolling());
    });
  }

  pollContentUpdatePeriodically() {
    this.store.dispatch(new AuthActions.BeginContentUpdatePolling());
    const source = interval(120000).pipe(
      takeUntil(this.unsubscribe$)
    );

    source.subscribe(_ => {
      if (this.poolingEnabled)
        this.store.dispatch(new AuthActions.BeginContentUpdatePolling());
    });
  }
  contentUpdateStatus: any;
  get contentUpdateStatusClass(): any {
    return {
      "update-completed": this.contentUpdateStatus === 2 || this.contentUpdateStatus === 3,
      "update-available": this.contentUpdateStatus === 0,
      "update-pending": this.contentUpdateStatus === 1
    };

  }
  get contentUpdateStatusIcon(): any {
    switch (this.contentUpdateStatus) {
      case 2:
      case 3:
        return "contentUpdateDone";
      default:
        return "contentUpdate";
    }
  }
  get syncColor() {
    switch (this.bookGeneratedStatus) {
      case 'Completed':
        return '#5587C3';
      case 'InProgress':
        return '#f5a623';
      case 'NotStarted':
        return '#5587C3';
      case 'FailedException':
        return '#D8000C';
      default:
        break;
    }
  }


  logout() {
    if (this.userState) {
      if (this.userState.AdminReturnUserId) {
        window.location.href = '/Account/Account/LoginAsAdmin?AdminUserId=1';
      } else {
        this.store.dispatch(new AuthActions.Logout());
      }
    }
  }

  get logOutToolTip() {
    return this.userState.AdminReturnUserId ? "Back To Admin" : "Logout";
  }


}

