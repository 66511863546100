import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LoginPageHtmlResponse } from 'frb-api/src/models/login-page-html-response';
import { ContentService } from 'frb-app/src/app/services/content.service';
import {  Observable, Subscription } from 'rxjs';
import { slideAnimation } from 'shared/src/app/animations/animation';

import * as fromApp from '../../../store/app.reducers';
import * as fromAuthSelectors from '../store/auth.selectors';
import { map } from 'rxjs/internal/operators/map';
@Component({
  selector: 'pr-auth-wrapper',
  templateUrl: './auth-wrapper.component.html',
  animations: [slideAnimation]
})
export class AuthWrapperComponent implements OnInit, OnDestroy {
  section: string;
  leftPanelData: LoginPageHtmlResponse;
  leftPanelDataLoaded: boolean;
  showForgotPasswordSuccess$: Observable<boolean>;
  showTermsOfService$: Observable<boolean>;

  private _routerSubscription: Subscription;

  constructor(
    private _router: Router,
    private readonly _contentService: ContentService,
    private store: Store<fromApp.AppState>,
  ) {
    this._routerSubscription = this._router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {
        this.section = 'section-right';
      }
    });
  }

  ngOnInit() {
    this._contentService.getLoginPageHtml().pipe(map((res) => {
      this.leftPanelData = res.data as LoginPageHtmlResponse;
      this.leftPanelDataLoaded = true;
    }));
    this.setState();
  }

  ngOnDestroy() {
    this._routerSubscription.unsubscribe();
  }

  setState() {
    this.showTermsOfService$ = this.store.select(fromAuthSelectors.selectTermsOfServiceState.showTermsOfService);
    this.showForgotPasswordSuccess$ = this.store.select(fromAuthSelectors.selectForgotPasswordState.showForgotPasswordSuccess);
  }
}
