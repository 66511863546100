<div class="terms-of-service-page mat-typography">
    <h1 class="mat-display-1 header">
    Terms of service
    </h1>
  <div class="html-content">
    <pr-terms-of-service-content (loadEvent)="loadedEvent($event)"></pr-terms-of-service-content>
  </div>
  <div class="btn-div">
    <button *ngIf="!(enableAction$ | async)" class="btn" mat-flat-button color="primary" [disabled]="!loaded" (click)="close(false)">Back</button>
    <button *ngIf="enableAction$ | async" mat-flat-button color="accent" class="btn" [disabled]="!loaded" (click)="close(false)">Decline</button>
    <button *ngIf="enableAction$ | async" mat-flat-button class="btn" color="primary" [disabled]="!loaded"
      (click)="close(true)">Accept</button>
  </div>
</div>
