/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "ng-inline-svg/lib_commonjs/svg-cache.service";
import * as i2 from "@angular/common";
import * as i3 from "ng-inline-svg/lib_commonjs/inline-svg.config";
import * as i4 from "@angular/common/http";
import * as i5 from "ng-inline-svg/lib_commonjs/inline-svg.directive";
import * as i6 from "ng-inline-svg/lib_commonjs/inline-svg.service";
import * as i7 from "./icon.component";
import * as i8 from "../../../../common/services/enum-helpers.service";
import * as i9 from "../../../../common/services/core-config.service";
var styles_IconComponent = [];
var RenderType_IconComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IconComponent, data: {} });
export { RenderType_IconComponent as RenderType_IconComponent };
export function View_IconComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 3, "ps-icon-inner", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.SVGCacheService, i1.SVGCacheService, [[2, i2.APP_BASE_HREF], [2, i2.PlatformLocation], [2, i3.InlineSVGConfig], i4.HttpBackend, i0.RendererFactory2]), i0.ɵdid(2, 737280, null, 0, i5.InlineSVGDirective, [i0.ElementRef, i0.ViewContainerRef, i0.ComponentFactoryResolver, i1.SVGCacheService, i0.Renderer2, i6.InlineSVGService, [2, i3.InlineSVGConfig], i0.PLATFORM_ID], { inlineSVG: [0, "inlineSVG"] }, null), i0.ɵdid(3, 16384, null, 0, i7.IconComponentInnerTag, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.rootUrl + "/assets/icons/") + _co.name) + ".svg"); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_IconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ps-icon", [], null, null, null, View_IconComponent_0, RenderType_IconComponent)), i0.ɵdid(1, 114688, null, 0, i7.IconComponent, [i0.ElementRef, i8.EnumHelpers, i9.CoreConfig], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IconComponentNgFactory = i0.ɵccf("ps-icon", i7.IconComponent, View_IconComponent_Host_0, { name: "name", size: "size" }, {}, []);
export { IconComponentNgFactory as IconComponentNgFactory };
