<div class="auth-main-div">
  <div class="auth-container-div">
    <div type="box" class="pr-login-page auth-container-body-div">
      <div class="login-box">
        <div class="html-section">
          <img *ngIf="!leftPanelDataLoaded" src="./assets/img/default.png" alt="Profit Rhino" />
          <div *ngIf="leftPanelDataLoaded">
            <img *ngIf="!leftPanelData.isImage && !leftPanelData.isHtml && !leftPanelData.isBinary"
              src="./assets/img/default.png" alt="Profit Rhino" />
            <div *ngIf="leftPanelData.isHtml" [innerHTML]='leftPanelData.content | safeHtml'></div>
            <img *ngIf="leftPanelData.isImage" [src]="leftPanelData.imageUrl" alt="Profit Rhino" />
            <img *ngIf="leftPanelData.isBinary" [src]="leftPanelData.imageBytes" alt="Profit Rhino" />
          </div>
        </div>
        <div class="{{section}} section-div" [@slideAnimation]="(showTermsOfService$ | async) || (showForgotPasswordSuccess$ | async) ? 'right': 'middle'" >
          <div *ngIf="!(showTermsOfService$ | async) || (!(showTermsOfService$ | async) && (showForgotPasswordSuccess$ | async))">
            <img src="./assets/img/transparent_logo.png" class="transparent-logo" alt="Profit Rhino" />
          </div>
          <router-outlet></router-outlet>
          <div *ngIf="!(showTermsOfService$ | async) || (!(showTermsOfService$ | async) && (showForgotPasswordSuccess$ | async))" class="social-media">
            <pr-social-media></pr-social-media>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
