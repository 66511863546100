import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromApp from '../store/app.reducers';
import * as fromAuthSelectors from '../modules/auth/store/auth.selectors';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DBVersionGuard implements CanActivate {
  constructor(
    private store: Store<fromApp.AppState>
  ) { }

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean> {

    return this.store.select(fromAuthSelectors.selectUserState.user).pipe(
      first(),
      map(user => {
        if (user && user.token && user.token !== '') {
          if (!user.isSinglePartDB) {
            window.location.href = '/';
            return false;
          } else {
            return true;
          }
        } else {
          window.location.href = '/';
          return false;
        }

      })
    );
  }
}
