import * as tslib_1 from "tslib";
import { EnumHelpers as NonNgEnumHelpers } from 'core/src/app/common/services/enum-helpers';
import * as i0 from "@angular/core";
var EnumHelpers = /** @class */ (function (_super) {
    tslib_1.__extends(EnumHelpers, _super);
    function EnumHelpers() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EnumHelpers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EnumHelpers_Factory() { return new EnumHelpers(); }, token: EnumHelpers, providedIn: "root" });
    return EnumHelpers;
}(NonNgEnumHelpers));
export { EnumHelpers };
