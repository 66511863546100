import { Injectable } from '@angular/core';
import { UnauthenticatedApiClient } from '../services/api-client';
import { ForgotPasswordRequest } from '../models/forgot-password-request';
import { ForgotPasswordResponse } from '../models/forgot-password-response';
import { HttpResponse } from '../models/http-response';
import { LoginRequest } from '../models/login-request';
import { LoginResponse } from '../models/login-response';
import { LogoutResponse } from '../models/logout-response';
import { ResetPasswordRequest } from '../models/reset-password-request';
import { ResetPasswordResponse } from '../models/reset-password-response';
import { Observable } from 'rxjs';
import { VerifyResetPasswordTokenResponse } from '../models/verify-reset-password-token-response';
@Injectable()
export class AuthApi {

    constructor(private readonly _apiClient: UnauthenticatedApiClient) {
    }

    public loginUser(loginRequest: LoginRequest): Observable<HttpResponse<LoginResponse>> {
        if (loginRequest === null || loginRequest === undefined) {
            throw new Error('Required parameter login_request was null or undefined when calling loginUser.');
        }

        return this._apiClient.post<LoginResponse>(`/account/account/authorize`, loginRequest);
    }

    public isTokenExpired(token: string): Observable<HttpResponse<LoginResponse>> {
      if (token === null || token === undefined) {
        throw new Error('Required parameter validate_token was null or undefined when calling isTokenExpired.');
      }

      return this._apiClient.get<LoginResponse>(`/home/checkloggedin/`);
    }

    public getLoginDetails(loginRequest: LoginRequest): Observable<HttpResponse<LoginResponse>> {
      return this._apiClient.post<LoginResponse>(`/home/getlogindetails`, loginRequest);
    }

    public forgotPassword(forgotPasswordRequest: ForgotPasswordRequest): Observable<HttpResponse<ForgotPasswordResponse>> {
      return this._apiClient.post<ForgotPasswordResponse>(`/account/account/forgotpassword`, forgotPasswordRequest);
    }

    public verifyResetPasswordToken(resetPasswordToken: string): Observable<HttpResponse<VerifyResetPasswordTokenResponse>> {
      if (resetPasswordToken === null || resetPasswordToken === undefined) {
        throw new Error('Required parameter validate_token was null or undefined when calling isTokenExpired.');
      }

      return this._apiClient.post<VerifyResetPasswordTokenResponse>(`/account/account/verifyresetpasswordtoken/`, {
        resetPasswordToken: resetPasswordToken
      });
    }

    public resetPassword(resetPasswordRequest: ResetPasswordRequest): Observable<HttpResponse<ResetPasswordResponse>> {
      return this._apiClient.post<ResetPasswordResponse>(`/account/account/resetpassword/`, resetPasswordRequest);
    }

    public logoutUser(): Observable<HttpResponse<LogoutResponse>> {
      return this._apiClient.get<LogoutResponse>(`/account/account/logoff`);
    }

    public getNavigationItems(): Observable<HttpResponse<any>> {
      return this._apiClient.get<any>(`/api/v2/MainMenu`);
    }

    public getcompanyfeatures(): Observable<HttpResponse<any>> {
      return this._apiClient.get<any>(`/api/v2/modules`);
    }

    public fieldEdgeSync(): Observable<HttpResponse<any>> {
      return this._apiClient.get<any>(`/Company/subscription/StartFieldEdgeSync?isFullSync=false&V2=true`);
    }

    public fieldEdgeSyncPolling(): Observable<HttpResponse<any>> {
      return this._apiClient.get<any>('/api/v2/GetFiedEdgeSyncStatus');
    }

    public getMaskedPriceColumnNames () {
      return this._apiClient.get<any>('/api/v2/MaskedLabels');
    }

    public generateBook(): Observable<HttpResponse<any>> {
      return this._apiClient.get<any>(`/api/v2/StartSqlFileGenerator`);
    }

    public getBookStatus(): Observable<HttpResponse<any>>{
      return this._apiClient.get<any>('/api/v2/GetSqlLiteFileStatus');
    }
 }
