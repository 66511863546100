import { Store } from '@ngrx/store';
import * as AuthActions from '../modules/auth/store/auth.actions';
import * as fromAuthSelectors from '../modules/auth/store/auth.selectors';
import { first, map, skipWhile } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var ResetTokenGuard = /** @class */ (function () {
    function ResetTokenGuard(store) {
        this.store = store;
    }
    ResetTokenGuard.prototype.canActivate = function (_route, _state) {
        var resetPasswordToken = _route.paramMap.get('resetPasswordToken');
        this.store.dispatch(new AuthActions.VerifyResetPasswordToken(resetPasswordToken));
        return this.store.select(fromAuthSelectors.selectResetPasswordState.isCheckingToken).pipe(skipWhile(function (check) { return check.isCheckingToken; }), map(function (check) { return check.isTokenValid; }), first(function (ss) {
            return ss;
        }));
    };
    ResetTokenGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ResetTokenGuard_Factory() { return new ResetTokenGuard(i0.ɵɵinject(i1.Store)); }, token: ResetTokenGuard, providedIn: "root" });
    return ResetTokenGuard;
}());
export { ResetTokenGuard };
