import { Inject, Injectable } from '@angular/core';
import { PrApiSettings } from './pr-api-settings';
import { PR_API_SETTINGS } from './pr-api-settings.token';

@Injectable()
export class PrApiConfig {
  urls: {
    apiRoot: string;
  };

  constructor( @Inject(PR_API_SETTINGS) settings: PrApiSettings) {
    this._init(settings);
  }

  private _init(settings: PrApiSettings) {
    if (!settings) {
      throw new Error('No configuration options have been passed into frb-api.');
    }

    if (!settings.urls) {
      throw new Error(`Invalid value for configuration option "urls" (value: "${settings.urls}")`);
    }

    if (!settings.urls.apiRoot) {
      throw new Error(`Invalid value for configuration option "urls.apiRoot" (value: "${settings.urls.apiRoot}")`);
    }

    this.urls = {
      apiRoot: settings.urls.apiRoot,
    };
  }
}
