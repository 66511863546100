import { Injectable } from '@angular/core';
import { ContentApi } from 'frb-api/src/apis/content-api';
import { ContentResponse } from 'frb-api/src/models/content-response';
import { HttpResponse } from 'frb-api/src/models/http-response';
import { LoginPageHtmlResponse } from 'frb-api/src/models/login-page-html-response';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ContentService {
  constructor(private readonly _contentApi: ContentApi) {
  }

  public getLoginPageHtml(): Observable<HttpResponse<LoginPageHtmlResponse>> {
    return this._contentApi.getLoginPageHtml();
  }

  public getTermsAndServiceHtml(): Observable<HttpResponse<ContentResponse>> {
    return this._contentApi.getTermsAndServiceHtml();
  }
}
