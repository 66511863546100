
import { Component, OnDestroy, OnInit } from '@angular/core';
import * as fromApp from '../app/store/app.reducers';
import * as AuthActions from '../app/modules/auth/store/auth.actions';
import { ThemeService } from './services/theme.service';

import { select, Store } from '@ngrx/store';
import * as fromAuthSelectors from './modules/auth/store/auth.selectors';

import { Event as RouterEvent, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { IconRegisterService } from './services/icon-register.service';
import * as rg4js from 'raygun4js';
import { Subscription, Subject } from 'rxjs';
import { withLatestFrom, filter, takeUntil } from 'rxjs/operators';
import { RouteInterceptor } from 'shared/src/app/interceptors/router.interceptor.service';
import { LoaderService } from 'shared/src/app/interceptors/loader.service';

const VERSION_NUMBER = '1.0.0.0';
rg4js('apiKey', window.location.href.indexOf('secure') > -1 ? 'JPD8TZzaf8m2p2vGSfcHEw' : 'DeF8dBoi6AEsSn1ibgxtlA');
rg4js('setVersion', VERSION_NUMBER);
rg4js('enablePulse', true);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ["./app.component.scss"]
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'APP-Component';
  pollingSub: Subscription;
  raygunSubscription: Subscription;
  loading = true;
  excludedNavBarRoutes: string[] = ["login", "forgot-password", "reset-password"];
  userLoggedIn: boolean = false;
  unsubscribe$: Subject<void> = new Subject();
  constructor(
    private _themeService: ThemeService,
    private store: Store<fromApp.AppState>,
    private _routeInterceptor: RouteInterceptor,
    private router: Router,
    private iconRegisterService: IconRegisterService,
    public loaderService: LoaderService
  ) { }

  ngOnInit() {
    this._themeService.intializeTheme();

    this.iconRegisterService.registerIcons();


    this.store.pipe(
      select(fromAuthSelectors.selectUserState.user),
      takeUntil(this.unsubscribe$)
    ).subscribe(user => {
      if (user && user.token && user.token !== '') {
        this.store.dispatch(new AuthActions.GetCompanyFeatures());
        this.store.dispatch(new AuthActions.GetMaskedLabels());
        this.userLoggedIn = true;
      } else {
        this.userLoggedIn = false;
      }
    });

    // tslint:disable-next-line: no-unused-expression
    this._routeInterceptor;

    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
    if (window.location.href.indexOf('dev') === -1) {
      this.raygunSubscription = this.router.events
        .pipe(
          withLatestFrom(this.store.pipe(select(fromAuthSelectors.selectUserState.user))),
          filter(([, user]) => user.username !== undefined && user.username !== '')
        )
        .subscribe(([event, user]) => {
          if (event instanceof NavigationEnd) {
            // Track navigation end
            rg4js('trackEvent', {
              type: 'pageView',
              path: event.url
            });
            rg4js('setUser', {
              identifier: user.username,
              isAnonymous: false,
              email: user.username ? user.username : 'C# USER',
              firstName: user.username ? user.username : 'C# USER',
              fullName: user.companyName,
            });
          }
        });
    }
  }



  navigationInterceptor(event: RouterEvent): void {

    if (event instanceof NavigationStart) {
      this.loading = true;
      this.setNavBarStatus(event.url);
    }
    if (event instanceof NavigationEnd) {
      this.loading = false;
      this.setNavBarStatus(event.urlAfterRedirects);
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
      this.setNavBarStatus(event.url);
    }
    if (event instanceof NavigationError) {
      this.loading = false;
      this.setNavBarStatus(event.url);
    }
  }
  showNavBar: boolean = false;
  setNavBarStatus(url: string) {
    this.showNavBar = !this.excludedNavBarRoutes.some(x => url.indexOf(x) >= 0);
  }

  ngOnDestroy() {
    this.pollingSub.unsubscribe();
    if (window.location.href.indexOf('dev') === -1) {
      this.raygunSubscription.unsubscribe();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
