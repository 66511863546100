import * as tslib_1 from "tslib";
import { HttpParams } from '@angular/common/http';
import { UnauthenticatedApiClient } from './../services/api-client';
var CategoryAPI = /** @class */ (function () {
    function CategoryAPI(_apiClient) {
        this._apiClient = _apiClient;
    }
    CategoryAPI.prototype.getBusinessTypeList = function () {
        return this._apiClient.get("/api/v2/TaskCategories/0");
    };
    CategoryAPI.prototype.getCategoryChildren = function (id) {
        var params = new HttpParams().set('ParentId', id.toString()).set('PageNo', '1').set('PageSize', '-1');
        return this._apiClient.get("/api/v2/TaskCategories/", { params: params });
    };
    CategoryAPI.prototype.searchCategories = function (search, parentId) {
        if (parentId === void 0) { parentId = undefined; }
        var params = new HttpParams().set('SearchKeyword', search).set('PageNo', '1').set('PageSize', '-1');
        if (parentId) {
            params = params.set('ParentId', parentId.toString());
        }
        return this._apiClient.get("/api/v2/TaskCategories/", { params: params });
    };
    CategoryAPI.prototype.getTasksOfCategory = function (taskObj) {
        var params;
        if (taskObj.isAssignmentActive) {
            switch (taskObj.isAssignmentActive) {
                case 'Inactive':
                    taskObj.isAssignmentActive = 'Inactive';
                    break;
                case 'Both':
                    taskObj.isAssignmentActive = 'All';
                    break;
                default:
                    taskObj.isAssignmentActive;
            }
        }
        params = new HttpParams().set('PageSize', '25');
        if (taskObj.page) {
            params = params.append('PageNo', taskObj.page.toString());
        }
        if (taskObj.isAssignmentActive) {
            params = params.append('IsAssignmentActive', taskObj.isAssignmentActive);
        }
        if (taskObj.oem) {
            params = params.append('OemFilter', taskObj.oem);
        }
        if (taskObj.isTaskActive) {
            params = params.append('IsTaskActive', taskObj.isTaskActive);
        }
        return this._apiClient.get("/api/v2/TaskCategories/" + taskObj.id + "/tasks", { params: params });
    };
    CategoryAPI.prototype.getCategoryById = function (id) {
        return this._apiClient.get("/api/v2/TaskCategories/" + id);
    };
    CategoryAPI.prototype.createCategory = function (category, imageName) {
        return this._apiClient.post("/api/v2/Category", tslib_1.__assign({}, category, { imageData: category.imageUrl, imageName: imageName }));
    };
    CategoryAPI.prototype.updateCategory = function (category, imageName) {
        var destruct = {
            id: category.id,
            name: category.name,
            description: category.description,
            isActive: category.isActive,
            account: category.account,
            imageData: category.imageUrl,
            imageName: imageName,
            position: category.position,
            isOptIn: category.isOptIn
        };
        return this._apiClient.put("/api/v2/Category", destruct);
    };
    CategoryAPI.prototype.deleteCategory = function (id) {
        return this._apiClient.delete("/api/v2/Category?Id=" + id);
    };
    CategoryAPI.prototype.addTaskCategories = function (categorieIds, taskId) {
        return this._apiClient.post("/api/v2/task/" + taskId + "/AssignCategory", categorieIds);
    };
    CategoryAPI.prototype.deleteTaskCategory = function (assignmentId, taskId) {
        return this._apiClient.delete("/api/v2/Task/" + taskId + "/Category/" + assignmentId);
    };
    CategoryAPI.prototype.copyTask = function (CopyTaskRequest) {
        var req = {
            taskNumber: CopyTaskRequest.copyTo,
            copyMarketingData: CopyTaskRequest.copyMarketingData,
            buisnessTypeId: CopyTaskRequest.buisnessTypeId
        };
        return this._apiClient.post("/api/v2/task/" + CopyTaskRequest.copyFrom + "/CopyTask", req);
    };
    CategoryAPI.prototype.categoryBulkActions = function (request) {
        return this._apiClient.post('/api/v2/bulkaction/Category', { ids: request.ids, actionType: request.type });
    };
    CategoryAPI.prototype.partCategoryBulkActions = function (request) {
        return this._apiClient.post('/api/v2/bulkaction/PartCategory', { ids: request.ids, actionType: request.type });
    };
    CategoryAPI.prototype.productlineBulkActions = function (request) {
        return this._apiClient.post('/api/v2/bulkaction/Productlines', { ids: request.ids, actionType: request.type });
    };
    CategoryAPI.prototype.updateCategoryOrder = function (sourceId, destinationId, index) {
        var body = {
            sourceId: sourceId,
            destinationId: destinationId,
            index: index
        };
        return this._apiClient.post("/api/v2/categorytree/actions/movecategory", body);
    };
    CategoryAPI.prototype.updatePartCategoryOrder = function (categories) {
        var request = {
            sortOrders: categories
        };
        return this._apiClient.post('/api/v2/PartCategory/ReOrderPosition', request);
    };
    CategoryAPI.prototype.updateProductlineOrder = function (categories) {
        var request = {
            sortOrders: categories
        };
        return this._apiClient.post('/api/v2/Productline/ReOrderPosition', request);
    };
    CategoryAPI.prototype.getPartCategories = function () {
        var params = new HttpParams().set('PageNo', '1').set('PageSize', '-1');
        return this._apiClient.get('/api/v2/PartCategories', { params: params });
    };
    CategoryAPI.prototype.getProductLineByPartCategory = function (categoryID) {
        var params = new HttpParams().set('PageNo', '1').set('PageSize', '-1');
        return this._apiClient.get("/api/v2/PartCategory/" + categoryID + "/ProductLines", { params: params });
    };
    CategoryAPI.prototype.setCategoryTaskStatus = function (categoryID, taskID, status) {
        var body = {
            actionType: status ? 'Activate' : 'Inactivate'
        };
        return this._apiClient.post("/api/v2/Category/" + categoryID + "/ActiveInactiveTaskAssignment/" + taskID, body);
    };
    CategoryAPI.prototype.getVendors = function () {
        var params = new HttpParams().set('PageNo', '1').set('PageSize', '-1');
        return this._apiClient.get("/api/v2/Vendors", { params: params });
    };
    CategoryAPI.prototype.createPartCategory = function (category, imageName) {
        return this._apiClient.post("/api/v2/PartCategory", {
            name: category.name,
            description: category.description,
            account: category.account,
            imageUrl: category.imageUrl,
            imageName: imageName,
            isActive: category.isActive,
            categoryId: category.categoryId
        });
    };
    CategoryAPI.prototype.updatePartCategory = function (category, imageName) {
        var destruct = {
            id: category.id,
            name: category.name,
            description: category.description,
            isActive: category.isActive,
            account: category.account,
            imageData: category.imageUrl,
            imageName: imageName,
            categoryId: category.categoryId
        };
        return this._apiClient.put("/api/v2/PartCategory", destruct);
    };
    CategoryAPI.prototype.deletePartCategory = function (id) {
        return this._apiClient.delete("/api/v2/PartCategory?Id=" + id);
    };
    CategoryAPI.prototype.createProductLine = function (category, imageName) {
        var destruct = tslib_1.__assign({}, category, { imageName: imageName });
        return this._apiClient.post("/api/v2/Productline", destruct);
    };
    CategoryAPI.prototype.updateProductLine = function (category, imageName) {
        var destruct = {
            id: category.id,
            name: category.name,
            description: category.description,
            isActive: category.isActive,
            account: category.account,
            imageData: category.imageUrl,
            imageName: imageName,
            categoryId: category.categoryId
        };
        return this._apiClient.put("/api/v2/Productline", destruct);
    };
    CategoryAPI.prototype.deleteProductline = function (id) {
        return this._apiClient.delete("/api/v2/Productline?Id=" + id);
    };
    CategoryAPI.prototype.getGlobalLibrary = function (searchKeyword) {
        var params = new HttpParams().set('SearchKeyword', searchKeyword ? searchKeyword : '');
        return this._apiClient.get("/api/v2/Images/GlobalLibrary", { params: params });
    };
    CategoryAPI.prototype.getYourLibrary = function (searchKeyword) {
        var params = new HttpParams().set('SearchKeyword', searchKeyword ? searchKeyword : '').set('type', 'Category').set('BusinessType', '0');
        return this._apiClient.get("/api/v2/Images/YourLibrary", { params: params });
    };
    CategoryAPI.prototype.getGoogleLibrary = function (searchKeyword, page) {
        var params = new HttpParams().set('SearchKeyword', searchKeyword ? searchKeyword : '').set('imageType', 'large').set('PageNo', page.toString());
        return this._apiClient.get("/api/v2/Images/Google", { params: params });
    };
    CategoryAPI.prototype.convertGoogleImageInBase64 = function (imageUrl) {
        var params = new HttpParams().set('ImageUrl', imageUrl);
        return this._apiClient.get("/api/v2/Images/UploadImage", { params: params });
    };
    CategoryAPI.prototype.reorderTasksInCategory = function (assignmentId, destinationCategoryId, index) {
        var body = {
            assignmentId: assignmentId,
            destinationCategoryId: destinationCategoryId,
            index: index
        };
        return this._apiClient.post("/api/v2/categorytree/actions/movetaskassignment", body);
    };
    CategoryAPI.prototype.requestPartsOfProductline = function (productLineId) {
        return this._apiClient.get("/api/v2/productline/" + productLineId + "/parts");
    };
    CategoryAPI.prototype.createPart = function (part) {
        return this._apiClient.post('/api/v2/part', part);
    };
    CategoryAPI.prototype.moveCategories = function (categoryIDs, targetId) {
        var body = {
            sourceIds: categoryIDs,
            targetId: targetId
        };
        return this._apiClient.post("/api/v2/Category/Move", body);
    };
    CategoryAPI.prototype.createTask = function (taskDetails) {
        var body = {
            taskNumber: taskDetails.taskNumber,
            businessTypeId: taskDetails.businessTypeId,
            name: taskDetails.name,
            description: taskDetails.description,
            marketingDescription: taskDetails.marketingDescription,
            taskDuration: taskDetails.taskDuration,
            taskAsstTime: taskDetails.taskAsstTime,
            addonEnabled: taskDetails.addonEnabled,
            addonDuration: taskDetails.addonDuration,
            addonAsstTime: taskDetails.addonAsstTime
        };
        return this._apiClient.post('/api/v2/tasks', body);
    };
    CategoryAPI.prototype.addPartToTask = function (id, taskDetails) {
        var body = taskDetails.parts.map(function (p, i) {
            return {
                id: p.id,
                qty: p.qty,
                order: i,
                partType: 'Primary'
            };
        });
        return this._apiClient.post("/api/v2/task/" + id + "/Part", body);
    };
    CategoryAPI.prototype.assignTaskToCategory = function (id, categoryID) {
        var body = [
            {
                id: categoryID
            }
        ];
        return this._apiClient.post("/api/v2/task/" + id + "/AssignCategory", body);
    };
    CategoryAPI.prototype.addImagesToTask = function (id, taskDetails) {
        var body = taskDetails.images.map(function (im, i) {
            return {
                name: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
                order: i,
                imageData: im.url.includes(';base64,') ? im.url.split(';base64,')[1] : im.url
            };
        });
        return this._apiClient.post("/api/v2/task/" + id + "/UploadImage", body);
    };
    CategoryAPI.prototype.deleteTaskFromCategory = function (categoryId, taskId) {
        return this._apiClient.delete("/api/v2/task/" + taskId + "/Category/" + categoryId);
    };
    CategoryAPI.prototype.savePart = function (patch, partId) {
        return this._apiClient.patch("/api/v2/part/" + partId, patch);
    };
    CategoryAPI.prototype.getManufacturers = function () {
        var params = new HttpParams().set('PageNo', '1').set('PageSize', '-1');
        return this._apiClient.get("/api/v2/manufacturer", { params: params });
    };
    CategoryAPI.prototype.getPriceBookTree = function () {
        return this._apiClient.get("/api/v2/PriceBookTree");
    };
    return CategoryAPI;
}());
export { CategoryAPI };
