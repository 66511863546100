import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import * as fromApp from '../../../store/app.reducers';
import * as AuthActions from '../store/auth.actions';

@Injectable({ providedIn: 'root' })
export class LoginResolver implements Resolve<void> {
    constructor(private store: Store<fromApp.AppState>) {}

    resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
        this.store.dispatch(new AuthActions.LoginRoute());
    }
}
