import * as tslib_1 from "tslib";
import { UnauthenticatedApiClient } from '../services/api-client';
import { HttpParams } from '@angular/common/http';
var TreeApi = /** @class */ (function () {
    function TreeApi(_apiClient) {
        this._apiClient = _apiClient;
    }
    TreeApi.prototype.getPriceBookTree = function () {
        return this._apiClient.get("/api/v2/PriceBookTree");
    };
    TreeApi.prototype.copyCategory = function (copyConfiguration) {
        var body = tslib_1.__assign({}, copyConfiguration);
        return this._apiClient.post("/api/v2/category/actions/copy", body);
    };
    TreeApi.prototype.reOrderCategoryTaskAssignment = function (id, index) {
        var body = {
            id: id,
            index: index
        };
        return this._apiClient.post("/api/v2/Category/ReOrderCategoryTaskAssignment", body);
    };
    TreeApi.prototype.moveCategory = function (sourceId, destinationId, index) {
        var body = {
            sourceId: sourceId,
            destinationId: destinationId,
            index: index
        };
        return this._apiClient.post("/api/v2/categorytree/actions/movecategory", body);
    };
    TreeApi.prototype.moveTaskAssignment = function (assignmentId, destinationCategoryId, index) {
        var body = {
            assignmentId: assignmentId,
            destinationCategoryId: destinationCategoryId,
            index: index
        };
        return this._apiClient.post("/api/v2/categorytree/actions/movetaskassignment", body);
    };
    TreeApi.prototype.createCategory = function (category, imageName) {
        return this._apiClient.post("/api/v2/Category", tslib_1.__assign({}, category, { imageData: category.imageUrl, imageName: imageName }));
    };
    TreeApi.prototype.updateCategory = function (category, imageName) {
        var destruct = {
            id: category.id,
            name: category.name,
            description: category.description,
            isActive: category.isActive,
            account: category.account,
            imageData: category.imageUrl,
            imageName: imageName,
            isOptIn: category.isOptIn
        };
        return this._apiClient.put("/api/v2/Category", destruct);
    };
    TreeApi.prototype.deleteCategory = function (id) {
        return this._apiClient.delete("/api/v2/Category?Id=" + id);
    };
    TreeApi.prototype.addTaskCategories = function (categorieIds, taskId) {
        return this._apiClient.post("/api/v2/task/" + taskId + "/AssignCategory", categorieIds);
    };
    TreeApi.prototype.deleteTaskCategory = function (assignmentId, taskId) {
        return this._apiClient.delete("/api/v2/Task/" + taskId + "/Category/" + assignmentId);
    };
    TreeApi.prototype.copyTask = function (CopyTaskRequest) {
        var req;
        if (CopyTaskRequest.copyTo !== '') {
            req = {
                taskNumber: CopyTaskRequest.copyTo,
                copyMarketingData: CopyTaskRequest.copyMarketingData,
                buisnessTypeId: CopyTaskRequest.buisnessTypeId
            };
        }
        else {
            req = {
                copyMarketingData: CopyTaskRequest.copyMarketingData,
                buisnessTypeId: CopyTaskRequest.buisnessTypeId
            };
        }
        return this._apiClient.post("/api/v2/task/" + CopyTaskRequest.copyFrom + "/CopyTask", req);
    };
    TreeApi.prototype.fetchCategoryById = function (id) {
        return this._apiClient.get("/api/v2/Category/" + id + "/PriceBookTree");
    };
    TreeApi.prototype.activeInactiveCategoryTask = function (categoryId, TaskId, status) {
        var body = {
            actionType: status ? 'Activate' : 'Inactivate'
        };
        return this._apiClient.post("/api/v2/Category/" + categoryId + "/ActiveInactiveTaskAssignment/" + TaskId, body);
    };
    TreeApi.prototype.getTasksOfCategory = function (id) {
        var params = new HttpParams().set('PageSize', '-1');
        return this._apiClient.get("/api/v2/TaskCategories/" + id + "/tasks", { params: params });
    };
    return TreeApi;
}());
export { TreeApi };
