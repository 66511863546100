import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable()
export class AppPreloadingStrategyService implements PreloadingStrategy {
  constructor() { }
  preload(route: Route, load: Function): Observable<any> {
    const loadRoute = (delay) => delay ? timer(150).pipe(flatMap(() => load())) : load();
    return route.data && route.data.preload ? loadRoute(route.data.delay) : of(null);
  }
}
