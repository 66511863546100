/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-wrapper-footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i3 from "@angular/material/toolbar";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "./customer-wrapper-footer.component";
var styles_CustomerWrapperFooterComponent = [i0.styles];
var RenderType_CustomerWrapperFooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerWrapperFooterComponent, data: {} });
export { RenderType_CustomerWrapperFooterComponent as RenderType_CustomerWrapperFooterComponent };
export function View_CustomerWrapperFooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "customer-wrapper-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "mat-toolbar", [["class", "footer mat-toolbar"], ["color", "primary"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i2.View_MatToolbar_0, i2.RenderType_MatToolbar)), i1.ɵdid(2, 4243456, null, 1, i3.MatToolbar, [i1.ElementRef, i4.Platform, i5.DOCUMENT], { color: [0, "color"] }, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(4, 0, null, 1, 5, "mat-toolbar-row", [["class", "mat-toolbar-row"]], null, null, null, null, null)), i1.ɵdid(5, 16384, [[1, 4]], 0, i3.MatToolbarRow, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["Build Version ", " Copyright \u00A9 Profit Rhino "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", " ", ""]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 2)._toolbarRows.length > 0); var currVal_1 = (i1.ɵnov(_v, 2)._toolbarRows.length === 0); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _co.buildVersion; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.lastYear; var currVal_5 = _co.currentYear; _ck(_v, 9, 0, currVal_4, currVal_5); }); }
export function View_CustomerWrapperFooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pr-customer-wrapper-footer", [], null, null, null, View_CustomerWrapperFooterComponent_0, RenderType_CustomerWrapperFooterComponent)), i1.ɵdid(1, 114688, null, 0, i6.CustomerWrapperFooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerWrapperFooterComponentNgFactory = i1.ɵccf("pr-customer-wrapper-footer", i6.CustomerWrapperFooterComponent, View_CustomerWrapperFooterComponent_Host_0, {}, {}, []);
export { CustomerWrapperFooterComponentNgFactory as CustomerWrapperFooterComponentNgFactory };
